.users {
    width: 100%;
    border-radius: 10px;
    box-sizing: border-box;
    overflow: auto;
    position: relative;

    table {
      min-width: 100%;
      table-layout: fixed;
      border-collapse: collapse;
      font-family: $font-paragraphe;
      box-sizing: border-box;

      th, td {
        padding: 15px;
        text-align: left;
        box-sizing: border-box;

        .text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .status {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: left-baseline;
        
          .round {
            text-align: center;
            width: 5px;
            height: 5px;
            border-radius: 50%;
          
            &.true {
              background-color: green;
            }
  
            &.false {
              background-color: red;
            }
          }
        
        }
      }

      tbody {
        tr:hover {
          cursor: pointer;
        }

        tr.disabledClass:hover {
          cursor: not-allowed;
        }
      }
    }
  }