@charset "UTF-8";
@font-face {
  font-family: "Tiempos";
  src: url("./assets/fonts/tiempos/TiemposHeadline-Regular_5qhhSkP25.otf");
  src: url("./assets/fonts/tiempos/TiemposHeadline-Regular_5qhhSkP25.otf") format("embedded-opentype"), url("./assets/fonts/tiempos/TiemposHeadline-Regular_g8k6ia8vz.woff2") format("woff2"), url("./assets/fonts/tiempos/TiemposHeadline-Regular_7R5Pei2Uo.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Apax";
  src: url("./assets/fonts/apax/Apax-Regular.otf");
  src: url("./assets/fonts/apax/Apax-Regular.otf") format("embedded-opentype"), url("./assets/fonts/apax/Apax-Medium.woff2") format("woff2"), url("./assets/fonts/apax/Apax-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
html, body {
  overflow-x: hidden;
  width: 100vw;
  box-sizing: border-box;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

*::selection {
  background-color: rgb(236, 179, 229);
}

.App.light-mode h1,
.App.light-mode h2,
.App.light-mode h3,
.App.light-mode h4,
.App.light-mode svg,
.App.light-mode textarea {
  color: #11181c;
}
.App.light-mode p {
  color: #687076;
}
.App.light-mode p.error {
  color: #560707;
}
.App.light-mode input, .App.light-mode .action, .App.light-mode .switch, .App.light-mode .addTextInput {
  background-color: #d5d5d5;
  color: #11181c;
}
.App.light-mode button {
  background-color: #c898ff;
  color: #2f0065;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.App.light-mode button:hover {
  background-color: #9133fe;
}
.App.light-mode button.success {
  background-color: #95e195 !important;
  color: #175607;
}
.App.light-mode button.error {
  background-color: #e19595 !important;
  color: #560707;
}
.App.light-mode button:disabled {
  background-color: #ccc;
  /* Set the background color for disabled buttons */
  color: #999;
  /* Set the text color for disabled buttons */
  cursor: not-allowed;
  /* Set the cursor for disabled buttons */
}
.App.light-mode button:disabled svg {
  color: #999;
}
.App.light-mode .sso {
  background-color: #212121 !important;
  color: rgb(251, 251, 251) !important;
  box-shadow: none;
  color: #9ba1ac;
}
.App.light-mode .sso:hover {
  background-color: #3a3a3a !important;
}
.App.light-mode .btn,
.App.light-mode .specialized-chatbot > select {
  box-shadow: none;
  border: 1px solid #ccc;
}
.App.light-mode .btn:hover, .App.light-mode .btn.active,
.App.light-mode .specialized-chatbot > select:hover,
.App.light-mode .specialized-chatbot > select.active {
  background-color: #e3cbff;
  border: 1px solid white;
}
.App.light-mode .btn:disabled,
.App.light-mode .specialized-chatbot > select:disabled {
  background-color: initial;
  border: 1px solid #ccc;
}
.App.light-mode .specialized-chatbot > select option {
  background-color: #f3f3f3;
}
.App.light-mode .login {
  background-color: rgb(251, 251, 251);
}
.App.light-mode .login .lines .line {
  background: #e2e2e2;
}
.App.light-mode .login .lines .line::after {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000000 75%, #000000 100%);
}
.App.light-mode .login .line-separator > .line {
  background: #e2e2e2;
}
.App.light-mode .login .cardLogin {
  background: rgba(246, 246, 246, 0.02);
}
.App.light-mode .login form .form-group .form-head {
  color: #11181c;
}
.App.light-mode .login form .form-group .form-head p,
.App.light-mode .login form .form-group .form-head svg {
  color: #11181c;
  fill: #11181c;
}
.App.light-mode .login form .form-group .pass {
  color: #11181c;
}
.App.light-mode .login form .form-group .icon {
  fill: #11181c;
}
.App.light-mode .login form .form-group input {
  background-color: #eeeeee !important;
  color: #11181c;
}
.App.light-mode .login form a {
  color: #11181c;
}
.App.light-mode .login .footer {
  background: #e2e2e2;
}
.App.light-mode .login .footer p,
.App.light-mode .login .footer a {
  color: #11181c;
}
.App.light-mode .loading {
  background-color: rgb(251, 251, 251);
}
.App.light-mode .section {
  background-color: rgb(251, 251, 251);
}
.App.light-mode .navbar {
  background-color: rgb(251, 251, 251);
}
.App.light-mode .icon {
  color: #11181c;
}
.App.light-mode .navbar::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  /* hauteur du dégradé */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  z-index: -1;
}
.App.light-mode a {
  color: #11181c;
}
.App.light-mode .login-link {
  color: #11181c;
}
.App.light-mode .window .topbar {
  background-color: #e2e2e2;
}
.App.light-mode .window .content {
  background-color: #c8c8c8;
}
.App.light-mode .window .bottombar {
  background-color: #e2e2e2;
}
.App.light-mode .toggle-container span {
  color: #11181c;
}
.App.light-mode select {
  background-color: #f6f6f6;
  color: #11181c;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.App.light-mode select:hover {
  background-color: #eeeeee;
}
.App.light-mode form input {
  background-color: #f6f6f6;
  color: #11181c;
}
.App.light-mode .contactForm {
  background-color: #f8f8f8;
}
.App.light-mode .contactForm input,
.App.light-mode .contactForm textarea,
.App.light-mode .contactForm select {
  background-color: #f1f1f1;
  color: #687076;
}
.App.light-mode .card {
  background-color: rgb(251, 251, 251);
}
.App.light-mode .card:hover {
  background-color: #eeeeee;
}
.App.light-mode .card .titre .icon .audit {
  color: #E14033;
}
.App.light-mode .card .titre .icon .formation {
  color: #FFBA33;
}
.App.light-mode .card .titre .icon .implementation {
  color: #2f92cf;
}
.App.light-mode .card .titre .underline.audit svg {
  stroke: #E14033;
}
.App.light-mode .card .titre .underline.formation svg {
  stroke: #FFBA33;
}
.App.light-mode .card .titre .underline.implementation svg {
  stroke: #2f92cf;
}
.App.light-mode .card .texte .line.audit {
  background-color: #E14033;
}
.App.light-mode .card .texte .line.formation {
  background-color: #FFBA33;
}
.App.light-mode .card .texte .line.implementation {
  background-color: #2f92cf;
}
.App.light-mode .card .lien.audit p {
  background-color: #f5bdb8;
  color: #982016;
}
.App.light-mode .card .lien.audit p:hover {
  background-color: #E14033;
}
.App.light-mode .card .lien.audit .icon {
  color: #982016;
}
.App.light-mode .card .lien.formation p {
  background-color: #ffeecc;
  color: #cc8700;
}
.App.light-mode .card .lien.formation p:hover {
  background-color: #FFBA33;
}
.App.light-mode .card .lien.formation .icon {
  color: #cc8700;
}
.App.light-mode .card .lien.implementation p {
  background-color: #abd3ec;
  color: #1c577c;
}
.App.light-mode .card .lien.implementation p:hover {
  background-color: #2f92cf;
}
.App.light-mode .card .lien.implementation .icon {
  color: #1c577c;
}
.App.light-mode .question .form {
  box-shadow: 35px 35px 71px #e6e6e6, -35px -35px 71px #ffffff;
}
.App.light-mode .question .form p {
  color: #687076;
}
.App.light-mode .question .form button:hover p {
  color: white !important;
}
.App.light-mode .question .form .renderer-core-form-flow {
  background-image: url("https://ik.imagekit.io/z0tzxea0wgx/Cogitum/bg2_wbEVWNt5N.png?updatedAt=1680315152110");
}
.App.light-mode .question .form input,
.App.light-mode .question .form textarea {
  color: #3b007e !important;
  border-bottom: 1px #3b007e solid !important;
}
.App.light-mode .question .form input::placeholder,
.App.light-mode .question .form textarea::placeholder {
  color: #3b007e;
}
.App.light-mode .question .form .renderer-components-error-message-wrapper .renderer-core-html-parser p {
  color: white !important;
}
.App.light-mode .cogichat .sidebar {
  background-color: #ffffff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}
.App.light-mode .cogichat .sidebar .logo {
  background-color: #ececec;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.App.light-mode .cogichat .sidebar .logo.active:hover {
  background-color: #e2e2e2;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}
.App.light-mode .cogichat .sidebar .hidden {
  color: #eeeeee;
}
.App.light-mode .cogichat .sidebar .arrow {
  background-color: #eeeeee;
}
.App.light-mode .cogichat .sidebar .logout .MuiSwitch-track {
  background-color: #7601fd;
}
.App.light-mode .cogichat .sidebar .sb.favorite .two svg.star {
  fill: #FFBA33;
  stroke: #FFBA33;
}
.App.light-mode .cogichat .sidebar .sb.history .two svg.star:hover {
  fill: #FFBA33;
  stroke: #FFBA33;
}
.App.light-mode .cogichat .sidebar .sb.history .two svg.trash:hover {
  fill: #E14033;
}
.App.light-mode .cogichat .sidebar .sb h2 {
  border-bottom: 1px solid #ccc;
}
.App.light-mode .cogichat .sidebar .sb ul li .one:hover {
  background-color: #e2e2e2;
}
.App.light-mode .cogichat .sidebar .sb ul li.active {
  background-color: #e3cbff;
  color: #170032;
}
.App.light-mode .cogichat .sidebar .sb ul li.active p {
  color: #170032;
}
.App.light-mode .cogichat .sidebar .sb ul li.active .one:hover {
  background-color: transparent;
}
.App.light-mode .cogichat .sidebar .sb ul::-webkit-scrollbar-thumb {
  background-color: #ccc;
}
.App.light-mode .cogichat .chat {
  background-color: #f8f8f8;
}
.App.light-mode .cogichat .chat .chat-header::after {
  background-color: #ccc;
}
.App.light-mode .cogichat .chat .chat-messages .message.unpair {
  background-color: #f8f8f8;
}
.App.light-mode .cogichat .chat .chat-messages .message.pair {
  background-color: #e2e2e2;
}
.App.light-mode .cogichat .chat .chat-messages::-webkit-scrollbar-thumb {
  background-color: #ccc;
  /* Set the color of the scrollbar line */
}
.App.light-mode .cogichat .chat .new-chat p {
  color: #7a7a7a;
}
.App.light-mode .cogichat .chat .new-chat p span {
  color: #7601fd;
}
.App.light-mode .cogichat .chat .new-chat .projects-container {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  background-color: #f6f6f6;
}
.App.light-mode .cogichat .chat .new-chat .projects-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
}
.App.light-mode .cogichat .chat .new-chat .templates::before {
  background: linear-gradient(to right, #f8f8f8, transparent);
}
.App.light-mode .cogichat .chat .new-chat .templates::after {
  background: linear-gradient(to left, #f8f8f8, transparent);
}
.App.light-mode .cogichat .chat .chat-input {
  background-image: linear-gradient(to top, #fbfbfb 50%, rgba(251, 251, 251, 0) 100%);
}
.App.light-mode .cogichat .chat .chat-input .input-container .new .test {
  background-color: #e2e2e2;
}
.App.light-mode .cogichat .chat .chat-input .input-container .new .mobile-btn {
  background-color: #f6f6f6;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.App.light-mode .cogichat .chat .chat-input .input-container .new .mobile-btn:hover {
  background-color: #eeeeee;
}
.App.light-mode .cogichat .chat .chat-input .input-container .new .mobile-btn svg {
  fill: #2f2f2f;
}
.App.light-mode .cogichat .chat .chat-input .input-container .options select option {
  background-color: #f6f6f6;
}
.App.light-mode .cogichat .chat .chat-input .input-container .options select option:checked {
  background-color: #7601fd;
  color: #fff;
}
.App.light-mode .cogichat .chat .chat-input .input-container .options .temperature input {
  accent-color: #7601fd;
}
.App.light-mode .cogichat .chat .chat-input .input-container .options .temperature .description {
  background-color: #e2e2e2;
}
.App.light-mode .cogichat .chat .chat-input .input-container .options .temperature .description::after {
  border-color: transparent transparent #e2e2e2 transparent;
}
.App.light-mode .cogichat .chat .chat-input .input-container .files .addFile,
.App.light-mode .cogichat .chat .chat-input .input-container .files .file-container {
  background-color: #f6f6f6;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.App.light-mode .cogichat .chat .chat-input .input-container .files .addFile .cross,
.App.light-mode .cogichat .chat .chat-input .input-container .files .file-container .cross {
  background-color: #141414;
}
.App.light-mode .cogichat .chat .chat-input .input-container .files .addFile .cross svg,
.App.light-mode .cogichat .chat .chat-input .input-container .files .file-container .cross svg {
  fill: white;
  color: white;
}
.App.light-mode .cogichat .chat .chat-input .input-container .input .textarea-container {
  background-color: #f6f6f6;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.App.light-mode .cogichat .chat .chat-input .input-container .input textarea {
  background-color: #f6f6f6;
}
.App.light-mode .cogichat .chat .chat-input .input-container .input textarea::-webkit-scrollbar-thumb {
  background-color: #ccc;
}
.App.light-mode .cogichat .chat .chat-input .input-container .input svg {
  stroke: #888;
}
.App.light-mode .chat-formatted .role.assistant, .App.light-mode .chat-formatted .role.system {
  background-color: #c8c8c8;
}
.App.light-mode .chat-formatted .loader span {
  background: #11181c;
}
.App.light-mode .chat-formatted .pdf-container .pdf {
  background-color: #fff;
}
.App.light-mode .chat-formatted .content.user {
  color: #383d40 !important;
}
.App.light-mode .chat-formatted .content h1 {
  color: #11181c;
}
.App.light-mode .chat-formatted .content h2 {
  color: #11181c;
}
.App.light-mode .chat-formatted .content h3 {
  color: #11181c;
}
.App.light-mode .chat-formatted .content h4 {
  color: #11181c;
}
.App.light-mode .chat-formatted .content h5 {
  color: #383d40 !important;
}
.App.light-mode .chat-formatted .content h6 {
  color: #383d40 !important;
}
.App.light-mode .chat-formatted .content p {
  color: #383d40 !important;
}
.App.light-mode .chat-formatted .content ul {
  color: #383d40 !important;
}
.App.light-mode .chat-formatted .content ul li {
  color: #383d40 !important;
}
.App.light-mode .chat-formatted .content ol {
  color: #383d40 !important;
}
.App.light-mode .chat-formatted .content ol li {
  color: #383d40 !important;
}
.App.light-mode .chat-formatted .content blockquote {
  border-left: 2px solid #383d40;
  color: #383d40 !important;
}
.App.light-mode .chat-formatted .content a {
  color: #007bff;
}
.App.light-mode .chat-formatted .content pre .codeContainer {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  background-color: #f3f3f3 !important;
}
.App.light-mode .chat-formatted .content pre .codeContainer .codeFormatted {
  background-color: none !important;
  background: none !important;
}
.App.light-mode .chat-formatted .content pre .codeContainer .codeFormatted code {
  background: none !important;
  background-color: none !important;
}
.App.light-mode .chat-formatted .content pre span.span-code {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  background-color: #f3f3f3 !important;
}
.App.light-mode .chat-formatted .content .tableContainer {
  border: 1px solid #c8c8c8;
}
.App.light-mode .chat-formatted .content .tableContainer table tbody tr:nth-child(odd) {
  background-color: #e7e7e7;
}
.App.light-mode .chat-formatted .content .tableContainer table tbody tr:hover {
  background-color: #e3cbff;
  border: 1px solid #e3cbff;
  color: #5e01ca;
}
.App.light-mode .chat-formatted .content .tableContainer table thead tr {
  border-bottom: 1px solid #c8c8c8;
}
.App.light-mode .chat-formatted .function-container .button-content {
  color: #2f0065;
}
.App.light-mode .chat-formatted .function-container .param-button svg path {
  fill: #b7bcc0;
}
.App.light-mode .chat-formatted .function-container .param-button.active:hover {
  background-color: #e3cbff;
  border: 1px solid white;
}
.App.light-mode .chat-formatted .function-container .param-button.active:hover svg path {
  fill: #2f0065;
}
.App.light-mode .chat-formatted .function-container .plurial-text-inputs-component {
  background-color: #fff;
}
.App.light-mode .settings .settings-container .paragraphe,
.App.light-mode .settings .settings-container p,
.App.light-mode .settings .loader-container .paragraphe,
.App.light-mode .settings .loader-container p {
  color: #7c7c7c;
}
.App.light-mode .settings .settings-container .textarea-container,
.App.light-mode .settings .loader-container .textarea-container {
  background-color: #f8f8f8;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.App.light-mode .settings .settings-container textarea,
.App.light-mode .settings .loader-container textarea {
  background-color: #f8f8f8;
}
.App.light-mode .settings .settings-container textarea::-webkit-scrollbar-thumb,
.App.light-mode .settings .loader-container textarea::-webkit-scrollbar-thumb {
  background-color: #ccc;
}
.App.light-mode .settings .user input:disabled {
  color: #bbbbbb;
  background-color: #dbdbdb;
}
.App.light-mode .settings .choose-avatar {
  background-color: #e2e2e2;
}
.App.light-mode .settings .choose-avatar .avatar-container::-webkit-scrollbar-thumb {
  background-color: #959595;
}
.App.light-mode .settings .choose-avatar li:hover, .App.light-mode .settings .choose-avatar li.active {
  background-color: #959595;
}
.App.light-mode .settings .credits .progress-bar {
  background-color: #e2e2e2;
}
.App.light-mode .settings .charts .grid {
  stroke: #2f2f2f;
}
.App.light-mode .settings .charts .chart-placeholder {
  background-color: #e2e2e2;
}
.App.light-mode .settings .charts .global-line path {
  stroke-width: 4;
}
.App.light-mode .settings .users {
  border: 1px solid #c8c8c8;
}
.App.light-mode .settings .users tbody tr:nth-child(even) {
  background-color: #e7e7e7;
}
.App.light-mode .settings .users tbody tr:hover {
  background-color: #e3cbff;
  color: #5e01ca;
}
.App.light-mode .settings .users tbody .disabledClass:hover {
  background-color: transparent;
  color: black;
}
.App.light-mode .settings .users thead tr {
  border-bottom: 1px solid #c8c8c8;
}
.App.light-mode .dce .tableContainer {
  border: 1px solid #c8c8c8;
}
.App.light-mode .dce .tableContainer table {
  border: 1px solid #c8c8c8;
}
.App.light-mode .dce .tableContainer table .checked {
  background: linear-gradient(45deg, rgb(142, 215, 160), transparent);
}
.App.light-mode .dce .tableContainer table .dropdown {
  border: 1px solid #c8c8c8;
}
.App.light-mode .dce .tableContainer table .dropdown:hover {
  background-color: #e3cbff;
  color: #5e01ca;
}
.App.light-mode .dce .tableContainer table .dropdown svg {
  fill: #c8c8c8;
  stroke: #c8c8c8;
}
.App.light-mode .dce .tableContainer table tbody tr:nth-child(odd) {
  background-color: #e7e7e7;
}
.App.light-mode .dce .tableContainer table tbody .hoverable:hover {
  background-color: #e3cbff;
  color: #2f0065;
}
.App.light-mode .dce .tableContainer table thead tr {
  border-bottom: 1px solid #c8c8c8;
}
.App.light-mode .toggle-darkmode {
  background-color: #c8c8c8;
}
.App.light-mode .toggle-darkmode svg {
  fill: #11181c;
}
.App.light-mode .folder {
  color: #11181c;
}
.App.dark-mode h1,
.App.dark-mode h2,
.App.dark-mode h3,
.App.dark-mode h4,
.App.dark-mode svg {
  color: #eceede;
}
.App.dark-mode textarea {
  color: white;
  background-color: #474747;
}
.App.dark-mode textarea::-webkit-scrollbar-thumb {
  background-color: #ccc;
}
.App.dark-mode p {
  color: #9ba1ac;
}
.App.dark-mode p.error {
  color: #ebbdbd;
}
.App.dark-mode input,
.App.dark-mode .action,
.App.dark-mode .switch,
.App.dark-mode .addTextInput {
  background-color: #2e2e2e;
  color: #eceede;
}
.App.dark-mode button {
  background-color: #23004b;
  color: #e3cbff;
}
.App.dark-mode button:hover {
  background-color: #5e01ca;
}
.App.dark-mode button.success {
  background-color: #074e07 !important;
  color: #c2ebbd;
}
.App.dark-mode button.error {
  background-color: #4e0707 !important;
  color: #ebbdbd;
}
.App.dark-mode button:disabled {
  background-color: #ccc;
  color: #999;
  cursor: not-allowed;
}
.App.dark-mode button:disabled svg {
  color: #999;
}
.App.dark-mode .sso {
  background-color: #2F2F2F !important;
  color: rgb(251, 251, 251) !important;
}
.App.dark-mode .sso:hover {
  background-color: #464646 !important;
}
.App.dark-mode .btn,
.App.dark-mode .specialized-chatbot > select {
  border: 1px solid #ccc;
  color: #d3d5da;
}
.App.dark-mode .btn:hover, .App.dark-mode .btn.active,
.App.dark-mode .specialized-chatbot > select:hover,
.App.dark-mode .specialized-chatbot > select.active {
  background-color: #e3cbff;
  border: 1px solid #e3cbff;
  color: #5e01ca;
}
.App.dark-mode .btn:hover svg, .App.dark-mode .btn.active svg,
.App.dark-mode .specialized-chatbot > select:hover svg,
.App.dark-mode .specialized-chatbot > select.active svg {
  color: #5e01ca;
}
.App.dark-mode .btn:disabled,
.App.dark-mode .specialized-chatbot > select:disabled {
  background-color: initial;
  border: 1px solid #ccc;
}
.App.dark-mode .specialized-chatbot > select option {
  color: #9ba1ac;
}
.App.dark-mode .loading {
  background-color: #141414;
}
.App.dark-mode .icon {
  color: #eceede;
}
.App.dark-mode .section {
  background-color: #141414;
}
.App.dark-mode .navbar {
  background-color: #141414;
}
.App.dark-mode a {
  color: #eceede;
}
.App.dark-mode .login-link {
  color: #eceede;
}
.App.dark-mode .window .topbar {
  background-color: #2e2e2e;
}
.App.dark-mode .window .content {
  background-color: #474747;
}
.App.dark-mode .window .bottombar {
  background-color: #2e2e2e;
}
.App.dark-mode .toggle-container span {
  color: #d1d5db;
}
.App.dark-mode select {
  background-color: #2e2e2e;
  color: #eceede;
}
.App.dark-mode select:hover {
  background-color: #3a3a3a;
}
.App.dark-mode select option {
  background-color: #212121;
}
.App.dark-mode select option:checked {
  background-color: #7601fd;
  color: #fff;
}
.App.dark-mode form input {
  background-color: #2e2e2e;
  color: #eceede;
}
.App.dark-mode .login {
  background-color: #141414;
}
.App.dark-mode .login .lines .line {
  background: #2e2e2e;
}
.App.dark-mode .login .lines .line::after {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
}
.App.dark-mode .login .line-separator > .line {
  background: #2e2e2e;
}
.App.dark-mode .login .cardLogin {
  background: rgba(46, 46, 46, 0.1);
}
.App.dark-mode .login form .form-group .form-head {
  color: #eceede;
}
.App.dark-mode .login form .form-group .form-head p,
.App.dark-mode .login form .form-group .form-head svg {
  color: #eceede;
}
.App.dark-mode .login form .form-group .pass {
  color: #eceede;
}
.App.dark-mode .login form .form-group .icon {
  fill: #eceede;
}
.App.dark-mode .login form .form-group input {
  background-color: #212121 !important;
  color: #eceede;
}
.App.dark-mode .login form a {
  color: #eceede;
}
.App.dark-mode .login .footer {
  background: #2e2e2e;
}
.App.dark-mode .login .footer p,
.App.dark-mode .login .footer a {
  color: #eceede;
}
.App.dark-mode .contactForm {
  background-color: #171717;
}
.App.dark-mode .contactForm textarea,
.App.dark-mode .contactForm input,
.App.dark-mode .contactForm select {
  background-color: #1c1c1c;
  color: #9ba1ac;
}
.App.dark-mode .card {
  background-color: #2e2e2e;
}
.App.dark-mode .card:hover {
  background-color: #3a3a3a;
}
.App.dark-mode .card .card-description {
  color: #9ba1ac;
}
.App.dark-mode .card .titre .icon .audit {
  color: #be4d45;
}
.App.dark-mode .card .titre .icon .formation {
  color: #cca34c;
}
.App.dark-mode .card .titre .icon .implementation {
  color: #3581af;
}
.App.dark-mode .card .titre .underline.audit svg {
  stroke: #be4d45;
}
.App.dark-mode .card .titre .underline.formation svg {
  stroke: #cca34c;
}
.App.dark-mode .card .titre .underline.implementation svg {
  stroke: #3581af;
}
.App.dark-mode .card .texte .line.audit {
  background-color: #be4d45;
}
.App.dark-mode .card .texte .line.formation {
  background-color: #cca34c;
}
.App.dark-mode .card .texte .line.implementation {
  background-color: #3581af;
}
.App.dark-mode .card .lien.audit p {
  background-color: #4f1f1b;
  color: #f3dedc;
}
.App.dark-mode .card .lien.audit p:hover {
  background-color: #be4d45;
}
.App.dark-mode .card .lien.audit .icon {
  color: #f3dedc;
}
.App.dark-mode .card .lien.formation p {
  background-color: #634c1c;
  color: #f9f5eb;
}
.App.dark-mode .card .lien.formation p:hover {
  background-color: #cca34c;
}
.App.dark-mode .card .lien.formation .icon {
  color: #f9f5eb;
}
.App.dark-mode .card .lien.implementation p {
  background-color: #112a3a;
  color: #c3dded;
}
.App.dark-mode .card .lien.implementation p:hover {
  background-color: #3581af;
}
.App.dark-mode .card .lien.implementation .icon {
  color: #c3dded;
}
.App.dark-mode .question .form p {
  color: #9ba1ac;
}
.App.dark-mode .question .form button:hover p {
  color: #9ba1ac !important;
}
.App.dark-mode .question .form input,
.App.dark-mode .question .form textarea {
  color: #c898ff !important;
  border-bottom: 1px #c898ff solid !important;
}
.App.dark-mode .question .form input::placeholder,
.App.dark-mode .question .form textarea::placeholder {
  color: #c898ff;
}
.App.dark-mode .question .form .renderer-core-form-flow {
  background-image: url("https://ik.imagekit.io/z0tzxea0wgx/Cogitum/bg__jOx__KXki.png?updatedAt=1680317119803");
}
.App.dark-mode .question .form .renderer-components-error-message-wrapper .renderer-core-html-parser p {
  color: white !important;
}
.App.dark-mode .dashboard,
.App.dark-mode .shared {
  background-color: #212121;
}
.App.dark-mode .cogichat {
  background-color: #212121;
}
.App.dark-mode .cogichat .sidebar {
  background-color: #2e2e2e;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);
  color: #d3d5da;
}
.App.dark-mode .cogichat .sidebar .arrow {
  background-color: #2e2e2e;
}
.App.dark-mode .cogichat .sidebar .logo {
  background-color: #474747;
}
.App.dark-mode .cogichat .sidebar .logo.active:hover {
  background-color: #3a3a3a;
}
.App.dark-mode .cogichat .sidebar .logo h1 span {
  color: #ad66fe !important;
}
.App.dark-mode .cogichat .sidebar .hidden {
  color: #474747;
}
.App.dark-mode .cogichat .sidebar .logout .MuiSwitch-track {
  background-color: #7601fd;
}
.App.dark-mode .cogichat .sidebar .sb.favorite .two svg.star {
  fill: #cca34c;
  stroke: #cca34c;
}
.App.dark-mode .cogichat .sidebar .sb.history .two svg.star:hover {
  fill: #cca34c;
  stroke: #cca34c;
}
.App.dark-mode .cogichat .sidebar .sb.history .two svg.trash:hover {
  fill: #be4d45;
}
.App.dark-mode .cogichat .sidebar .sb h2 {
  border-bottom: 1px solid #ccc;
}
.App.dark-mode .cogichat .sidebar .sb ul li .one p {
  color: #d3d5da;
}
.App.dark-mode .cogichat .sidebar .sb ul li .one:hover {
  background-color: #616161;
}
.App.dark-mode .cogichat .sidebar .sb ul li.active {
  background-color: #e3cbff;
  color: #170032;
}
.App.dark-mode .cogichat .sidebar .sb ul li.active p {
  color: #170032;
}
.App.dark-mode .cogichat .sidebar .sb ul li.active .one:hover {
  background-color: transparent;
}
.App.dark-mode .cogichat .sidebar .sb ul::-webkit-scrollbar-thumb {
  background-color: #ccc;
}
.App.dark-mode .cogichat .chat {
  background-color: #2e2e2e;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.App.dark-mode .cogichat .chat .chat-messages .message.unpair {
  background-color: #333333;
}
.App.dark-mode .cogichat .chat .chat-messages .message.pair {
  background-color: #3a3a3a;
}
.App.dark-mode .cogichat .chat .chat-messages::-webkit-scrollbar-thumb {
  background-color: #ccc;
  /* Set the color of the scrollbar line */
}
.App.dark-mode .cogichat .chat .new-chat p {
  color: #adadad;
}
.App.dark-mode .cogichat .chat .new-chat p span {
  color: #ad66fe;
}
.App.dark-mode .cogichat .chat .new-chat .projects-container {
  background-color: #212121;
}
.App.dark-mode .cogichat .chat .new-chat .projects-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
}
.App.dark-mode .cogichat .chat .new-chat .templates::before {
  background: linear-gradient(to right, #2e2e2e, transparent);
}
.App.dark-mode .cogichat .chat .new-chat .templates::after {
  background: linear-gradient(to left, #2e2e2e, transparent);
}
.App.dark-mode .cogichat .chat .chat-input {
  background-image: linear-gradient(to top, #212121 50%, rgba(33, 33, 33, 0) 100%);
}
.App.dark-mode .cogichat .chat .chat-input .input-container .files .addFile,
.App.dark-mode .cogichat .chat .chat-input .input-container .files .file-container {
  background-color: #2e2e2e;
}
.App.dark-mode .cogichat .chat .chat-input .input-container .files .addFile .cross,
.App.dark-mode .cogichat .chat .chat-input .input-container .files .file-container .cross {
  background-color: #474747;
}
.App.dark-mode .cogichat .chat .chat-input .input-container .new .test {
  background-color: #141414;
}
.App.dark-mode .cogichat .chat .chat-input .input-container .new .mobile-btn {
  background-color: #474747;
}
.App.dark-mode .cogichat .chat .chat-input .input-container .new .mobile-btn:hover {
  background-color: #545454;
}
.App.dark-mode .cogichat .chat .chat-input .input-container .new .mobile-btn svg {
  fill: white;
}
.App.dark-mode .cogichat .chat .chat-input .input-container .options .temperature p {
  color: white;
}
.App.dark-mode .cogichat .chat .chat-input .input-container .options .temperature input {
  accent-color: #ad66fe;
}
.App.dark-mode .cogichat .chat .chat-input .input-container .options .temperature .description {
  background-color: #616161;
}
.App.dark-mode .cogichat .chat .chat-input .input-container .options .temperature .description::after {
  border-color: transparent transparent #616161 transparent;
}
.App.dark-mode .cogichat .chat .chat-input .input-container .input .textarea-container {
  background-color: #474747;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.App.dark-mode .cogichat .chat .chat-input .input-container .input svg {
  stroke: white;
}
.App.dark-mode .chat-formatted .role.assistant, .App.dark-mode .chat-formatted .role.system {
  background-color: #2e2e2e;
}
.App.dark-mode .chat-formatted .loader span {
  background: #eceede;
}
.App.dark-mode .chat-formatted .pdf-container {
  color: #d3d5da;
}
.App.dark-mode .chat-formatted .pdf-container .pdf {
  background-color: #2e2e2e;
}
.App.dark-mode .chat-formatted .content.user {
  color: #d3d5da;
}
.App.dark-mode .chat-formatted .content h1 {
  color: #d3d5da;
}
.App.dark-mode .chat-formatted .content h2 {
  color: #d3d5da;
}
.App.dark-mode .chat-formatted .content h3 {
  color: #d3d5da;
}
.App.dark-mode .chat-formatted .content h4 {
  color: #d3d5da;
}
.App.dark-mode .chat-formatted .content h5 {
  color: #d3d5da;
}
.App.dark-mode .chat-formatted .content h6 {
  color: #d3d5da;
}
.App.dark-mode .chat-formatted .content p {
  color: #d3d5da;
}
.App.dark-mode .chat-formatted .content p span.span-code {
  color: white;
}
.App.dark-mode .chat-formatted .content ul {
  color: #d3d5da;
}
.App.dark-mode .chat-formatted .content ul li {
  color: #d3d5da;
}
.App.dark-mode .chat-formatted .content ol {
  color: #d3d5da;
}
.App.dark-mode .chat-formatted .content ol li {
  color: #d3d5da;
}
.App.dark-mode .chat-formatted .content blockquote {
  border-left: 2px solid white;
  color: #d3d5da;
}
.App.dark-mode .chat-formatted .content a {
  color: #007bff;
}
.App.dark-mode .chat-formatted .content pre .codeContainer {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  background-color: #282828 !important;
}
.App.dark-mode .chat-formatted .content pre .codeContainer .codeFormatted {
  background-color: none !important;
  background: none !important;
  color: rgb(171, 178, 191);
}
.App.dark-mode .chat-formatted .content pre .codeContainer .codeFormatted code {
  background: none !important;
  background-color: none !important;
}
.App.dark-mode .chat-formatted .content pre span.span-code {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  background-color: #282828 !important;
  color: #d3d5da;
}
.App.dark-mode .chat-formatted .content .tableContainer {
  border: 1px solid #474747;
}
.App.dark-mode .chat-formatted .content .tableContainer table {
  border: 1px solid #474747;
  color: #d3d5da;
}
.App.dark-mode .chat-formatted .content .tableContainer table tbody tr:nth-child(odd) {
  background-color: #282828;
}
.App.dark-mode .chat-formatted .content .tableContainer table tbody tr:hover {
  background-color: #e3cbff;
  border: 1px solid #e3cbff;
  color: #5e01ca;
}
.App.dark-mode .chat-formatted .content .tableContainer table thead tr {
  border-bottom: 1px solid #474747;
}
.App.dark-mode .chat-formatted .function-container .button-content {
  color: #eceede;
}
.App.dark-mode .chat-formatted .function-container .plurial-text-inputs-component {
  background-color: #616161;
}
.App.dark-mode .chat-formatted .function-container .param-button svg path {
  fill: #eceede;
}
.App.dark-mode .chat-formatted .function-container .param-button.active:hover {
  background-color: #e3cbff;
  border: 1px solid #e3cbff;
  color: #5e01ca;
}
.App.dark-mode .chat-formatted .function-container .param-button.active:hover svg path {
  fill: #5e01ca;
}
.App.dark-mode .settings .settings-container,
.App.dark-mode .settings .loader-container {
  background-color: #212121;
  color: #d3d5da;
}
.App.dark-mode .settings .settings-container .paragraphe,
.App.dark-mode .settings .settings-container p,
.App.dark-mode .settings .loader-container .paragraphe,
.App.dark-mode .settings .loader-container p {
  color: #949494;
}
.App.dark-mode .settings .settings-container .textarea-container,
.App.dark-mode .settings .loader-container .textarea-container {
  background-color: #474747;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.App.dark-mode .settings .settings-container textarea,
.App.dark-mode .settings .loader-container textarea {
  color: white;
  background-color: #474747;
}
.App.dark-mode .settings .settings-container textarea::-webkit-scrollbar-thumb,
.App.dark-mode .settings .loader-container textarea::-webkit-scrollbar-thumb {
  background-color: #ccc;
}
.App.dark-mode .settings .loader:before {
  animation: ball1 1s infinite;
  background-color: #474747;
  box-shadow: 30px 0 0 #2e2e2e;
  margin-bottom: 10px;
}
.App.dark-mode .settings .loader:after {
  animation: ball2 1s infinite;
  background-color: #616161;
  box-shadow: 30px 0 0 #7a7a7a;
}
.App.dark-mode .settings .user input:disabled {
  color: #595959;
  background-color: #3e3e3e;
}
.App.dark-mode .settings .choose-avatar {
  background-color: #2e2e2e;
}
.App.dark-mode .settings .choose-avatar .avatar-container::-webkit-scrollbar-thumb {
  background-color: #7a7a7a;
}
.App.dark-mode .settings .choose-avatar li:hover, .App.dark-mode .settings .choose-avatar li.active {
  background-color: #7a7a7a;
}
.App.dark-mode .settings .credits .progress-bar {
  background-color: #2e2e2e;
}
.App.dark-mode .settings .charts .grid {
  stroke: #949494;
}
.App.dark-mode .settings .charts .chart-placeholder {
  background-color: #2e2e2e;
}
.App.dark-mode .settings .charts .global-line path {
  stroke-width: 4;
}
.App.dark-mode .settings .users {
  border: 1px solid #474747;
}
.App.dark-mode .settings .users tbody tr:nth-child(even) {
  background-color: #282828;
}
.App.dark-mode .settings .users tbody tr:hover {
  background-color: #e3cbff;
  color: #5e01ca;
}
.App.dark-mode .settings .users tbody .disabledClass:hover {
  background-color: transparent;
  color: #d3d5da;
}
.App.dark-mode .settings .users thead tr {
  border-bottom: 1px solid #474747;
}
.App.dark-mode .dce .tableContainer {
  border: 1px solid #474747;
}
.App.dark-mode .dce .tableContainer table {
  border: 1px solid #474747;
  color: #d3d5da;
}
.App.dark-mode .dce .tableContainer table .checked {
  background: linear-gradient(45deg, rgb(52, 103, 66), transparent);
}
.App.dark-mode .dce .tableContainer table .dropdown {
  border: 1px solid #474747;
}
.App.dark-mode .dce .tableContainer table .dropdown:hover {
  background-color: #e3cbff;
  color: #2f0065;
}
.App.dark-mode .dce .tableContainer table .dropdown svg {
  fill: #474747;
  stroke: #474747;
}
.App.dark-mode .dce .tableContainer table tbody tr:nth-child(odd) {
  background-color: #282828;
}
.App.dark-mode .dce .tableContainer table tbody .hoverable:hover {
  background-color: #e3cbff;
  color: #5e01ca;
}
.App.dark-mode .dce .tableContainer table tbody svg {
  color: #5e01ca;
}
.App.dark-mode .dce .tableContainer table thead tr {
  border-bottom: 1px solid #474747;
}
.App.dark-mode .toggle-darkmode {
  border: 1px solid #eceede;
  background-color: #2e2e2e;
}
.App.dark-mode .toggle-darkmode svg {
  fill: #eceede;
}
.App.dark-mode .folder {
  color: #eceede;
}
@media only screen and (max-device-width: 425px) {
  .App .section {
    padding-left: 5vw;
    padding-right: 5vw;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .App .section {
    padding-left: 5vw;
    padding-right: 5vw;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .App .section {
    padding-left: 10vw;
    padding-right: 10vw;
  }
}
@media only screen and (min-device-width: 1241px) and (max-device-width: 5000px) {
  .App .section {
    padding-left: 15vw;
    padding-right: 15vw;
  }
}
@media only screen and (min-device-width: 1921px) {
  .App .section {
    padding-left: 20vw;
    padding-right: 20vw;
  }
}
.App h1, .App h2, .App h3, .App p, .App ul, .App li {
  margin: 0;
  padding: 0;
}
@media only screen and (max-device-width: 425px) {
  .App h1 {
    font-size: 2.5rem;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .App h1 {
    font-size: 2.5rem;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .App h1 {
    font-size: 3.5rem;
  }
}
@media only screen and (min-device-width: 1241px) and (max-device-width: 5000px) {
  .App h1 {
    font-size: 3.5rem;
  }
}
@media only screen and (min-device-width: 1921px) {
  .App h1 {
    font-size: 4rem;
  }
}
.App h1 span {
  color: #7601fd !important;
}
.App h1, .App h2, .App h3, .App h4 {
  font-family: "Tiempos";
}
.App h1 span, .App h2 span, .App h3 span, .App h4 span {
  color: #7601fd;
}
.App p {
  font-family: "Inter";
}
@media only screen and (max-device-width: 425px) {
  .App p {
    font-size: 0.9rem;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .App p {
    font-size: 0.9rem;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .App p {
    font-size: 1.2rem;
  }
}
@media only screen and (min-device-width: 1241px) and (max-device-width: 5000px) {
  .App p {
    font-size: 1.2rem;
  }
}
@media only screen and (min-device-width: 1921px) {
  .App p {
    font-size: 1.5rem;
  }
}
@media only screen and (max-device-width: 425px) {
  .App .validate span {
    font-size: 0.9rem;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .App .validate span {
    font-size: 0.9rem;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .App .validate span {
    font-size: 1.2rem;
  }
}
@media only screen and (min-device-width: 1241px) and (max-device-width: 5000px) {
  .App .validate span {
    font-size: 1.2rem;
  }
}
@media only screen and (min-device-width: 1921px) {
  .App .validate span {
    font-size: 1.5rem;
  }
}
.App input, .App textarea, .App select {
  border: none;
  border-radius: 4px;
  font-family: "Inter";
}
.App input:focus, .App textarea:focus, .App select:focus {
  outline: none;
}
.App input:focus::placeholder, .App textarea:focus::placeholder, .App select:focus::placeholder {
  color: transparent;
}
@media only screen and (max-device-width: 425px) {
  .App input, .App textarea, .App select {
    font-size: 0.9rem;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .App input, .App textarea, .App select {
    font-size: 0.9rem;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .App input, .App textarea, .App select {
    font-size: 1.2rem;
  }
}
@media only screen and (min-device-width: 1241px) and (max-device-width: 5000px) {
  .App input, .App textarea, .App select {
    font-size: 1.2rem;
  }
}
@media only screen and (min-device-width: 1921px) {
  .App input, .App textarea, .App select {
    font-size: 1.5rem;
  }
}
.App select {
  cursor: pointer;
}
.App button {
  border: none;
  border-radius: 4px;
  padding: 0.5rem 0.5rem;
  cursor: pointer;
  transition: background-color 0.1s ease, opacity 0.1s ease;
}
.App button.success {
  cursor: not-allowed;
}
.App .icon.clickable:hover {
  cursor: pointer;
}
.App .alert {
  font-size: 0.7em;
  padding: 10px;
  border-radius: 20px;
}
.App .alert.success {
  background-color: rgb(162, 225, 162);
  color: green !important;
  border: 1px solid green;
}
.App .alert.error {
  background-color: rgb(225, 162, 162);
  color: rgb(164, 0, 0) !important;
  border: 1px solid rgb(164, 0, 0);
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  pointer-events: none;
  transition: opacity 0.5s ease-in-out;
}
.loading .logo {
  animation: logo-animation 2s ease-in-out infinite;
  transform-origin: center;
  opacity: 0;
}
.loading .logo img {
  height: auto;
}
@media only screen and (max-device-width: 425px) {
  .loading .logo img {
    width: 10vw;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .loading .logo img {
    width: 10vw;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .loading .logo img {
    width: 7vw;
  }
}
@media only screen and (min-device-width: 1241px) and (max-device-width: 5000px) {
  .loading .logo img {
    width: 3vw;
  }
}
@media only screen and (min-device-width: 1921px) {
  .loading .logo img {
    width: 3vw;
  }
}
.loading.is-loading {
  opacity: 0;
}
@keyframes logo-animation {
  0% {
    opacity: 0.8;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    opacity: 0.8;
    transform: scale(1);
  }
}

.login {
  display: flex;
  place-content: center;
  align-items: center;
  overflow: hidden;
}
.login .lines {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  width: 90vw;
}
.login .lines .line {
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  left: 50%;
  overflow: hidden;
}
.login .lines .line::after {
  content: "";
  display: block;
  position: absolute;
  height: 15vh;
  width: 100%;
  top: -50%;
  left: 0;
  animation: drop 7s 0s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.login .lines .line:nth-child(1) {
  margin-left: -25%;
}
.login .lines .line:nth-child(1)::after {
  animation-delay: 2s;
}
.login .lines .line:nth-child(3) {
  margin-left: 25%;
}
.login .lines .line:nth-child(3)::after {
  animation-delay: 2.5s;
}
@keyframes drop {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}
.login .cardLogin {
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.9px);
  -webkit-backdrop-filter: blur(88.9px);
  border: 1px solid rgb(255, 255, 255);
  position: absolute;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none !important;
  gap: 0.5vh;
}
@media only screen and (max-device-width: 425px) {
  .login .cardLogin {
    width: 60%;
    padding: 1rem;
  }
  .login .cardLogin.password-recovery {
    width: 80% !important;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .login .cardLogin {
    width: 50%;
    padding: 2rem;
  }
  .login .cardLogin.password-recovery {
    width: 90% !important;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .login .cardLogin {
    width: 40%;
    padding: 2rem;
  }
  .login .cardLogin.password-recovery {
    width: 80% !important;
  }
}
@media only screen and (min-device-width: 1241px) and (max-device-width: 5000px) {
  .login .cardLogin {
    width: 15%;
    padding: 2rem;
  }
  .login .cardLogin.password-recovery {
    width: 30% !important;
  }
}
@media only screen and (min-device-width: 1921px) {
  .login .cardLogin {
    width: 13%;
    margin: 0;
    padding: 1.5rem;
  }
  .login .cardLogin.password-recovery {
    width: 20% !important;
  }
}
.login .cardLogin.password-recovery .password-text {
  text-align: center;
  font-size: 0.8rem;
  margin-bottom: 4vh;
}
.login .cardLogin a {
  font-family: "Inter";
}
@media only screen and (max-device-width: 425px) {
  .login .cardLogin a {
    font-size: 0.8rem;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .login .cardLogin a {
    font-size: 0.8rem;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .login .cardLogin a {
    font-size: 1rem;
  }
}
@media only screen and (min-device-width: 1241px) and (max-device-width: 5000px) {
  .login .cardLogin a {
    font-size: 1rem;
  }
}
@media only screen and (min-device-width: 1921px) {
  .login .cardLogin a {
    font-size: 0.7rem;
  }
}
.login .cardLogin .logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  position: relative;
}
.login .cardLogin .logo img {
  width: 80%;
  height: auto;
  margin-bottom: 20px;
}
.login .cardLogin form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
@media only screen and (max-device-width: 425px) {
  .login .cardLogin form {
    gap: 1vh;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .login .cardLogin form {
    gap: 1vh;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .login .cardLogin form {
    gap: 1.5vh;
  }
}
@media only screen and (min-device-width: 1241px) and (max-device-width: 5000px) {
  .login .cardLogin form {
    gap: 1.5vh;
  }
}
@media only screen and (min-device-width: 1921px) {
  .login .cardLogin form {
    gap: 0.5vh;
  }
}
.login .cardLogin form img {
  padding: 0 2vw;
}
.login .cardLogin form .form-group {
  display: flex;
  flex-direction: column;
  gap: 0.2vh;
  width: 100%;
}
.login .cardLogin form .form-group .form-head {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
}
.login .cardLogin form .form-group .form-head p, .login .cardLogin form .form-group .form-head svg {
  font-size: 0.9rem;
}
.login .cardLogin form .form-group .pass {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.login .cardLogin form .form-group .pass span {
  margin: 0;
  padding: 0;
  font-family: "Inter";
}
.login .cardLogin form .form-group .pass span:hover {
  cursor: pointer;
}
.login .cardLogin form .form-group input {
  overflow: hidden;
  font-family: "Inter";
  background: none;
  padding: 10px;
  font-size: 0.8rem;
}
@media only screen and (max-device-width: 425px) {
  .login .cardLogin form .form-group input {
    border-radius: 5px;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .login .cardLogin form .form-group input {
    border-radius: 5px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .login .cardLogin form .form-group input {
    border-radius: 5px;
  }
}
@media only screen and (min-device-width: 1241px) and (max-device-width: 5000px) {
  .login .cardLogin form .form-group input {
    border-radius: 5px;
  }
}
@media only screen and (min-device-width: 1921px) {
  .login .cardLogin form .form-group input {
    border-radius: 5px;
  }
}
.login .cardLogin form button {
  width: 100%;
  font-size: 0.8rem;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}
.login .cardLogin form .errormsg {
  font-size: 14px;
}
.login .cardLogin .line-separator {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2vh 0;
}
.login .cardLogin .line-separator .line {
  width: 40%;
  height: 1px;
}
.login .cardLogin .sso {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 10px;
}
.login .cardLogin .sso img {
  width: auto;
  height: 20px;
}
.login .footer {
  position: absolute;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3vh;
  font-family: "Inter";
}
.login .footer p {
  font-size: 15px;
}
.login .footer p a {
  font-family: "Apax";
  text-decoration: none;
}

.shared {
  display: flex;
}
.shared .dashboard-container {
  flex: 3 !important;
}
.shared .pdf-viewer-container {
  flex: 2 !important;
}
.shared table {
  font-family: "Inter";
}
.shared .dce {
  width: 100%;
  overflow-x: auto;
  margin-top: 2vh;
}
.shared .dce .header {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}
.shared .dce .head {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 5vh;
}
.shared .dce .head input {
  padding: 5px;
}
.shared .dce .tableContainer {
  width: calc(100% - 2px);
  overflow-x: auto;
  margin-top: 2vh;
  border-radius: 10px;
}
.shared .dce .tableContainer .category-dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Tiempos";
}
.shared .dce .tableContainer table {
  border-radius: 10px;
  min-width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  font-family: "Inter";
  box-sizing: border-box;
}
.shared .dce .tableContainer table th,
.shared .dce .tableContainer table td {
  padding: 24px;
  text-align: left;
  box-sizing: border-box;
  font-size: 0.9rem;
  height: 100%;
}
.shared .dce .tableContainer table th .text,
.shared .dce .tableContainer table td .text {
  position: relative;
  display: flex;
  max-width: 300px;
  flex-wrap: wrap;
}
.shared .dce .tableContainer table th .roro,
.shared .dce .tableContainer table td .roro {
  position: relative;
  margin-top: 60px;
}
.shared .dce .tableContainer table th .roro button,
.shared .dce .tableContainer table td .roro button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  background-color: rgb(146, 194, 146);
}
.shared .dce .tableContainer table th .roro button:hover,
.shared .dce .tableContainer table td .roro button:hover {
  background-color: rgb(87, 122, 87);
}
.shared .dce .tableContainer table th .roro button:hover svg,
.shared .dce .tableContainer table td .roro button:hover svg {
  color: rgb(173, 221, 181);
}
.shared .dce .tableContainer table th .roro svg,
.shared .dce .tableContainer table td .roro svg {
  color: rgb(40, 45, 40);
}
.shared .dce .tableContainer table th .test,
.shared .dce .tableContainer table td .test {
  position: relative;
}
.shared .dce .tableContainer table th textarea,
.shared .dce .tableContainer table td textarea {
  width: 90%;
  font-size: 0.9rem;
}
.shared .dce .tableContainer table .CheckBox {
  cursor: pointer;
}
.shared .dce .tableContainer table .editable {
  position: relative;
  padding-right: 24px;
  /* Ajustez en fonction de la taille de votre icône */
}
.shared .dce .tableContainer table .edit-icon {
  visibility: hidden;
  position: absolute;
  right: 0;
  bottom: -20px;
  cursor: pointer;
  height: 20px;
}
.shared .dce .tableContainer table .editable:hover .edit-icon {
  visibility: visible;
}
.shared .dce .tableContainer table .files {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}
.shared .dce .tableContainer table tbody {
  box-sizing: border-box;
  position: relative;
}
.shared .dce .tableContainer table tbody tr {
  border-radius: 10px;
}
.shared .dce .tableContainer table tbody tr:hover {
  cursor: pointer;
}
.shared .dce .tableContainer table tbody table {
  width: 100%;
}
.shared .dce .tableContainer table .dropdown-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 30px;
}
.shared .dce .tableContainer table .dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 30px;
  padding: 15px;
}
.shared .dce .tableContainer table .nested-table {
  box-sizing: border-box;
  border-radius: 10px;
  width: 100% !important;
}
.shared .dce .tableContainer table .nested-table.editable th:nth-child(1) {
  width: 3%;
}
.shared .dce .tableContainer table .nested-table.editable th:nth-child(2) {
  width: 40%;
}
.shared .dce .tableContainer table .nested-table.editable th:nth-child(3) {
  width: 30%;
}
.shared .dce .tableContainer table .nested-table.not-editable th:nth-child(1) {
  width: 40%;
}
.shared .dce .tableContainer table .nested-table.not-editable th:nth-child(2) {
  width: 30%;
}
.shared .dce .tableContainer table .nested-table tr {
  background-color: inherit;
}
.shared .dce .tableContainer table .nested-table tr .link {
  text-decoration: underline;
}
.shared .dce .tableContainer table .nested-table tr:hover {
  background-color: inherit;
  /* ou la couleur d'arrière-plan initiale que vous souhaitez */
  border: inherit;
  /* ou la bordure initiale que vous souhaitez */
  color: inherit;
  /* ou la couleur initiale que vous souhaitez */
  cursor: auto;
}
.shared .dce .progress-bar {
  width: 100%;
  height: 30px;
  background-color: #e0e0e0;
  border-radius: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
}
.shared .dce .progress-bar .progress-bar-fill {
  height: 100%;
  background-color: #4caf50;
  border-radius: 50px;
  transition: width 0.5s ease;
}
.shared .dce .progress-bar .progress-bar-fill .status-info {
  position: absolute;
  left: 50%;
  top: 25%;
  transform: translateX(-50%);
}
.shared .dossier .folder {
  margin-top: 2vh;
}
.shared .dossier .link {
  text-decoration: underline;
}

.toggle-darkmode {
  cursor: pointer;
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 50px;
  right: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-in;
}
.toggle-darkmode svg {
  max-width: 80%;
  height: auto;
}

.toggle-darkmode:hover {
  transform: scale(1.1);
}

.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
}
.dashboard .choose {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 3vh;
}
@media only screen and (max-device-width: 425px) {
  .dashboard .choose {
    margin: 0 2vw;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .dashboard .choose {
    margin: 0 2vw;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .dashboard .choose {
    margin: 0 10vw;
  }
}
@media only screen and (min-device-width: 0px) and (max-device-width: 0px) {
  .dashboard .choose {
    margin: 0 20vw;
  }
}
@media only screen and (min-device-width: 1241px) and (max-device-width: 5000px) {
  .dashboard .choose {
    margin: 0 20vw;
  }
}
.dashboard .choose .logo {
  position: relative;
}
@media only screen and (max-device-width: 425px) {
  .dashboard .choose .logo {
    width: 50%;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .dashboard .choose .logo {
    width: 20%;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .dashboard .choose .logo {
    width: 20%;
  }
}
@media only screen and (min-device-width: 0px) and (max-device-width: 0px) {
  .dashboard .choose .logo {
    width: 20%;
  }
}
@media only screen and (min-device-width: 1241px) and (max-device-width: 5000px) {
  .dashboard .choose .logo {
    width: 10%;
  }
}
.dashboard .choose .logo img {
  width: 100%;
  height: auto;
}
.dashboard .choose .plugins {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1vw;
}
@media only screen and (max-device-width: 425px) {
  .dashboard .choose .plugins {
    min-height: 40px;
    flex-direction: column;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .dashboard .choose .plugins {
    min-height: 40px;
    flex-direction: column;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .dashboard .choose .plugins {
    min-height: 400px;
    flex-direction: row;
  }
}
@media only screen and (min-device-width: 0px) and (max-device-width: 0px) {
  .dashboard .choose .plugins {
    min-height: 400px;
    flex-direction: row;
  }
}
@media only screen and (min-device-width: 1241px) and (max-device-width: 5000px) {
  .dashboard .choose .plugins {
    min-height: 400px;
    flex-direction: row;
  }
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
  color: #333;
}
@media only screen and (max-device-width: 425px) {
  .card {
    width: 80%;
    min-height: 20vh;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .card {
    width: 40%;
    min-height: 40px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .card {
    width: 30%;
    min-height: 400px;
  }
}
@media only screen and (min-device-width: 0px) and (max-device-width: 0px) {
  .card {
    width: 20%;
    min-height: 400px;
  }
}
@media only screen and (min-device-width: 1241px) and (max-device-width: 5000px) {
  .card {
    width: 20%;
    min-height: 400px;
  }
}
.card .card-content {
  padding: 16px;
  display: flex;
  flex-direction: column;
  width: calc(100% - 32px);
  gap: 2vh;
  align-items: flex-start;
  justify-content: center;
}
.card .card-content .card-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}
.card .card-content .card-title h3 {
  font-family: "Montserrat";
  font-weight: bold;
}
.card .card-content .card-description {
  font-size: 1em;
}

.charts {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.charts .month {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}
.charts .month .label {
  display: flex;
  align-items: center;
  font-family: "Inter";
}
.charts .month .left, .charts .month .right {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.charts .chart-placeholder {
  height: 100%;
  width: 100%;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.charts .chart-placeholder .spinner {
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #7601fd; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.charts .global-line {
  font-family: "Inter";
  font-size: 0.7em;
}
.charts .global-line path {
  stroke-width: 4;
}

.dashboard-container {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.dashboard-container .cogichat {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.dashboard-container .cogichat .btn {
  cursor: pointer;
  padding: 10px 10px;
  text-align: center;
  border-radius: 5px;
  font-family: "Inter";
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dashboard-container .cogichat .sidebar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  gap: 20px;
  z-index: 300;
  transition: width 0.1s, min-width 0.1s;
}
@media only screen and (max-device-width: 425px) {
  .dashboard-container .cogichat .sidebar {
    position: absolute;
    width: 85%;
  }
  .dashboard-container .cogichat .sidebar.open {
    min-width: 300px;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .dashboard-container .cogichat .sidebar {
    position: absolute;
    width: 85%;
  }
  .dashboard-container .cogichat .sidebar.open {
    min-width: 300px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .dashboard-container .cogichat .sidebar {
    position: absolute;
    width: 50%;
  }
  .dashboard-container .cogichat .sidebar.open {
    min-width: 300px;
  }
}
@media only screen and (min-device-width: 0px) and (max-device-width: 0px) {
  .dashboard-container .cogichat .sidebar {
    position: relative;
    width: 18%;
  }
  .dashboard-container .cogichat .sidebar.open {
    min-width: 450px;
  }
}
@media only screen and (min-device-width: 1241px) and (max-device-width: 5000px) {
  .dashboard-container .cogichat .sidebar {
    position: relative;
    width: 18%;
  }
  .dashboard-container .cogichat .sidebar.open {
    min-width: 450px;
  }
}
.dashboard-container .cogichat .sidebar.absolute {
  position: absolute;
}
.dashboard-container .cogichat .sidebar.close {
  width: 0%;
  padding: 0;
}
.dashboard-container .cogichat .sidebar .arrow {
  cursor: pointer;
  z-index: 40;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  position: absolute;
  top: 10px;
  right: -35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-container .cogichat .sidebar .sidebar-content {
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.dashboard-container .cogichat .sidebar .sidebar-content .logo {
  margin: 3px;
  padding: 5px;
  display: flex;
  border-radius: 10px;
  width: 90%;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dashboard-container .cogichat .sidebar .sidebar-content .logo.active:hover {
  cursor: pointer;
}
.dashboard-container .cogichat .sidebar .sidebar-content .logo .box {
  display: flex;
  justify-content: center;
  flex: 5;
  position: relative;
}
.dashboard-container .cogichat .sidebar .sidebar-content .logo .box img {
  width: 40%;
  height: auto;
}
.dashboard-container .cogichat .sidebar .sidebar-content .logo .back {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  cursor: pointer;
}
.dashboard-container .cogichat .sidebar .sidebar-content .menu {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
}
.dashboard-container .cogichat .sidebar .sidebar-content .menu svg {
  fill: none;
}
.dashboard-container .cogichat .sidebar .sidebar-content .logout {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.dashboard-container .cogichat .sidebar .sidebar-content .logout .btn:first-child {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-grow: 1;
}
.dashboard-container .cogichat .sidebar .sidebar-content .logout .btn:first-child .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  min-width: 1em;
}
.dashboard-container .cogichat .sidebar .sidebar-content .logout .btn:first-child .text {
  white-space: nowrap; /* Empêche le texte de passer à la ligne */
  overflow: hidden; /* Cache le débordement de texte */
  text-overflow: ellipsis;
}
.dashboard-container .cogichat .sidebar .sidebar-content .logout .toggle-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-container .cogichat .sidebar .sidebar-content .specialized-chatbot {
  font-size: 0.9rem;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dashboard-container .cogichat .sidebar .sidebar-content .specialized-chatbot select {
  font-size: 0.9rem;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}
.dashboard-container .cogichat .sidebar .sidebar-content .specialized-chatbot select option {
  border: none;
  padding: 5px 10px;
  border-radius: 50px;
  outline: none;
  transition: background-color 0.2s ease-in-out;
}
.dashboard-container .cogichat .sidebar .sidebar-content .specialized-chatbot select option:hover {
  cursor: pointer;
}
.dashboard-container .cogichat .sidebar .sidebar-content .userselector {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}
.dashboard-container .cogichat .sidebar .sidebar-content .userselector select {
  font-size: 0.9rem;
}
.dashboard-container .cogichat .sidebar .sidebar-content .new {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
  position: relative;
}
.dashboard-container .cogichat .sidebar .sidebar-content .new .new-chat {
  flex: 6;
}
.dashboard-container .cogichat .sidebar .sidebar-content .new .folder {
  flex: 1;
}
.dashboard-container .cogichat .sidebar .sidebar-content .sb {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.dashboard-container .cogichat .sidebar .sidebar-content .sb.history {
  flex: 3;
}
.dashboard-container .cogichat .sidebar .sidebar-content .sb.favorite {
  flex: 5;
}
.dashboard-container .cogichat .sidebar .sidebar-content .sb h2 {
  margin: 0 0 10px 0;
  font-size: 18px;
  padding: 10px;
  width: 100%;
  text-align: left;
}
.dashboard-container .cogichat .sidebar .sidebar-content .sb ul {
  list-style-type: none;
  margin: 0;
  padding-right: 2%;
  height: 100%;
  width: 98%;
  overflow-y: auto;
}
.dashboard-container .cogichat .sidebar .sidebar-content .sb ul li {
  font-family: "Inter";
  margin-bottom: 10px;
  transition: background-color 0.2s ease-in-out;
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 5px;
}
.dashboard-container .cogichat .sidebar .sidebar-content .sb ul li .one {
  cursor: pointer;
  border-radius: 5px;
  padding: 4px;
  flex: 3;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  overflow-x: hidden;
}
.dashboard-container .cogichat .sidebar .sidebar-content .sb ul li .one svg {
  flex: 1;
  fill: none;
}
.dashboard-container .cogichat .sidebar .sidebar-content .sb ul li .one p {
  flex: 6;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10px;
  font-size: 1rem;
}
.dashboard-container .cogichat .sidebar .sidebar-content .sb ul li .two {
  border-radius: 5px;
  padding: 4px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex: 1;
}
.dashboard-container .cogichat .sidebar .sidebar-content .sb ul li .two svg {
  cursor: pointer;
  z-index: 10;
  fill: none;
  transition: transform 0.2s ease-in;
}
.dashboard-container .cogichat .sidebar .sidebar-content .sb ul li .two svg.star:hover {
  transform: scale(1.3);
}
.dashboard-container .cogichat .sidebar .sidebar-content .sb ul li .two svg.trash:hover {
  transform: scale(1.3);
}
.dashboard-container .cogichat .sidebar .sidebar-content .sb ul::-webkit-scrollbar {
  width: 4px;
}
.dashboard-container .cogichat .sidebar .sidebar-content .sb ul::-webkit-scrollbar-thumb {
  border-radius: 2px; /* Set the border radius for a rounded scrollbar line */
}
.dashboard-container .cogichat .sidebar .sidebar-content .delete {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}
.dashboard-container .cogichat .sidebar .sidebar-content .delete .delete-chats {
  flex: 1;
}
.dashboard-container .cogichat .sidebar .sidebar-content .delete .toggle-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.dashboard-container .cogichat .sidebar .email {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.dashboard-container .cogichat .sidebar .email p {
  font-size: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dashboard-container .cogichat .big-container {
  display: flex;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  min-width: 70%;
}
.dashboard-container .cogichat .big-container .chat {
  display: flex;
  flex: 3;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  max-width: 100%;
}
.dashboard-container .cogichat .big-container .chat .chat-messages {
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  position: relative;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message {
  display: flex;
  flex-direction: row;
  position: relative;
  max-width: 100%;
}
@media only screen and (max-device-width: 425px) {
  .dashboard-container .cogichat .big-container .chat .chat-messages .message {
    padding: 50px 5vw;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .dashboard-container .cogichat .big-container .chat .chat-messages .message {
    padding: 50px 5vw;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .dashboard-container .cogichat .big-container .chat .chat-messages .message {
    padding: 50px 10%;
  }
}
@media only screen and (min-device-width: 0px) and (max-device-width: 0px) {
  .dashboard-container .cogichat .big-container .chat .chat-messages .message {
    padding: 50px 20%;
  }
}
@media only screen and (min-device-width: 1241px) and (max-device-width: 5000px) {
  .dashboard-container .cogichat .big-container .chat .chat-messages .message {
    padding: 50px 20%;
  }
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message span {
  flex: 1;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .message-actions {
  position: absolute;
  top: 20px;
  right: 20%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
@media only screen and (max-device-width: 425px) {
  .dashboard-container .cogichat .big-container .chat .chat-messages .message .message-actions {
    right: 20px;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .dashboard-container .cogichat .big-container .chat .chat-messages .message .message-actions {
    right: 20px;
  }
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .message-actions .btn {
  cursor: pointer;
  padding: 5px 5px;
  text-align: center;
  border-radius: 5px;
  font-family: "Inter";
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  font-size: 0.9rem;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted {
  margin: 0.5em 0;
  padding: 0.5em;
  border-radius: 4px;
  flex: 3;
  display: flex;
  flex-direction: row;
  position: relative;
  max-width: 90%;
  gap: 10px;
  max-width: 100%;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .role {
  border-radius: 5px;
  width: 30px;
  height: 30px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .role img {
  width: 90%;
  height: auto;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 20px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .loader span {
  position: absolute;
  top: 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: loader 1.4s infinite ease-in-out both;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .loader span:nth-child(1) {
  left: 4px;
  animation-delay: -0.32s;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .loader span:nth-child(2) {
  left: 16px;
  animation-delay: -0.16s;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .loader span:nth-child(3) {
  left: 27px;
  animation-delay: 0;
}
@keyframes loader {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .pdf-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: calc(100% - 40px);
  align-items: flex-start;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .pdf-container .pdf {
  display: flex;
  flex-direction: row;
  height: 50px;
  position: relative;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 10px;
  gap: 20px;
  align-items: center;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .pdf-container .pdf .logo {
  height: 100%;
  position: relative;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .pdf-container .pdf .logo img {
  height: 100%;
  width: auto;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .pdf-container .pdf .content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .pdf-container .pdf .content .pdf-title, .dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .pdf-container .pdf .content .status {
  font-family: "Inter";
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .pdf-container .pdf .content .status.ok p {
  color: #16be59 !important;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .pdf-container .pdf .content .status.nok p {
  color: #ca3828 !important;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .pdf-container .infos {
  font-family: "Inter";
  cursor: pointer;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .pdf-container .infos:hover {
  text-decoration: underline;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .pdf-container .pdf-content {
  font-family: "Inter";
  flex: 6;
  line-height: 25px;
  font-size: "Inter";
  max-width: calc(100% - 40px);
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .pdf-container .pdf-content .pdf-page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  cursor: pointer;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .pdf-container .pdf-content .pdf-page .headline {
  margin-top: 10px;
  gap: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .pdf-container .pdf-content .pdf-page:hover {
  text-decoration: solid;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .pdf-container .pdf-content .pdf-page input {
  cursor: pointer;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .json-container {
  width: 100%;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .json-container .buttons {
  display: flex;
  gap: 5px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content {
  flex: 6;
  line-height: 25px;
  font-size: "Inter";
  max-width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content.user {
  font-family: "Inter";
  white-space: pre-wrap;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content h1 {
  font-size: 2em;
  line-height: 1.2em;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content h2 {
  font-size: 1.75em;
  line-height: 1.2em;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content h3 {
  font-size: 1.5em;
  line-height: 1.2em;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content h4 {
  font-size: 1.25em;
  line-height: 1.2em;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content h5 {
  font-size: 1em;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content h6 {
  font-size: 0.75em;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content hr {
  width: 100%;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content p {
  font-size: 1em;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content p span.span-code {
  font-weight: 900;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content ul {
  margin-left: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content ul li {
  font-family: "Inter";
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content ol {
  margin-left: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content ol li {
  font-family: "Inter";
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content blockquote {
  padding-left: 1em;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content a {
  font-family: "serif";
  text-decoration: none;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content a:hover {
  text-decoration: underline;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content em {
  font-style: italic;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content strong {
  font-weight: bold;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content pre {
  border-radius: 10px;
  position: relative;
  max-width: 100%;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content pre .codeContainer {
  position: relative;
  display: inline-block;
  width: 100%;
  border-radius: 10px !important;
  overflow: auto;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content pre .codeContainer .codeFormatted {
  border-radius: 10px !important;
  position: relative;
  font-family: "Roboto Mono", monospace;
  border-radius: 10px !important;
  padding: 0 1em !important;
  margin: 0.5em 0px !important;
  line-height: 1.5em !important;
  font-size: 1em !important;
  white-space: pre !important;
  max-width: 100% !important; /* Limit width to prevent overflow */
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content pre .codeContainer .codeFormatted code {
  display: inline-block;
  white-space: pre;
  overflow: auto;
  max-width: 100%; /* Limit width to prevent overflow */
  padding: 10px 0;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content pre .codeContainer .copyButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 3px;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content pre .codeContainer .copyButton:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content pre span.span-code {
  display: block;
  padding: 10px;
  border-radius: 5px;
  font-weight: 600;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content .tableContainer {
  width: 100%;
  overflow: auto;
  border-radius: 10px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content .tableContainer table {
  table-layout: fixed;
  font-family: "Inter";
  box-sizing: border-box;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 1em;
  min-width: 100%;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content .tableContainer table th, .dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content .tableContainer table td {
  padding: 10px;
  text-align: left;
  box-sizing: border-box;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content .tableContainer table th .text, .dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content .tableContainer table td .text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content .tableContainer table thead tr {
  border-radius: 10px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .content .tableContainer table tbody tr:hover {
  cursor: pointer;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  flex-grow: 1;
  position: relative;
  margin: 0 auto;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-container .admin-actions {
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: center;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-container .admin-actions select, .dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-container .admin-actions .prod-button {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.9rem;
  font-family: "Inter";
  border-radius: 5px;
  padding: 0 5px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-container .admin-actions select:hover, .dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-container .admin-actions .prod-button:hover {
  cursor: pointer;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-container .description p {
  font-size: 1rem;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-container button {
  z-index: 50;
  position: absolute;
  height: 4vh;
  border-radius: 5px;
  width: 100%;
  bottom: calc(0px - 0.5em - 0.5em - 50px - 2vh);
  left: 50%;
  transform: translateX(-50%);
  position: relative;
  overflow: hidden;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-container button .button-content {
  font-family: "Tiempos";
  font-weight: 900;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 60;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-container button .progressBar {
  z-index: 55;
  border-radius: 20px;
  position: absolute;
  background-color: #c898ff;
  height: 100%;
  left: 0;
  bottom: 0;
  top: 0;
  transition: width 0.4s ease-in-out;
  width: 0%;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .image {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  position: relative;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .image .img-container {
  border-radius: 20px;
  overflow: hidden;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .image .img-container img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .image .buttons {
  display: flex;
  gap: 20px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE {
  width: 100%;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .footer-btn {
  margin-top: 2vh;
  display: flex;
  gap: 20px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dossier .folder {
  margin-top: 2vh;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dossier .link {
  text-decoration: underline;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce {
  width: 100%;
  overflow-x: auto;
  margin-top: 2vh;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .header {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .head {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 5vh;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .head input {
  padding: 5px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer {
  width: calc(100% - 2px);
  overflow-x: auto;
  margin-top: 2vh;
  border-radius: 10px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer .category-dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Tiempos";
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table {
  border-radius: 10px;
  min-width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  font-family: "Inter";
  box-sizing: border-box;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table th,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table td {
  padding: 24px;
  text-align: left;
  box-sizing: border-box;
  font-size: 0.9rem;
  height: 100%;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table th .text,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table td .text {
  position: relative;
  display: flex;
  max-width: 300px;
  flex-wrap: wrap;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table th .roro,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table td .roro {
  position: relative;
  margin-top: 60px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table th .roro button,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table td .roro button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  background-color: rgb(146, 194, 146);
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table th .roro button:hover,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table td .roro button:hover {
  background-color: rgb(87, 122, 87);
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table th .roro button:hover svg,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table td .roro button:hover svg {
  color: rgb(173, 221, 181);
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table th .roro svg,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table td .roro svg {
  color: rgb(40, 45, 40);
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table th .test,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table td .test {
  position: relative;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table th textarea,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table td textarea {
  width: 90%;
  font-size: 0.9rem;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table .CheckBox {
  cursor: pointer;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table .editable {
  position: relative;
  padding-right: 24px;
  /* Ajustez en fonction de la taille de votre icône */
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table .edit-icon {
  visibility: hidden;
  position: absolute;
  right: 0;
  bottom: -20px;
  cursor: pointer;
  height: 20px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table .editable:hover .edit-icon {
  visibility: visible;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table .files {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table tbody {
  box-sizing: border-box;
  position: relative;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table tbody tr {
  border-radius: 10px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table tbody tr:hover {
  cursor: pointer;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table tbody table {
  width: 100%;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table .dropdown-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 30px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table .dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 30px;
  padding: 15px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table .nested-table {
  box-sizing: border-box;
  border-radius: 10px;
  width: 100% !important;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table .nested-table.editable th:nth-child(1) {
  width: 3%;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table .nested-table.editable th:nth-child(2) {
  width: 40%;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table .nested-table.editable th:nth-child(3) {
  width: 30%;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table .nested-table.not-editable th:nth-child(1) {
  width: 40%;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table .nested-table.not-editable th:nth-child(2) {
  width: 30%;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table .nested-table tr {
  background-color: inherit;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table .nested-table tr .link {
  text-decoration: underline;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .tableContainer table .nested-table tr:hover {
  background-color: inherit;
  /* ou la couleur d'arrière-plan initiale que vous souhaitez */
  border: inherit;
  /* ou la bordure initiale que vous souhaitez */
  color: inherit;
  /* ou la couleur initiale que vous souhaitez */
  cursor: auto;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .progress-bar {
  width: 100%;
  height: 30px;
  background-color: #e0e0e0;
  border-radius: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .progress-bar .progress-bar-fill {
  height: 100%;
  background-color: #4caf50;
  border-radius: 50px;
  transition: width 0.5s ease;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-DCE .dce .progress-bar .progress-bar-fill .status-info {
  position: absolute;
  left: 50%;
  top: 25%;
  transform: translateX(-50%);
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO {
  width: 100%;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .footer-btn {
  margin-top: 2vh;
  display: flex;
  gap: 20px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce {
  width: 100%;
  overflow-x: auto;
  margin-top: 2vh;
  /* Styles pour les lignes développées */
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .header {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .head {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 5vh;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .head input {
  padding: 5px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .open {
  cursor: pointer;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .open td {
  border-bottom: 2px solid #ddd;
  /* Exemple de bordure pour mieux voir la ligne */
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .open + tr {
  display: table-row;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer {
  width: calc(100% - 2px);
  overflow-x: auto;
  margin-top: 2vh;
  border-radius: 10px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer .category-dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Tiempos";
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table {
  border-radius: 10px;
  min-width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  font-family: "Inter";
  box-sizing: border-box;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table th,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table td {
  padding: 24px;
  text-align: left;
  box-sizing: border-box;
  font-size: 0.9rem;
  height: 100%;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table th .nocompare th,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table th .nocompare td,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table td .nocompare th,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table td .nocompare td {
  padding: 8px;
  border: 1px solid #ddd;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table th .nocompare th.conforme,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table th .nocompare td.conforme,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table td .nocompare th.conforme,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table td .nocompare td.conforme {
  width: 5%; /* Extrait prend 2/10 ou 20% */
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table th .nocompare th.extrait,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table th .nocompare td.extrait,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table td .nocompare th.extrait,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table td .nocompare td.extrait {
  width: 25%; /* Extrait prend 2/10 ou 20% */
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table th .nocompare th.explication,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table th .nocompare td.explication,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table td .nocompare th.explication,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table td .nocompare td.explication {
  width: 60%; /* Explication prend 3/10 ou 60% */
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table th .nocompare th.page,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table th .nocompare td.page,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table td .nocompare th.page,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table td .nocompare td.page {
  width: 5%;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table th .compare th,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table th .compare td,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table td .compare th,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table td .compare td {
  padding: 8px;
  border: 1px solid #ddd;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table th .compare th.extrait,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table th .compare td.extrait,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table td .compare th.extrait,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table td .compare td.extrait {
  width: 25%; /* Extrait prend 2/10 ou 20% */
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table th .compare th.extrait span,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table th .compare td.extrait span,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table td .compare th.extrait span,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table td .compare td.extrait span {
  display: block; /* Pour mettre le contenu du span sur une nouvelle ligne */
  font-size: smaller; /* Pour réduire la taille de la police */
  font-style: italic; /* Pour appliquer l'italique */
  margin-top: 5px; /* Pour espacer un peu du texte précédent, optionnel */
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table th .compare th.explication,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table th .compare td.explication,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table td .compare th.explication,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table td .compare td.explication {
  width: 30%; /* Explication prend 3/10 ou 60% */
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table th .compare th.conforme,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table th .compare td.conforme,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table td .compare th.conforme,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table td .compare td.conforme {
  width: 5%; /* Explication prend 3/10 ou 60% */
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table th .compare th.page,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table th .compare td.page,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table td .compare th.page,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table td .compare td.page {
  width: 10%;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table th td.page span,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table td td.page span {
  text-decoration: underline;
  color: blue;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table th .text,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table td .text {
  position: relative;
  display: flex;
  max-width: 300px;
  flex-wrap: wrap;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table th .roro,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table td .roro {
  position: relative;
  margin-top: 60px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table th .roro button,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table td .roro button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  background-color: rgb(146, 194, 146);
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table th .roro button:hover,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table td .roro button:hover {
  background-color: rgb(87, 122, 87);
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table th .roro button:hover svg,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table td .roro button:hover svg {
  color: rgb(173, 221, 181);
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table th .roro svg,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table td .roro svg {
  color: rgb(40, 45, 40);
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table th .test,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table td .test {
  position: relative;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table th textarea,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table td textarea {
  width: 90%;
  font-size: 0.9rem;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table .CheckBox {
  cursor: pointer;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table .editable {
  position: relative;
  padding-right: 24px;
  /* Ajustez en fonction de la taille de votre icône */
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table .edit-icon {
  visibility: hidden;
  position: absolute;
  right: 0;
  bottom: -20px;
  cursor: pointer;
  height: 20px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table .editable:hover .edit-icon {
  visibility: visible;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table .files {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table tbody {
  box-sizing: border-box;
  position: relative;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table tbody tr {
  border-radius: 10px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table tbody tr:hover {
  cursor: pointer;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table tbody table {
  width: 100%;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table .dropdown-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 30px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table .dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 30px;
  padding: 15px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table .nested-table {
  box-sizing: border-box;
  border-radius: 10px;
  width: 100% !important;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table .nested-table.editable th:nth-child(1) {
  width: 3%;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table .nested-table.editable th:nth-child(2) {
  width: 40%;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table .nested-table.editable th:nth-child(3) {
  width: 30%;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table .nested-table.not-editable th:nth-child(1) {
  width: 40%;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table .nested-table.not-editable th:nth-child(2) {
  width: 30%;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table .nested-table tr {
  background-color: inherit;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table .nested-table tr .link {
  text-decoration: underline;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .tableContainer table .nested-table tr:hover {
  background-color: inherit;
  /* ou la couleur d'arrière-plan initiale que vous souhaitez */
  border: inherit;
  /* ou la bordure initiale que vous souhaitez */
  color: inherit;
  /* ou la couleur initiale que vous souhaitez */
  cursor: auto;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .progress-bar {
  width: 100%;
  height: 30px;
  background-color: #e0e0e0;
  border-radius: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .progress-bar .progress-bar-fill {
  height: 100%;
  background-color: #4caf50;
  border-radius: 50px;
  transition: width 0.5s ease;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .function-ACCEO .dce .progress-bar .progress-bar-fill .status-info {
  position: absolute;
  left: 50%;
  top: 25%;
  transform: translateX(-50%);
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters {
  display: flex;
  flex-wrap: wrap;
  gap: 5vh;
  width: 100%;
  flex-direction: column;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters input:hover:disabled, .dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters textarea:hover:disabled {
  cursor: not-allowed;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 10px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container.single {
  flex-grow: 1;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container.double {
  flex-grow: 1;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container.click .param-button.active:hover {
  cursor: pointer;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container.click .param-button .spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 50px;
  height: 50px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container.click .param-button .spinner-circle {
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  animation: spin 1s ease-in-out infinite;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container.click .param-button .spinner-text {
  z-index: 1;
  font-size: 12px;
  font-family: "Inter";
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container.click .param-button svg {
  height: 80%;
  width: auto;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container h3 {
  margin-left: 5px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container h4 {
  margin: 10px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-device-width: 425px) {
  .dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .header {
    gap: 0;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .header {
    gap: 0;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .header {
    gap: 0;
  }
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .header .toggle-all-button {
  flex: 1;
  cursor: pointer;
  white-space: nowrap;
  text-align: right;
}
@media only screen and (max-device-width: 425px) {
  .dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .header .toggle-all-button {
    font-size: 10px;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .header .toggle-all-button {
    font-size: 10px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .header .toggle-all-button {
    font-size: 10px;
  }
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .header .toggle-all-button:hover {
  text-decoration: underline;
  transform: scale(1.01);
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .param-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid white;
  position: relative;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .param-button.valid {
  cursor: auto;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .param-button.valid:hover {
  background-color: initial;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .param-button img {
  height: 5vh;
  width: auto;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .param-button .files {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  gap: 20px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .param-button .files .pdf-function {
  display: flex;
  height: 100%; /* Assure que l'élément prend bien la hauteur du parent */
  overflow: hidden;
  position: relative;
  gap: 5px;
  box-sizing: border-box;
  align-items: stretch; /* Étire les enfants sur la même hauteur */
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .param-button .files .pdf-function .logo {
  display: flex;
  align-items: center;
  height: 100%; /* Assure que le conteneur logo prend toute la hauteur */
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .param-button .files .pdf-function .logo img {
  height: 46px; /* Assure que l'image occupe 100% de la hauteur du conteneur parent */
  width: auto; /* Conserve les proportions de l'image */
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .param-button .files .pdf-function .content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-family: "Inter";
  width: 50%;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .param-button .files .pdf-function .content .title,
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .param-button .files .pdf-function .content .pages {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .param-button .checklist-container {
  overflow: auto;
  max-height: 100%;
  max-width: 100%;
  flex: 1;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .param-button .checklist-container .buttons {
  gap: 5px;
  display: flex;
  flex-wrap: wrap;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .param-button .checklist-container .buttons .btn {
  border-radius: 20px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .param-button .checklist-container::-webkit-scrollbar {
  width: 4px;
  border-radius: 8px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .param-button .checklist-container::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #ad66fe;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .param-button .checked {
  z-index: 1000;
  position: absolute;
  bottom: -21%;
  right: -1rem;
  width: 2rem;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .param-button .checked::before {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .param-button .checked img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .param-button .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(128, 128, 128, 0.5);
  border-radius: 10px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .textbox {
  flex-grow: 1;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .textbox input {
  padding: 10px;
  width: calc(100% - 20px);
  overflow: auto;
  background: none;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 5px;
  vertical-align: middle;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .plurial-text-inputs {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .plurial-text-inputs .plurial-text-inputs-component {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 5px;
  max-width: 100%;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .plurial-text-inputs .plurial-text-inputs-component .inputs {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  width: 100%;
  flex-grow: 1;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .plurial-text-inputs .plurial-text-inputs-component .inputs .input-actions {
  width: 100%;
  max-width: 100%;
  display: flex;
  gap: 5px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .plurial-text-inputs .plurial-text-inputs-component .inputs .input-actions input {
  flex-grow: 1;
  font-size: 14px;
  text-overflow: ellipsis; /* Pour gérer les textes trop longs */
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .plurial-text-inputs .plurial-text-inputs-component .inputs .input-actions .action-buttons {
  display: flex;
  gap: 5px;
  flex-shrink: 0;
  width: auto;
  max-width: 360px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .plurial-text-inputs .plurial-text-inputs-component .inputs .input-actions .action-buttons .action {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  aspect-ratio: 1/1;
  border-radius: 5px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .plurial-text-inputs .plurial-text-inputs-component .inputs .input-actions .action-buttons .action:hover {
  cursor: pointer;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .plurial-text-inputs .plurial-text-inputs-component .inputs .input-actions .action-buttons .switch {
  border-radius: 5px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .plurial-text-inputs .plurial-text-inputs-component .inputs .input-actions .action-buttons .dropdown-select {
  width: 120px; /* Définissez une largeur fixe ou ajustez selon vos besoins */
  height: 38px; /* Correspond à la hauteur des boutons */
  border-radius: 5px; /* Correspond au style des boutons */
  background-color: #d5d5d5; /* Couleur de fond, ajustez si nécessaire */
  font-size: 14px; /* Correspond au texte des boutons */
  padding: 0 10px; /* Ajout d'un peu de rembourrage pour le texte */
  overflow: hidden; /* Assurez-vous que le contenu ne déborde pas */
  text-overflow: ellipsis; /* Pour gérer les textes trop longs */
  white-space: nowrap; /* Empêche le texte de passer à la ligne */
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .plurial-text-inputs .plurial-text-inputs-component .inputs .input-actions .action-buttons .dropdown-select:hover {
  border-color: #999; /* Changer la couleur de la bordure au survol */
  cursor: pointer; /* Changer le curseur au survol */
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .plurial-text-inputs .plurial-text-inputs-component .inputs .input-actions .action-buttons .dropdown-select:focus {
  outline: none; /* Supprime l'outline par défaut */
  border-color: #007bff; /* Changer la bordure en bleu au focus */
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .plurial-text-inputs .plurial-text-inputs-component .inputs .input-actions .action-buttons .dropdown-select:disabled {
  cursor: not-allowed; /* Curseur désactivé */
  background-color: #e0e0e0; /* Couleur de fond pour indiquer qu'il est désactivé */
  border-color: #ccc; /* Bordure pour indiquer qu'il est désactivé */
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .plurial-text-inputs .plurial-text-inputs-component .inputs textarea {
  width: 100%;
  resize: vertical;
  font-size: 14px;
  border: 1px solid black;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .plurial-text-inputs .plurial-text-inputs-component .inputs textarea {
  height: 60px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .plurial-text-inputs .plurial-text-inputs-component .file-keywords {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .plurial-text-inputs .plurial-text-inputs-component .file-keywords h3 {
  margin-right: 5px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .plurial-text-inputs .plurial-text-inputs-component .file-keywords .keyword-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .plurial-text-inputs .plurial-text-inputs-component .file-keywords .keyword-list .keyword-display {
  border: 1px solid black;
  border-radius: 50px;
  padding: 5px;
  font-family: "Inter";
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .plurial-text-inputs .plurial-text-inputs-component .file-keywords .keyword-list input {
  border: none;
  border-radius: 50px;
  padding: 5px;
  font-size: 0.1rem !important;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .plurial-text-inputs .addTextInput {
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 5px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .plurial-text-inputs .addTextInput:hover {
  cursor: pointer;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .checklist-container {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .message .chat-formatted .parameters .param-container .checklist-container .buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  width: calc(100% - 40px);
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #fff;
}
.dashboard-container .cogichat .big-container .chat .chat-messages .down {
  margin-top: 30vh;
}
.dashboard-container .cogichat .big-container .chat .chat-messages::-webkit-scrollbar {
  width: 4px;
  /* Set the width of the scrollbar */
}
.dashboard-container .cogichat .big-container .chat .chat-messages::-webkit-scrollbar-thumb {
  border-radius: 2px;
}
.dashboard-container .cogichat .big-container .chat .new-chat {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
}
@media only screen and (max-device-width: 425px) {
  .dashboard-container .cogichat .big-container .chat .new-chat {
    padding: 0 5%;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .dashboard-container .cogichat .big-container .chat .new-chat {
    padding: 0 5%;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .dashboard-container .cogichat .big-container .chat .new-chat {
    padding: 0 20%;
  }
}
@media only screen and (min-device-width: 0px) and (max-device-width: 0px) {
  .dashboard-container .cogichat .big-container .chat .new-chat {
    padding: 0 20%;
  }
}
@media only screen and (min-device-width: 1241px) and (max-device-width: 5000px) {
  .dashboard-container .cogichat .big-container .chat .new-chat {
    padding: 0 35%;
  }
}
.dashboard-container .cogichat .big-container .chat .new-chat p {
  font-family: "Inter" !important;
}
.dashboard-container .cogichat .big-container .chat .new-chat p span {
  font-family: "Tiempos";
}
@media only screen and (max-device-width: 425px) {
  .dashboard-container .cogichat .big-container .chat .new-chat p {
    text-align: center;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .dashboard-container .cogichat .big-container .chat .new-chat p {
    text-align: center;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .dashboard-container .cogichat .big-container .chat .new-chat p {
    text-align: center;
  }
}
@media only screen and (min-device-width: 0px) and (max-device-width: 0px) {
  .dashboard-container .cogichat .big-container .chat .new-chat p {
    width: 60vw;
  }
}
@media only screen and (min-device-width: 1241px) and (max-device-width: 5000px) {
  .dashboard-container .cogichat .big-container .chat .new-chat p {
    width: 20vw;
  }
}
.dashboard-container .cogichat .big-container .chat .new-chat .project-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  position: relative;
}
@media only screen and (max-device-width: 425px) {
  .dashboard-container .cogichat .big-container .chat .new-chat .project-body {
    width: 90vw;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .dashboard-container .cogichat .big-container .chat .new-chat .project-body {
    width: 90vw;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .dashboard-container .cogichat .big-container .chat .new-chat .project-body {
    width: 50vw;
  }
}
@media only screen and (min-device-width: 0px) and (max-device-width: 0px) {
  .dashboard-container .cogichat .big-container .chat .new-chat .project-body {
    width: 30vw;
  }
}
@media only screen and (min-device-width: 1241px) and (max-device-width: 5000px) {
  .dashboard-container .cogichat .big-container .chat .new-chat .project-body {
    width: 30vw;
  }
}
.dashboard-container .cogichat .big-container .chat .new-chat .project-body button {
  font-size: 1.2em;
  border-radius: 5px;
  width: 100%;
}
.dashboard-container .cogichat .big-container .chat .new-chat .projects-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 3vh;
  width: 100%;
}
.dashboard-container .cogichat .big-container .chat .new-chat .projects-parent .header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.dashboard-container .cogichat .big-container .chat .new-chat .projects-parent .header h3 {
  margin-bottom: 10px;
}
.dashboard-container .cogichat .big-container .chat .new-chat .projects-parent .header svg {
  cursor: pointer;
}
.dashboard-container .cogichat .big-container .chat .new-chat .projects-parent .projects-container {
  height: 15vh;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 15px;
  border-radius: 5px;
  width: calc(100% - 30px);
}
.dashboard-container .cogichat .big-container .chat .new-chat .projects-parent .projects-container .btn {
  margin: 0;
  height: 1.5em;
  flex: 1 0 calc(25% - 10px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.dashboard-container .cogichat .big-container .chat .new-chat .projects-parent .projects-container::-webkit-scrollbar {
  width: 4px;
}
.dashboard-container .cogichat .big-container .chat .new-chat .projects-parent .projects-container::-webkit-scrollbar-thumb {
  border-radius: 2px;
}
.dashboard-container .cogichat .big-container .chat .new-chat .templates {
  position: relative;
  margin-top: 5vh;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 100%;
}
.dashboard-container .cogichat .big-container .chat .new-chat .templates::before, .dashboard-container .cogichat .big-container .chat .new-chat .templates::after {
  z-index: 20;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 70px; /* La largeur du dégradé */
  pointer-events: none; /* Pour ne pas interférer avec les clics */
}
.dashboard-container .cogichat .big-container .chat .new-chat .templates::before {
  left: 0;
}
.dashboard-container .cogichat .big-container .chat .new-chat .templates::after {
  right: 0;
}
.dashboard-container .cogichat .big-container .chat .new-chat .templates .wrapper {
  display: flex;
  gap: 20px;
  width: max-content;
}
.dashboard-container .cogichat .big-container .chat .new-chat .templates .wrapper.left {
  animation: scroll-left 120s linear infinite;
}
.dashboard-container .cogichat .big-container .chat .new-chat .templates .wrapper.right {
  animation: scroll-right 120s linear infinite;
}
.dashboard-container .cogichat .big-container .chat .new-chat .templates .btn {
  cursor: pointer;
  padding: 10px 10px;
  text-align: center;
  border-radius: 30px;
  font-family: "Inter";
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
@keyframes scroll-right {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}
.dashboard-container .cogichat .big-container .chat .chat-input {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15vh 0 3vh;
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 100;
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container {
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 10px;
  margin-bottom: 5vh;
  position: relative;
}
@media only screen and (max-device-width: 425px) {
  .dashboard-container .cogichat .big-container .chat .chat-input .input-container {
    width: 90vw;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .dashboard-container .cogichat .big-container .chat .chat-input .input-container {
    width: 90vw;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .dashboard-container .cogichat .big-container .chat .chat-input .input-container {
    width: 80vw;
  }
}
@media only screen and (min-device-width: 0px) and (max-device-width: 0px) {
  .dashboard-container .cogichat .big-container .chat .chat-input .input-container {
    width: 50vw;
  }
}
@media only screen and (min-device-width: 1241px) and (max-device-width: 5000px) {
  .dashboard-container .cogichat .big-container .chat .chat-input .input-container {
    width: 50vw;
  }
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .file-upload {
  display: none;
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .file-upload .file-upload-label {
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .file-upload:hover {
  background-color: #45a049;
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .files {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .files .addFile {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 40px;
  overflow: hidden;
  border-radius: 5px;
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .files .addFile:hover {
  cursor: pointer;
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .files .file-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  border-radius: 5px;
  padding: 10px;
  width: 15%;
  position: relative;
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .files .file-container .left {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .files .file-container .left img {
  height: 30px;
  width: auto;
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .files .file-container .right {
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: hidden;
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .files .file-container .right .file-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .files .file-container .right p {
  font-size: 0.6em;
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .files .file-container .cross {
  position: absolute;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  top: -6px;
  right: -6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .files .file-container .cross:hover {
  cursor: pointer;
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .new {
  position: absolute;
  right: 0px;
  display: flex;
  justify-content: flex-end;
}
@media only screen and (max-device-width: 425px) {
  .dashboard-container .cogichat .big-container .chat .chat-input .input-container .new {
    top: -10px;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .dashboard-container .cogichat .big-container .chat .chat-input .input-container .new {
    top: -10px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .dashboard-container .cogichat .big-container .chat .chat-input .input-container .new {
    top: -10px;
  }
}
@media only screen and (min-device-width: 0px) and (max-device-width: 0px) {
  .dashboard-container .cogichat .big-container .chat .chat-input .input-container .new {
    top: -10px;
  }
}
@media only screen and (min-device-width: 1241px) and (max-device-width: 5000px) {
  .dashboard-container .cogichat .big-container .chat .chat-input .input-container .new {
    top: -10px;
  }
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .new .vertical {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
@media only screen and (max-device-width: 425px) {
  .dashboard-container .cogichat .big-container .chat .chat-input .input-container .new .vertical {
    flex-direction: column;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .dashboard-container .cogichat .big-container .chat .chat-input .input-container .new .vertical {
    flex-direction: column;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .dashboard-container .cogichat .big-container .chat .chat-input .input-container .new .vertical {
    flex-direction: column;
  }
}
@media only screen and (min-device-width: 0px) and (max-device-width: 0px) {
  .dashboard-container .cogichat .big-container .chat .chat-input .input-container .new .vertical {
    flex-direction: row;
  }
}
@media only screen and (min-device-width: 1241px) and (max-device-width: 5000px) {
  .dashboard-container .cogichat .big-container .chat .chat-input .input-container .new .vertical {
    flex-direction: row;
  }
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .new .vertical .test {
  bottom: 0;
  position: absolute;
  display: flex;
  padding: 2px;
  border-radius: 30px;
  gap: 5px;
  z-index: 25;
}
@media only screen and (max-device-width: 425px) {
  .dashboard-container .cogichat .big-container .chat .chat-input .input-container .new .vertical .test {
    flex-direction: column;
    bottom: 55px;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .dashboard-container .cogichat .big-container .chat .chat-input .input-container .new .vertical .test {
    flex-direction: column;
    bottom: 55px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .dashboard-container .cogichat .big-container .chat .chat-input .input-container .new .vertical .test {
    flex-direction: column;
    bottom: 55px;
  }
}
@media only screen and (min-device-width: 0px) and (max-device-width: 0px) {
  .dashboard-container .cogichat .big-container .chat .chat-input .input-container .new .vertical .test {
    flex-direction: row;
    right: 55px;
  }
}
@media only screen and (min-device-width: 1241px) and (max-device-width: 5000px) {
  .dashboard-container .cogichat .big-container .chat .chat-input .input-container .new .vertical .test {
    flex-direction: row;
    right: 55px;
  }
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .new .vertical .test .mobile-btn {
  height: 26px;
  width: 26px;
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .new .vertical .mobile-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .options {
  display: flex;
  flex: 1;
  gap: 10px;
}
@media only screen and (max-device-width: 425px) {
  .dashboard-container .cogichat .big-container .chat .chat-input .input-container .options {
    width: 90vw;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .dashboard-container .cogichat .big-container .chat .chat-input .input-container .options {
    width: 90vw;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .dashboard-container .cogichat .big-container .chat .chat-input .input-container .options {
    width: 80vw;
  }
}
@media only screen and (min-device-width: 0px) and (max-device-width: 0px) {
  .dashboard-container .cogichat .big-container .chat .chat-input .input-container .options {
    width: 50vw;
  }
}
@media only screen and (min-device-width: 1241px) and (max-device-width: 5000px) {
  .dashboard-container .cogichat .big-container .chat .chat-input .input-container .options {
    width: 50vw;
  }
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .options select {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  font-size: 1em;
  padding: 10px;
  width: 100%;
  text-overflow: ellipsis;
  overflow-x: hidden;
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .options select option {
  font-size: 1em;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s ease-in-out;
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .options button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .options select,
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .options .temperature {
  flex: 1;
}
@media only screen and (max-device-width: 425px) {
  .dashboard-container .cogichat .big-container .chat .chat-input .input-container .options select,
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .options .temperature {
    padding: 10px;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .dashboard-container .cogichat .big-container .chat .chat-input .input-container .options select,
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .options .temperature {
    padding: 10px;
  }
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .options .temperature {
  position: relative;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-device-width: 425px) {
  .dashboard-container .cogichat .big-container .chat .chat-input .input-container .options .temperature {
    display: none;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .dashboard-container .cogichat .big-container .chat .chat-input .input-container .options .temperature {
    display: none;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .dashboard-container .cogichat .big-container .chat .chat-input .input-container .options .temperature {
    display: none;
  }
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .options .temperature .description {
  position: absolute;
  border-radius: 10px;
  left: -20px;
  padding: 15px;
  min-height: 100px;
  width: 125%;
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .options .temperature .description.hidden {
  display: none;
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .options .temperature .description.visible {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .options .temperature .description::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 20px;
  border-width: 0 5px 10px 5px;
  border-style: solid;
  transform: rotate(180deg);
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .options .temperature .title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .options .temperature .title svg {
  cursor: pointer;
  height: 15px;
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .options .temperature p {
  font-size: 1em;
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .options .temperature .output {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .options .temperature .output input {
  flex-grow: 1;
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .input {
  flex: 1;
  display: flex;
  flex-direction: row;
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .input .textarea-container {
  flex: 5;
  resize: none !important;
  padding: 10px;
  line-height: 30px;
  border-radius: 5px;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  overflow-y: auto;
  position: relative;
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .input .textarea-container textarea {
  padding: 0px !important;
  font-size: 0.9em;
  width: 100%;
  height: auto;
  min-height: 30px;
  resize: none;
  overflow-y: scroll;
  transition: height 0.2s, padding 0.2s;
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .input .textarea-container textarea::-webkit-scrollbar {
  width: 4px;
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .input .textarea-container textarea::-webkit-scrollbar-thumb {
  border-radius: 2px;
}
.dashboard-container .cogichat .big-container .chat .chat-input .input-container .input .textarea-container svg {
  position: absolute;
  fill: none;
  stroke-width: 2;
  bottom: 19px;
  right: 30px;
  cursor: pointer;
}
.dashboard-container .cogichat .big-container .chat .chat-error {
  width: 70%;
  font-family: "Inter";
  border-radius: 10px;
  background-color: #ff8888;
  color: #560707;
  border: 1px solid #560707;
  padding: 1vh 2vw;
  bottom: -10px;
  opacity: 0;
  transition: bottom 0.3s, opacity 0.3s;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.dashboard-container .cogichat .big-container .chat .chat-error.active {
  bottom: 0;
  opacity: 1;
}
.dashboard-container .cogichat .big-container .chat .chat-error .cross {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-container .cogichat .big-container .settings {
  overflow-x: hidden;
  position: relative;
  flex-grow: 1;
}
.dashboard-container .cogichat .big-container .settings .settings-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  gap: 10vh;
  overflow-x: hidden;
}
@media only screen and (max-device-width: 425px) {
  .dashboard-container .cogichat .big-container .settings .settings-container {
    padding: 10vh 5vw;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .dashboard-container .cogichat .big-container .settings .settings-container {
    padding: 10vh 5vw;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .dashboard-container .cogichat .big-container .settings .settings-container {
    padding: 10vh 15%;
  }
}
@media only screen and (min-device-width: 0px) and (max-device-width: 0px) {
  .dashboard-container .cogichat .big-container .settings .settings-container {
    padding: 10vh 15%;
  }
}
@media only screen and (min-device-width: 1241px) and (max-device-width: 5000px) {
  .dashboard-container .cogichat .big-container .settings .settings-container {
    padding: 10vh 15%;
  }
}
.dashboard-container .cogichat .big-container .settings .settings-container .cross {
  z-index: 20;
  cursor: pointer;
  position: fixed;
  top: 20px;
  right: 20px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .prompt {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
}
.dashboard-container .cogichat .big-container .settings .settings-container .prompt .paragraphe, .dashboard-container .cogichat .big-container .settings .settings-container .prompt p {
  font-size: 1em;
  font-family: "Inter";
}
.dashboard-container .cogichat .big-container .settings .settings-container .prompt .paragraphe ol li, .dashboard-container .cogichat .big-container .settings .settings-container .prompt p ol li {
  margin-top: 10px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .prompt .projects {
  display: flex;
  gap: 20px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .prompt .projects .btn, .dashboard-container .cogichat .big-container .settings .settings-container .prompt .projects .form {
  flex: 1;
}
.dashboard-container .cogichat .big-container .settings .settings-container .prompt .newProject {
  width: 40%;
  display: flex;
  gap: 20px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .prompt .newProject input {
  flex: 5;
}
.dashboard-container .cogichat .big-container .settings .settings-container .prompt .textarea-container {
  border-radius: 10px;
  padding: 20px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .prompt .textarea-container textarea {
  max-width: 100%;
  min-width: 100%;
  min-height: 50vh;
  padding: 0px !important;
  font-size: 0.9em;
  line-height: 30px;
  border-radius: 10px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .prompt .textarea-container textarea::-webkit-scrollbar {
  width: 4px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .prompt .textarea-container textarea::-webkit-scrollbar-thumb {
  border-radius: 2px; /* Set the border radius for a rounded scrollbar line */
}
.dashboard-container .cogichat .big-container .settings .settings-container .prompt button {
  width: 100%;
  text-align: center;
  padding: 2vh 0;
  font-size: 1em !important;
}
.dashboard-container .cogichat .big-container .settings .settings-container .agent {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .agent input {
  width: calc(100% - 20px);
  padding: 10px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .agent button {
  padding: 10px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .agent button, .dashboard-container .cogichat .big-container .settings .settings-container .agent ul {
  width: 100%;
}
.dashboard-container .cogichat .big-container .settings .settings-container .agent ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .agent li {
  max-width: 100%;
  position: relative;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  background-color: white;
  display: flex;
  padding: 10px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.dashboard-container .cogichat .big-container .settings .settings-container .agent li .file {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-left: 50px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .agent li .file .icon {
  position: absolute;
  top: 10px; /* Adjusting for li's padding */
  left: 10px; /* Adjusting for li's padding */
  height: calc(100% - 20px); /* Adjusting for li's padding */
  display: flex;
  align-items: center; /* This will center the image vertically */
}
.dashboard-container .cogichat .big-container .settings .settings-container .agent li .file .icon img {
  height: 100%;
  width: auto;
}
.dashboard-container .cogichat .big-container .settings .settings-container .prompt {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
}
.dashboard-container .cogichat .big-container .settings .settings-container .prompt .paragraphe, .dashboard-container .cogichat .big-container .settings .settings-container .prompt p {
  font-size: 1em;
  font-family: "Inter";
}
.dashboard-container .cogichat .big-container .settings .settings-container .prompt .paragraphe ol li, .dashboard-container .cogichat .big-container .settings .settings-container .prompt p ol li {
  margin-top: 10px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .prompt .textarea-container {
  border-radius: 10px;
  padding: 20px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .prompt .textarea-container textarea {
  max-width: 100%;
  min-width: 100%;
  min-height: 50vh;
  padding: 0px !important;
  font-size: 0.9em;
  line-height: 30px;
  border-radius: 10px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .prompt .textarea-container textarea::-webkit-scrollbar {
  width: 4px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .prompt .textarea-container textarea::-webkit-scrollbar-thumb {
  border-radius: 2px; /* Set the border radius for a rounded scrollbar line */
}
.dashboard-container .cogichat .big-container .settings .settings-container .prompt button {
  width: 100%;
  text-align: center;
  padding: 2vh 0;
  font-size: 1em !important;
}
.dashboard-container .cogichat .big-container .settings .settings-container .prompt button:disabled {
  background-color: #ccc; /* Set the background color for disabled buttons */
  color: #999; /* Set the text color for disabled buttons */
  cursor: not-allowed; /* Set the cursor for disabled buttons */
}
.dashboard-container .cogichat .big-container .settings .settings-container .content {
  display: flex;
  flex-direction: column;
  gap: 5vh;
  box-sizing: border-box;
  position: relative;
}
.dashboard-container .cogichat .big-container .settings .settings-container .content .credits {
  position: absolute;
  top: -50px;
  right: 0;
  width: 30%;
}
.dashboard-container .cogichat .big-container .settings .settings-container .content .credits .titre {
  display: flex;
  align-items: baseline;
  gap: 10px;
  justify-content: space-between;
}
.dashboard-container .cogichat .big-container .settings .settings-container .content .credits .titre p {
  font-size: 0.8em;
}
.dashboard-container .cogichat .big-container .settings .settings-container .content .credits .progress-bar {
  width: 100%;
  height: 10px;
  border-radius: 10px;
  overflow: hidden;
}
.dashboard-container .cogichat .big-container .settings .settings-container .content .credits .progress-bar .active {
  background-color: #c898ff;
  height: 100%;
  border-radius: 10px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .content .credits .progress-bar .active.warning {
  background-color: orange;
}
.dashboard-container .cogichat .big-container .settings .settings-container .content .credits .progress-bar .active.danger {
  background-color: red;
}
.dashboard-container .cogichat .big-container .settings .settings-container .content .charts {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .content .charts .month {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .content .charts .month .label {
  display: flex;
  align-items: center;
  font-family: "Inter";
}
.dashboard-container .cogichat .big-container .settings .settings-container .content .charts .month .left, .dashboard-container .cogichat .big-container .settings .settings-container .content .charts .month .right {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.dashboard-container .cogichat .big-container .settings .settings-container .content .charts .chart-placeholder {
  height: 100%;
  width: 100%;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-container .cogichat .big-container .settings .settings-container .content .charts .chart-placeholder .spinner {
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #7601fd; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.dashboard-container .cogichat .big-container .settings .settings-container .content .charts .global-line {
  font-family: "Inter";
  font-size: 0.7em;
}
.dashboard-container .cogichat .big-container .settings .settings-container .content .charts .global-line path {
  stroke-width: 4;
}
.dashboard-container .cogichat .big-container .settings .settings-container .content .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard-container .cogichat .big-container .settings .settings-container .content .title .titre {
  display: flex;
  align-items: baseline;
  gap: 10px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .content .title .titre p {
  font-size: 0.8em;
}
.dashboard-container .cogichat .big-container .settings .settings-container .content .teams {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
}
.dashboard-container .cogichat .big-container .settings .settings-container .content .teams .btn {
  font-family: "Tiempos";
  border-radius: 20px;
  padding: 20px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .content .users {
  width: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  overflow: auto;
  position: relative;
}
.dashboard-container .cogichat .big-container .settings .settings-container .content .users table {
  min-width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  font-family: "Inter";
  box-sizing: border-box;
}
.dashboard-container .cogichat .big-container .settings .settings-container .content .users table th, .dashboard-container .cogichat .big-container .settings .settings-container .content .users table td {
  padding: 15px;
  text-align: left;
  box-sizing: border-box;
}
.dashboard-container .cogichat .big-container .settings .settings-container .content .users table th .text, .dashboard-container .cogichat .big-container .settings .settings-container .content .users table td .text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dashboard-container .cogichat .big-container .settings .settings-container .content .users table th .status, .dashboard-container .cogichat .big-container .settings .settings-container .content .users table td .status {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left-baseline;
}
.dashboard-container .cogichat .big-container .settings .settings-container .content .users table th .status .round, .dashboard-container .cogichat .big-container .settings .settings-container .content .users table td .status .round {
  text-align: center;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
.dashboard-container .cogichat .big-container .settings .settings-container .content .users table th .status .round.true, .dashboard-container .cogichat .big-container .settings .settings-container .content .users table td .status .round.true {
  background-color: green;
}
.dashboard-container .cogichat .big-container .settings .settings-container .content .users table th .status .round.false, .dashboard-container .cogichat .big-container .settings .settings-container .content .users table td .status .round.false {
  background-color: red;
}
.dashboard-container .cogichat .big-container .settings .settings-container .content .users table tbody tr:hover {
  cursor: pointer;
}
.dashboard-container .cogichat .big-container .settings .settings-container .content .users table tbody tr.disabledClass:hover {
  cursor: not-allowed;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .back {
  cursor: pointer;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule button {
  padding: 10px 15px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content {
  display: flex;
  flex-direction: column;
  gap: 5vh;
  width: 100%;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .buttons .btn {
  width: 100%;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .informations,
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .password,
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .delete {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .informations h2,
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .password h2,
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .delete h2 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .informations form label,
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .password form label,
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .delete form label {
  width: 100%;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .informations form input,
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .informations form select,
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .informations form button,
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .password form input,
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .password form select,
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .password form button,
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .delete form input,
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .delete form select,
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .delete form button {
  box-sizing: border-box;
  width: 100%;
  padding: 15px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .delete button {
  width: 30%;
  font-size: 1em;
}
@media only screen and (max-device-width: 425px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .delete button {
    width: 100%;
    padding: 15px;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .delete button {
    width: 100%;
    padding: 15px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .delete button {
    width: 100%;
    padding: 15px;
  }
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .doubleButton {
  width: calc(100% - 20px);
  display: flex;
  background-color: #f0f0f0;
  border-radius: 10px;
  flex-direction: row;
  gap: 20px;
  padding: 10px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .doubleButton button.active {
  background-color: #6ec6dc;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .doubleButton button.inactive {
  background-color: #f0f0f0;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .suggestions {
  width: 100%;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .suggestions .suggestions-container {
  display: inline-flex;
  animation: scroll-left 120s linear infinite;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .suggestions .suggestions-container .suggestion {
  padding: 5px 10px;
  margin-right: 10px;
  border: 1px solid #000;
  border-radius: 50px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .suggestions .suggestions-container .suggestion:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}
@keyframes scroll-left {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .back {
  cursor: pointer;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule button {
  padding: 10px 15px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content {
  display: flex;
  flex-direction: column;
  gap: 5vh;
  width: 100%;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .buttons .btn {
  width: 100%;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .informations,
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .password,
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .delete {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .informations h2,
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .password h2,
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .delete h2 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .informations form label,
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .password form label,
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .delete form label {
  width: 100%;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .informations form input, .dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .informations form select, .dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .informations form button,
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .password form input,
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .password form select,
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .password form button,
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .delete form input,
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .delete form select,
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .delete form button {
  box-sizing: border-box;
  width: 100%;
  padding: 15px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .delete button {
  width: 30%;
  font-size: 1em;
}
@media only screen and (max-device-width: 425px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .delete button {
    width: 100%;
    padding: 15px;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .delete button {
    width: 100%;
    padding: 15px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .submodule .content .delete button {
    width: 100%;
    padding: 15px;
  }
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule {
  display: flex;
  flex-direction: column;
  gap: 5vh;
  width: 100%;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .back {
  cursor: pointer;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule form input, .dashboard-container .cogichat .big-container .settings .settings-container .submodule form button, .dashboard-container .cogichat .big-container .settings .settings-container .submodule form select {
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .back {
  cursor: pointer;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .submodule-content {
  display: flex;
  flex-direction: column;
  gap: 5vh;
  box-sizing: border-box;
  position: relative;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .submodule-content .delete {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .submodule-content .delete button {
  width: 20%;
  padding: 1vh;
}
@media only screen and (max-device-width: 425px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .submodule .submodule-content .delete button {
    width: 100%;
    padding: 15px;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .submodule .submodule-content .delete button {
    width: 100%;
    padding: 15px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .submodule .submodule-content .delete button {
    width: 100%;
    padding: 15px;
  }
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .submodule-content .delete button:disabled {
  cursor: not-allowed;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .submodule-content .delete ul {
  margin-top: 10px;
  font-family: "Inter";
  font-size: 0.8em;
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .submodule-content .delete ul li {
  text-indent: 20px;
  text-decoration: none;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .submodule-content .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .submodule-content .title .titre {
  display: flex;
  align-items: baseline;
  gap: 10px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .submodule-content .title .titre p {
  font-size: 0.8em;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .submodule-content .users {
  width: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  overflow: auto;
  position: relative;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .submodule-content .users table {
  min-width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  font-family: "Inter";
  box-sizing: border-box;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .submodule-content .users table th, .dashboard-container .cogichat .big-container .settings .settings-container .submodule .submodule-content .users table td {
  padding: 15px;
  text-align: left;
  box-sizing: border-box;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .submodule-content .users table th .text, .dashboard-container .cogichat .big-container .settings .settings-container .submodule .submodule-content .users table td .text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .submodule-content .users table th .status, .dashboard-container .cogichat .big-container .settings .settings-container .submodule .submodule-content .users table td .status {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left-baseline;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .submodule-content .users table th .status .round, .dashboard-container .cogichat .big-container .settings .settings-container .submodule .submodule-content .users table td .status .round {
  text-align: center;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .submodule-content .users table th .status .round.true, .dashboard-container .cogichat .big-container .settings .settings-container .submodule .submodule-content .users table td .status .round.true {
  background-color: green;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .submodule-content .users table th .status .round.false, .dashboard-container .cogichat .big-container .settings .settings-container .submodule .submodule-content .users table td .status .round.false {
  background-color: red;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .submodule-content .users table tbody tr:hover {
  cursor: pointer;
}
.dashboard-container .cogichat .big-container .settings .settings-container .submodule .submodule-content .users table tbody tr.disabledClass:hover {
  cursor: not-allowed;
}
.dashboard-container .cogichat .big-container .settings .settings-container .input-provider {
  margin-top: 20px;
  width: 100%;
}
.dashboard-container .cogichat .big-container .settings .settings-container .user {
  display: flex;
  position: relative;
}
@media only screen and (max-device-width: 425px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .user {
    flex-direction: column;
    gap: 60px;
    align-items: flex-start;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .user {
    flex-direction: column;
    gap: 60px;
    align-items: flex-start;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .user {
    gap: 50px;
  }
}
@media only screen and (min-device-width: 0px) and (max-device-width: 0px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .user {
    gap: 50px;
  }
}
@media only screen and (min-device-width: 1241px) and (max-device-width: 5000px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .user {
    gap: 50px;
  }
}
.dashboard-container .cogichat .big-container .settings .settings-container .user .choose-avatar {
  z-index: 60;
  position: absolute;
  width: 80%;
  height: 50vh;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 20px;
  padding: 60px 20px 20px;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dashboard-container .cogichat .big-container .settings .settings-container .user .choose-avatar .cross {
  position: absolute;
}
.dashboard-container .cogichat .big-container .settings .settings-container .user .choose-avatar .avatar-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;
}
.dashboard-container .cogichat .big-container .settings .settings-container .user .choose-avatar .avatar-container::-webkit-scrollbar {
  width: 4px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .user .choose-avatar .avatar-container::-webkit-scrollbar-thumb {
  border-radius: 2px; /* Set the border radius for a rounded scrollbar line */
}
.dashboard-container .cogichat .big-container .settings .settings-container .user .choose-avatar .avatar-container ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.dashboard-container .cogichat .big-container .settings .settings-container .user .choose-avatar .avatar-container ul li {
  cursor: pointer;
  flex-basis: calc(20% - 10px);
  margin: 5px;
  display: inline-flex;
  justify-content: space-around;
  align-items: space-around;
  border-radius: 10px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .user .choose-avatar .avatar-container ul li .avatar-image {
  max-width: 80%;
  max-height: 80%;
  height: auto;
}
.dashboard-container .cogichat .big-container .settings .settings-container .user .choose-avatar .validate {
  padding: 2vh 2vw;
}
.dashboard-container .cogichat .big-container .settings .settings-container .user .choose-avatar .validate button {
  text-align: center;
  padding: 2vh 2vw;
  font-size: 1em !important;
}
.dashboard-container .cogichat .big-container .settings .settings-container .user .choose-avatar .validate button:disabled {
  background-color: #ccc; /* Set the background color for disabled buttons */
  color: #999; /* Set the text color for disabled buttons */
  cursor: not-allowed; /* Set the cursor for disabled buttons */
}
.dashboard-container .cogichat .big-container .settings .settings-container .user .btn {
  text-align: center;
  cursor: pointer;
  padding: 10px 10px;
  text-align: center;
  border-radius: 5px;
  font-family: "Inter";
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dashboard-container .cogichat .big-container .settings .settings-container .user .avatar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
@media only screen and (max-device-width: 425px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .user .avatar {
    align-items: flex-start;
    flex-direction: column;
    width: 50%;
    gap: 5px;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .user .avatar {
    align-items: flex-start;
    flex-direction: column;
    width: 50%;
    gap: 5px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .user .avatar {
    width: 15%;
    flex-direction: column;
  }
}
@media only screen and (min-device-width: 0px) and (max-device-width: 0px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .user .avatar {
    width: 15%;
    flex-direction: column;
  }
}
@media only screen and (min-device-width: 1241px) and (max-device-width: 5000px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .user .avatar {
    width: 15%;
    flex-direction: column;
  }
}
.dashboard-container .cogichat .big-container .settings .settings-container .user .avatar .img {
  height: auto;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.dashboard-container .cogichat .big-container .settings .settings-container .user .avatar .img img {
  width: 100%;
  height: auto;
}
.dashboard-container .cogichat .big-container .settings .settings-container .user .avatar .btn {
  width: 90%;
}
.dashboard-container .cogichat .big-container .settings .settings-container .user form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .user form label {
  font-family: "Inter";
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .user form input {
  padding: 15px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .user form .btn {
  width: 40%;
}
.dashboard-container .cogichat .big-container .settings .settings-container .user form button {
  text-align: center;
  padding: 1vh 0;
  font-size: 1em !important;
}
.dashboard-container .cogichat .big-container .settings .settings-container .user form button:disabled {
  background-color: #ccc;
  /* Set the background color for disabled buttons */
  color: #999;
  /* Set the text color for disabled buttons */
  cursor: not-allowed;
  /* Set the cursor for disabled buttons */
}
@media only screen and (max-device-width: 425px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .user form button {
    width: 100%;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .user form button {
    width: 100%;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .user form button {
    width: 30%;
  }
}
@media only screen and (min-device-width: 0px) and (max-device-width: 0px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .user form button {
    width: 30%;
  }
}
@media only screen and (min-device-width: 1241px) and (max-device-width: 5000px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .user form button {
    width: 30%;
  }
}
.dashboard-container .cogichat .big-container .settings .settings-container .user .infos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 50px;
  width: 100%;
}
.dashboard-container .cogichat .big-container .settings .settings-container .user .infos .informations {
  display: flex;
  flex-direction: column;
}
@media only screen and (max-device-width: 425px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .user .infos .informations {
    width: 100%;
    gap: 5px;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .user .infos .informations {
    width: 100%;
    gap: 5px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .user .infos .informations {
    width: 80%;
    gap: 50px;
  }
}
@media only screen and (min-device-width: 0px) and (max-device-width: 0px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .user .infos .informations {
    width: 80%;
    gap: 50px;
  }
}
@media only screen and (min-device-width: 1241px) and (max-device-width: 5000px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .user .infos .informations {
    width: 80%;
    gap: 50px;
  }
}
.dashboard-container .cogichat .big-container .settings .settings-container .user .infos .password {
  display: flex;
  flex-direction: column;
}
@media only screen and (max-device-width: 425px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .user .infos .password {
    width: 100%;
    gap: 5px;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .user .infos .password {
    width: 100%;
    gap: 5px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .user .infos .password {
    width: 80%;
    gap: 50px;
  }
}
@media only screen and (min-device-width: 0px) and (max-device-width: 0px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .user .infos .password {
    width: 80%;
    gap: 50px;
  }
}
@media only screen and (min-device-width: 1241px) and (max-device-width: 5000px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .user .infos .password {
    width: 80%;
    gap: 50px;
  }
}
.dashboard-container .cogichat .big-container .settings .settings-container .user .infos .tone {
  display: flex;
  flex-direction: column;
}
@media only screen and (max-device-width: 425px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .user .infos .tone {
    width: 100%;
    gap: 20px;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 767px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .user .infos .tone {
    width: 100%;
    gap: 20px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1240px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .user .infos .tone {
    width: 80%;
    gap: 10px;
  }
}
@media only screen and (min-device-width: 0px) and (max-device-width: 0px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .user .infos .tone {
    width: 80%;
    gap: 15px;
  }
}
@media only screen and (min-device-width: 1241px) and (max-device-width: 5000px) {
  .dashboard-container .cogichat .big-container .settings .settings-container .user .infos .tone {
    width: 80%;
    gap: 15px;
  }
}
.dashboard-container .cogichat .big-container .settings .settings-container .user .infos .tone h2 {
  margin-bottom: 30px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .user .infos .tone .button {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .user .infos .tone .button .btn {
  border-radius: 25px;
}
.dashboard-container .cogichat .big-container .settings .settings-container .user .infos .tone .button .btn.disabled {
  cursor: not-allowed;
}
.dashboard-container .cogichat .big-container .settings .container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.dashboard-container .cogichat .big-container .settings .container .top-bar {
  flex: 1;
  background-color: rgb(229, 229, 229);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  padding-left: 80px;
}
.dashboard-container .cogichat .big-container .settings .container .editor {
  flex: 14;
  height: 100%;
}
.dashboard-container .cogichat .big-container .settings .container .console {
  flex: 4;
  background-color: rgb(229, 229, 229);
  overflow-y: scroll;
  padding-bottom: 10vh;
}
.dashboard-container .cogichat .big-container .settings .container .console .logline {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 10px;
  align-items: center;
  justify-content: left;
  border-bottom: 1px solid black;
}
.dashboard-container .cogichat .big-container .settings::-webkit-scrollbar {
  width: 4px; /* Set the width of the scrollbar */
}
.dashboard-container .cogichat .big-container .settings::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #ccc;
}
.dashboard-container .cogichat .big-container .pdf-viewer-container {
  position: relative;
  flex: 2;
}
.dashboard-container .cogichat .big-container .pdf-viewer-container .pdf-viewer-wrapper {
  position: relative;
  all: initial;
  font-family: Arial, sans-serif;
  /* Vous pouvez changer la police ici */
}
.dashboard-container .cogichat .big-container .pdf-viewer-container .pdf-viewer-wrapper .toolbar-light-mode .rpv-toolbar {
  background-color: #ffffff;
}
.dashboard-container .cogichat .big-container .pdf-viewer-container .pdf-viewer-wrapper .toolbar-dark-mode {
  background-color: #333;
  color: #fff;
}
.dashboard-container .cogichat .big-container .pdf-viewer-container .pdf-viewer-wrapper .toolbar-dark-mode input {
  background-color: #2b2a2a;
  color: #fff;
}
.dashboard-container .cogichat .big-container .pdf-viewer-container .pdf-viewer-wrapper .toolbar-dark-mode span {
  color: #d1d1d1;
}
.dashboard-container .cogichat .big-container .pdf-viewer-container .pdf-viewer-wrapper .toolbar-dark-mode .rpv-toolbar {
  background-color: #333;
}
.dashboard-container .cogichat .big-container .pdf-viewer-container .pdf-viewer-wrapper .toolbar-dark-mode .rpv-toolbar-button {
  color: #fff;
}
.dashboard-container .cogichat .big-container .pdf-viewer-container .pdf-viewer-wrapper .toolbar-dark-mode .rpv-toolbar-button:hover {
  background-color: #555;
}
.dashboard-container .cogichat .big-container .pdf-viewer-container .pdf-viewer-wrapper .toolbar-dark-mode .rpv-toolbar-dropdown {
  background-color: #333;
  color: #fff;
}
.dashboard-container .cogichat .big-container .pdf-viewer-container .pdf-viewer-wrapper .toolbar-dark-mode .rpv-toolbar-dropdown .rpv-toolbar-dropdown-item:hover {
  background-color: #555;
}
.dashboard-container .cogichat .big-container .pdf-viewer-container .pdf-viewer-wrapper .toolbar-dark-mode .rpv-toolbar-separator {
  border-color: #555;
}
.dashboard-container .cogichat .big-container .pdf-viewer-container .pdf-viewer-wrapper .toolbar-dark-mode .rpv-toolbar-item .rpv-toolbar-field {
  background-color: #333;
  color: #fff;
}
.dashboard-container .cogichat .big-container .pdf-viewer-container .pdf-viewer-wrapper button {
  background-color: transparent;
  border: none;
  border-radius: 0.25rem;
  color: #11181c;
  cursor: pointer;
  height: 2rem;
  padding: 0 0.5rem;
}
.dashboard-container .cogichat .big-container .pdf-viewer-container .pdf-viewer-wrapper input {
  background-color: var(--rpv-core__textbox-background-color);
  border: 1px solid var(--rpv-core__textbox-border-color);
  box-sizing: border-box;
  border-radius: 0.25rem;
  color: var(--rpv-core__textbox-color);
  padding: 0 0.5rem;
  height: 2rem;
  width: 100%;
  font-size: 1rem;
}

