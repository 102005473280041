.charts {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .month{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;

        .label {
            display: flex;
            align-items: center;
            font-family: $font-paragraphe;
        }

        .left, .right {
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    }

    .chart-placeholder {
        height: 100%;
        width: 100%;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        .spinner {
            border: 2px solid #f3f3f3; /* Light grey */
            border-top: 2px solid $primary-color; /* Blue */
            border-radius: 50%;
            width: 20px;
            height: 20px;
            animation: spin 2s linear infinite;
          }
          
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }          
      }
      

    .global-line {
        font-family: $font-paragraphe;
        font-size: 0.7em;
        path {
            stroke-width: 4;
        }
    }          
}