.chat {
    display: flex;
    flex: 3;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    border-radius: 5px;
    position: relative;
    max-width: 100%;

    .chat-messages {
    
        display: flex;
        overflow-y: scroll;
        flex-direction: column;
        height: 100%;
        max-width: 100%;
        position: relative;

        .message {
            display: flex;
            flex-direction: row;
            position: relative;
            max-width: 100%;

            @include xs {
                padding: 50px 5vw;
            }

            @include sm {
                padding: 50px 5vw;
            }

            @include md {
                padding: 50px 10%;
            }

            @include lg {
                padding: 50px 20%;
            }

            @include xl {
                padding: 50px 20%;
            }

            span {
                flex: 1;
            }

            .message-actions {
                position: absolute;
                top: 20px;
                right: 20%;
                display: flex;
                flex-direction: row;
                gap: 10px;

                @include xs {
                    right: 20px;
                }

                @include sm {
                    right: 20px;
                }

                .btn {
                    cursor: pointer;
                    padding: 5px 5px;
                    text-align: center;
                    border-radius: 5px;
                    font-family: $font-paragraphe;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 10px;
                    font-size: 0.9rem;
                }
            }

            @import "./chatFormatted.scss";
        }

        .down {
            margin-top: 30vh;
        }

        &::-webkit-scrollbar {
            width: 4px;
            /* Set the width of the scrollbar */
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 2px;
        }
    }

    @import "../NewChat/newChat.scss";

    .chat-input {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15vh 0 3vh;
        position: absolute;
        width: 100%;
        bottom: 0;
        z-index: 100;

        .input-container {
            display: flex;
            flex-direction: column;
            justify-content: left;
            gap: 10px;
            margin-bottom: 5vh;
            position: relative;

            @include xs {
                width: 90vw;
            }

            @include sm {
                width: 90vw;
            }

            @include md {
                width: 80vw;
            }

            @include lg {
                width: 50vw;
            }

            @include xl {
                width: 50vw;
            }

            .file-upload {
                display: none;

                .file-upload-label {
                    padding: 10px;
                    background-color: #4CAF50;
                    color: white;
                    cursor: pointer;
                }

                &:hover {
                    background-color: #45a049;
                }
            }

            .files {
                width: 100%;
                height: 40px;
                display: flex;
                flex-direction: row-reverse;
                gap: 10px;

                .addFile {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    width: 40px;
                    overflow: hidden;
                    border-radius: 5px;

                    &:hover {
                        cursor: pointer;
                    }
                }

                @import "./components/file/fileContainer.scss";

            }



            .new {
                position: absolute;
                right: 0px;
                display: flex;
                justify-content: flex-end;

                @include xs {
                    top: -10px;
                }

                @include sm {
                    top: -10px;
                }

                @include md {
                    top: -10px;
                }

                @include lg {
                    top: -10px;
                }

                @include xl {
                    top: -10px;
                }

                .vertical {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;

                    @include xs {
                        flex-direction: column;
                    }

                    @include sm {
                        flex-direction: column;
                    }

                    @include md {
                        flex-direction: column;
                    }

                    @include lg {
                        flex-direction: row;
                    }

                    @include xl {
                        flex-direction: row;
                    }

                    .test {
                        bottom: 0;
                        position: absolute;
                        display: flex;
                        padding: 2px;
                        border-radius: 30px;
                        gap: 5px;
                        z-index: 25;


                        @include xs {
                            flex-direction: column;
                            bottom: 55px;
                        }

                        @include sm {
                            flex-direction: column;
                            bottom: 55px;
                        }

                        @include md {
                            flex-direction: column;
                            bottom: 55px;

                        }

                        @include lg {
                            flex-direction: row;
                            right: 55px;
                        }

                        @include xl {
                            flex-direction: row;
                            right: 55px;
                        }

                        .mobile-btn {
                            height: 26px;
                            width: 26px;
                        }
                    }

                    .mobile-btn {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 10px;
                        height: 30px;
                        width: 30px;
                        border-radius: 50%;
                        cursor: pointer;
                    }
                }
            }

            .options {
                display: flex;
                flex: 1;
                gap: 10px;

                @include xs {
                    width: 90vw;
                }

                @include sm {
                    width: 90vw;
                }

                @include md {
                    width: 80vw;
                }

                @include lg {
                    width: 50vw;
                }

                @include xl {
                    width: 50vw;
                }

                select {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;
                    font-size: 1em;
                    padding: 10px;
                    width: 100%;
                    text-overflow: ellipsis;
                    overflow-x: hidden;

                    option {
                        font-size: 1em;
                        border: none;
                        padding: 5px 10px;
                        border-radius: 5px;
                        cursor: pointer;
                        outline: none;
                        transition: background-color 0.2s ease-in-out;
                    }
                }

                button {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;
                }

                select,
                .temperature {
                    flex: 1;

                    @include xs {
                        padding: 10px;
                    }

                    @include sm {
                        padding: 10px;
                    }

                }

                .temperature {
                    position: relative;
                    display: flex;
                    flex-direction: column;

                    @include xs {
                        display: none;
                    }

                    @include sm {
                        display: none;
                    }

                    @include md {
                        display: none;
                    }

                    .description {
                        position: absolute;
                        border-radius: 10px;
                        left: -20px;
                        padding: 15px;
                        min-height: 100px;
                        width: 125%;

                        &.hidden {
                            display: none;
                        }

                        &.visible {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: flex-start;
                            gap: 10px;
                        }

                        &::after {
                            content: "";
                            position: absolute;
                            bottom: -10px; // La taille du triangle
                            left: 20px; // Centre le triangle par rapport à la div description
                            border-width: 0 5px 10px 5px; // Taille du triangle
                            border-style: solid;
                            transform: rotate(180deg);
                        }
                    }

                    .title {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 10px;

                        svg {
                            cursor: pointer;
                            height: 15px;
                        }
                    }

                    p {
                        font-size: 1em;
                    }

                    .output {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        gap: 20px;

                        input {
                            flex-grow: 1;
                        }
                    }
                }
            }

            .input {
                flex: 1;
                display: flex;
                flex-direction: row;

                .textarea-container {
                    flex: 5;
                    resize: none !important;
                    padding: 10px;
                    line-height: 30px;
                    border-radius: 5px;
                    border: none;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-end;
                    overflow-y: auto;
                    position: relative;

                    textarea {
                        padding: 0px !important;
                        font-size: 0.9em;
                        width: 100%;
                        height: auto;
                        min-height: 30px;
                        resize: none;
                        overflow-y: scroll;
                        transition: height 0.2s, padding 0.2s;

                        &::-webkit-scrollbar {
                            width: 4px;
                        }

                        &::-webkit-scrollbar-thumb {
                            border-radius: 2px;
                        }
                    }

                    svg {
                        position: absolute;
                        fill: none;
                        stroke-width: 2;
                        bottom: 19px;
                        right: 30px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .chat-error {
        width: 70%;
        font-family: $font-paragraphe;
        border-radius: 10px;
        background-color: #ff8888;
        color: $light-error-text-color;
        border: 1px solid $light-error-text-color;
        padding: 1vh 2vw;
        bottom: -10px;
        opacity: 0;
        transition: bottom 0.3s, opacity 0.3s;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &.active {
            bottom: 0;
            opacity: 1;
        }

        .cross {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}