.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
  color: #333;

  @include xs {
    width: 80%;
    min-height: 20vh;
  }

  @include sm {
    width: 40%;
    min-height: 40px;
  }

  @include md {
    width: 30%;
    min-height: 400px;
  }

  @include lg {
    width: 20%;
    min-height: 400px;
  }

  @include xl {
    width: 20%;
    min-height: 400px;
  }

  .card-content {
    padding: 16px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 32px);
    gap: 2vh;
    align-items: flex-start;
    justify-content: center;

    .card-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 0;
      padding: 0;
      font-size: 1em;
      font-weight: 700;

      h3 {
        font-family: 'Montserrat';
        font-weight: bold;
      }
    }

    .card-description {
      font-size: 1em;
    }
  }
}