 
.prompt {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;

    .paragraphe, p {
        font-size: 1em;
        font-family: $font-paragraphe;
        ol {
            li {
                margin-top: 10px;
            }
        }
    }
    .textarea-container {
        border-radius: 10px;
        padding: 20px;
        textarea {
            max-width: 100%;
            min-width: 100%;
            min-height: 50vh;
            padding: 0px !important;
            font-size: 0.9em;
            line-height: 30px;
            border-radius: 10px;

            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 2px; /* Set the border radius for a rounded scrollbar line */
            }
        }
    }
    button {
        width: 100%;
        text-align: center;
        padding: 2vh 0;
        font-size: 1em !important;

        &:disabled {
            background-color: #ccc; /* Set the background color for disabled buttons */
            color: #999; /* Set the text color for disabled buttons */
            cursor: not-allowed; /* Set the cursor for disabled buttons */
        }
    }
}