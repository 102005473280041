.container {
    height: 100vh;
    display: flex;
    flex-direction: column;

    .top-bar {
        flex: 1;
        background-color: rgb(229, 229, 229);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: left;
        padding-left: 80px;
    }

    .editor {
        flex: 14;
        height: 100%;
    }

    .console {
        flex: 4;
        background-color: rgb(229, 229, 229);
        overflow-y: scroll;
        padding-bottom: 10vh;

        .logline {
            display: flex;
            flex-direction: row;
            gap: 20px;
            padding: 10px;
            align-items: center;
            justify-content: left;
            border-bottom: 1px solid black;
        }
    }
}