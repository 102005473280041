.login {
    display: flex;
    place-content: center;
    align-items: center;
    overflow: hidden;



    .lines {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        margin: auto;
        width: 90vw;

        .line {
            position: absolute;
            width: 1px;
            height: 100%;
            top: 0;
            left: 50%;
            overflow: hidden;

            &::after {
                content: '';
                display: block;
                position: absolute;
                height: 15vh;
                width: 100%;
                top: -50%;
                left: 0;
                animation: drop 7s 0s infinite;
                animation-fill-mode: forwards;
                animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
            }

            &:nth-child(1) {
                margin-left: -25%;

                &::after {
                    animation-delay: 2s;
                }
            }

            &:nth-child(3) {
                margin-left: 25%;

                &::after {
                    animation-delay: 2.5s;
                }
            }
        }

        @keyframes drop {
            0% {
                top: -50%;
            }

            100% {
                top: 110%;
            }
        }
    }

    .cardLogin {
        border-radius: 10px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(8.9px);
        -webkit-backdrop-filter: blur(88.9px);
        border: 1px solid rgba(255, 255, 255, 1);

        position: absolute;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: none !important;
        gap: 0.5vh;

        @include xs {
            width: 60%;
            padding: 1rem;

            &.password-recovery {
                width: 80% !important;
            }
        }

        @include sm {
            width: 50%;
            padding: 2rem;

            &.password-recovery {
                width: 90% !important;
            }
        }

        @include md {
            width: 40%;
            padding: 2rem;

            &.password-recovery {
                width: 80% !important;
            }
        }

        @include xl {
            width: 15%;
            padding: 2rem;

            &.password-recovery {
                width: 30% !important;
            }
        }

        @include xxl {
            width: 13%;
            margin: 0;
            padding: 1.5rem;

            &.password-recovery {
                width: 20% !important;
            }
        }

        &.password-recovery {
            .password-text {
                text-align: center;
                font-size: 0.8rem;
                margin-bottom: 4vh;
            }
        }

        a {
            font-family: $font-paragraphe;

            @include xs {
                font-size: 0.8rem;
            }

            @include sm {
                font-size: 0.8rem;
            }

            @include md {
                font-size: 1rem;
            }

            @include xl {
                font-size: 1rem;
            }

            @include xxl {
                font-size: 0.7rem;
            }
        }

        .logo {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: auto;
            position: relative;

            img {
                width: 80%;
                height: auto;
                margin-bottom: 20px;
            }
        }

        form {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            @include xs {
                gap: 1vh;
            }

            @include sm {
                gap: 1vh;
            }

            @include md {
                gap: 1.5vh;
            }

            @include xl {
                gap: 1.5vh;
            }

            @include xxl {
                gap: 0.5vh;
            }

            img {
                padding: 0 2vw;
            }

            .form-group {
                display: flex;
                flex-direction: column;
                gap: 0.2vh;
                width: 100%;

                .form-head {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 7px;

                    p, svg {
                        font-size: 0.9rem;
                    }
                }

                .pass {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    span {
                        margin: 0;
                        padding: 0;
                        font-family: $font-paragraphe;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }

                input {
                    overflow: hidden;
                    font-family: $font-paragraphe;
                    background: none;
                    padding: 10px;
                    font-size: 0.8rem;

                    @include xs {
                        border-radius: 5px;
                    }

                    @include sm {
                        border-radius: 5px;
                    }

                    @include md {
                        border-radius: 5px;
                    }

                    @include xl {
                        border-radius: 5px;
                    }

                    @include xxl {
                        border-radius: 5px;
                    }
                }
            }

            button {
                width: 100%;
                font-size: 0.8rem;
                padding: 10px;
                border-radius: 5px;
                margin-top: 10px;

            }

            .errormsg {
                font-size: 14px;
            }
        }

        .line-separator {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 2vh 0;
            .line {
                width: 40%;
                height: 1px;
            }
        }

        .sso {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            justify-content: center;
            gap: 10px;
            img {
                width: auto;
                height: 20px;
            }
        }
    }

    .footer {
        position: absolute;
        bottom: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 3vh;
        font-family: $font-paragraphe;

        p {
            font-size: 15px;

            a {
                font-family: 'Apax';
                text-decoration: none;
            }
        }
    }
}