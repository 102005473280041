@mixin dark-theme {

    h1,
    h2,
    h3,
    h4,
    svg {
        color: $dark-titre;
    }

    textarea {
        color: lighten($dark-paragraphe, 50%);
        background-color: lighten($dark-bg, 20%);

        &::-webkit-scrollbar-thumb {
            background-color: #ccc;
        }
    }

    p {
        color: $dark-paragraphe;

        &.error {
            color: $dark-error-text-color;
        }
    }

    input,
    .action,
    .switch,
    .addTextInput {
        background-color: lighten($dark-bg, 10%);
        color: $dark-titre;
    }

    button {
        background-color: darken($primary-color, 35%);
        color: lighten($primary-color, 40%);

        &:hover {
            background-color: darken($primary-color, 10%);
        }

        &.success {
            background-color: $dark-success-bg-color !important;
            color: $dark-success-text-color;
        }

        &.error {
            background-color: $dark-error-bg-color !important;
            color: $dark-error-text-color;
        }

        &:disabled {
            background-color: #ccc;
            color: #999;
            cursor: not-allowed;

            svg {
                color: #999;
            }
        }
    }

    .sso {
        background-color: #2F2F2F !important;

        &:hover {
            background-color: #464646 !important;
        }

        color: $light-bg !important;
    }

    .btn,
    .specialized-chatbot>select {
        border: 1px solid #ccc;
        color: lighten($dark-paragraphe, 20%);

        &:hover,
        &.active {
            background-color: lighten($primary-color, 40%);
            border: 1px solid lighten($primary-color, 40%);
            color: darken($primary-color, 10%);

            svg {
                color: darken($primary-color, 10%);
            }
        }

        &:disabled {
            background-color: initial;
            border: 1px solid #ccc;
        }
    }

    .specialized-chatbot>select {
        option {
            color: $dark-paragraphe;
        }
    }

    .loading {
        background-color: $dark-bg;
    }

    .icon {
        color: $dark-titre;
    }

    .section {
        background-color: $dark-bg;
    }

    .navbar {
        background-color: $dark-bg;
    }

    a {
        color: $dark-titre;
    }

    .login-link {
        color: $dark-titre;
    }

    .window {
        .topbar {
            background-color: lighten($dark-bg, 10%);
        }

        .content {
            background-color: lighten($dark-bg, 20%);
        }

        .bottombar {
            background-color: lighten($dark-bg, 10%);
        }
    }

    .toggle-container {
        span {
            color: #d1d5db;
        }
    }

    select {
        background-color: lighten($dark-bg, 10%);
        color: $dark-titre;

        &:hover {
            background-color: lighten($dark-bg, 15%);
        }

        option {
            background-color: lighten($dark-bg, 5%);

            &:checked {
                background-color: $primary-color;
                color: #fff;
            }
        }
    }

    form {
        input {
            background-color: lighten($dark-bg, 10%);
            color: $dark-titre;
        }
    }

    .login {
        background-color: $dark-bg;

        .lines {
            .line {
                background: lighten($dark-bg, 10%);

                &::after {
                    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%)
                }
            }
        }

        .line-separator>.line {
            background: lighten($dark-bg, 10%);
        }

        .cardLogin {
            background: rgba(lighten($dark-bg, 10%), 10%);
        }

        form {
            .form-group {
                .form-head {
                    color: $dark-titre;

                    p,
                    svg {
                        color: $dark-titre
                    }
                }

                .pass {
                    color: $dark-titre;
                }

                .icon {
                    fill: $dark-titre
                }

                input {
                    background-color: lighten($dark-bg, 5%) !important;
                    color: $dark-titre;
                }
            }

            a {
                color: $dark-titre;
            }
        }

        .footer {
            background: lighten($dark-bg, 10%);

            p,
            a {
                color: $dark-titre;
            }
        }
    }

    .contactForm {
        background-color: lighten($dark-bg, 1%);

        textarea,
        input,
        select {
            background-color: lighten($dark-bg, 3%);
            color: $dark-paragraphe;
        }
    }

    .card {
        background-color: lighten($dark-bg, 10%);

        &:hover {
            background-color: lighten($dark-bg, 15%);
        }

        .card-description {
            color: $dark-paragraphe;
        }

        .titre {
            .icon {
                .audit {
                    color: $dark-second;
                }

                .formation {
                    color: $dark-third;
                }

                .implementation {
                    color: $dark-fourth;
                }
            }

            .underline {
                &.audit {
                    svg {
                        stroke: $dark-second;
                    }
                }

                &.formation {
                    svg {
                        stroke: $dark-third;
                    }
                }

                &.implementation {
                    svg {
                        stroke: $dark-fourth;
                    }
                }
            }
        }

        .texte {
            .line {
                &.audit {
                    background-color: $dark-second;
                }

                &.formation {
                    background-color: $dark-third;
                }

                &.implementation {
                    background-color: $dark-fourth;
                }
            }
        }

        .lien {
            &.audit {
                p {
                    background-color: darken($dark-second, 30%);
                    color: lighten($dark-second, 40%);

                    &:hover {
                        background-color: $dark-second;
                    }
                }

                .icon {
                    color: lighten($dark-second, 40%);
                }
            }

            &.formation {
                p {
                    background-color: darken($dark-third, 30%);
                    color: lighten($dark-third, 40%);

                    &:hover {
                        background-color: $dark-third;
                    }
                }

                .icon {
                    color: lighten($dark-third, 40%);
                }
            }

            &.implementation {
                p {
                    background-color: darken($dark-fourth, 30%);
                    color: lighten($dark-fourth, 40%);

                    &:hover {
                        background-color: $dark-fourth;
                    }
                }

                .icon {
                    color: lighten($dark-fourth, 40%);
                }
            }
        }

    }

    .question {
        .form {
            p {
                color: $dark-paragraphe;
            }

            button {
                &:hover {
                    p {
                        color: $dark-paragraphe !important;
                    }
                }
            }

            input,
            textarea {
                color: lighten($primary-color, 30%) !important;
                border-bottom: 1px lighten($primary-color, 30%) solid !important;

                &::placeholder {
                    color: lighten($primary-color, 30%);
                }
            }

            .renderer-core-form-flow {
                background-image: url("https://ik.imagekit.io/z0tzxea0wgx/Cogitum/bg__jOx__KXki.png?updatedAt=1680317119803");
            }

            .renderer-components-error-message-wrapper {
                .renderer-core-html-parser {
                    p {
                        color: white !important;
                    }
                }
            }
        }
    }

    .dashboard,
    .shared {
        background-color: lighten($dark-bg, 5%);
    }

    .cogichat {
        background-color: lighten($dark-bg, 5%);

        .sidebar {
            background-color: lighten($dark-bg, 10%);
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);
            color: lighten($dark-paragraphe, 20%);

            .arrow {
                background-color: lighten($dark-bg, 10%);
            }

            .logo {
                background-color: lighten($dark-bg, 20%);

                &.active {
                    &:hover {
                        background-color: lighten($dark-bg, 15%);
                    }
                }

                h1 {
                    span {
                        color: lighten($primary-color, 20%) !important;
                    }
                }
            }

            .hidden {
                color: lighten($dark-bg, 20%);
            }

            .logout {
                .MuiSwitch-track {
                    background-color: $primary-color;
                }
            }

            .sb {
                &.favorite {
                    .two {
                        svg.star {
                            fill: $dark-third;
                            stroke: $dark-third;
                        }
                    }
                }

                &.history {
                    .two {
                        svg.star {
                            &:hover {
                                fill: $dark-third;
                                stroke: $dark-third;
                            }
                        }

                        svg.trash {
                            &:hover {
                                fill: $dark-second;
                            }
                        }
                    }
                }

                h2 {
                    border-bottom: 1px solid #ccc;
                }

                ul {
                    li {
                        .one {
                            p {
                                color: lighten($dark-paragraphe, 20%);
                            }

                            &:hover {
                                background-color: lighten($dark-bg, 30%);
                            }
                        }

                        &.active {
                            background-color: lighten($primary-color, 40%);
                            color: darken($primary-color, 40%);

                            p {
                                color: darken($primary-color, 40%);
                            }

                            .one {
                                &:hover {
                                    background-color: transparent;
                                }
                            }
                        }
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: #ccc;
                    }
                }

            }
        }

        .chat {
            background-color: lighten($dark-bg, 10%);
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);

            .chat-messages {
                .message {

                    &.unpair {
                        background-color: lighten($dark-bg, 12%);
                    }

                    &.pair {
                        background-color: lighten($dark-bg, 15%);
                    }
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #ccc;
                    /* Set the color of the scrollbar line */
                }
            }

            .new-chat {
                p {
                    color: lighten($dark-bg, 60%);

                    span {
                        color: lighten($primary-color, 20%);
                    }
                }

                .projects-container {
                    background-color: lighten($dark-bg, 5%);

                    &::-webkit-scrollbar-thumb {
                        background-color: #ccc;
                    }
                }

                .templates {
                    &::before {
                        background: linear-gradient(to right, lighten($dark-bg, 10%), transparent);
                    }

                    &::after {
                        background: linear-gradient(to left, lighten($dark-bg, 10%), transparent);
                    }
                }
            }

            .chat-input {
                background-image: linear-gradient(to top, rgba(lighten($dark-bg, 5%), 1) 50%, rgba(lighten($dark-bg, 5%), 0) 100%);

                .input-container {


                    .files {

                        .addFile,
                        .file-container {
                            background-color: lighten($dark-bg, 10%);

                            .cross {
                                background-color: lighten($dark-bg, 20%);
                            }
                        }
                    }

                    .new {

                        .test {
                            background-color: $dark-bg;
                        }

                        .mobile-btn {
                            background-color: lighten($dark-bg, 20%);

                            &:hover {
                                background-color: lighten($dark-bg, 25%);
                            }

                            svg {
                                fill: lighten($dark-paragraphe, 50%);
                            }
                        }
                    }

                    .options {
                        .temperature {
                            p {
                                color: lighten($dark-paragraphe, 50%);
                            }

                            input {
                                accent-color: lighten($primary-color, 20%);
                            }

                            .description {
                                background-color: lighten($dark-bg, 30%);

                                &::after {
                                    border-color: transparent transparent lighten($dark-bg, 30%) transparent; // Couleur du triangle
                                }
                            }
                        }
                    }

                    .input {

                        .textarea-container {
                            background-color: lighten($dark-bg, 20%);
                            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
                        }

                        svg {
                            stroke: lighten($dark-paragraphe, 50%);
                        }
                    }
                }
            }
        }
    }

    .chat-formatted {

        .role {

            &.assistant,
            &.system {
                background-color: lighten($dark-bg, 10%);
            }
        }

        .loader {
            span {
                background: $dark-titre;
            }
        }

        .pdf-container {
            color: lighten($dark-paragraphe, 20%);

            .pdf {
                background-color: lighten($dark-bg, 10%);
            }
        }

        .content {

            &.user {
                color: lighten($dark-paragraphe, 20%);
            }

            h1 {
                color: lighten($dark-paragraphe, 20%);
            }

            h2 {
                color: lighten($dark-paragraphe, 20%);
            }

            h3 {
                color: lighten($dark-paragraphe, 20%);
            }

            h4 {
                color: lighten($dark-paragraphe, 20%);
            }

            h5 {
                color: lighten($dark-paragraphe, 20%);
            }

            h6 {
                color: lighten($dark-paragraphe, 20%);
            }

            p {
                color: lighten($dark-paragraphe, 20%);

                span {
                    &.span-code {
                        color: lighten($dark-paragraphe, 70%);
                    }
                }
            }

            ul {
                color: lighten($dark-paragraphe, 20%);

                li {
                    color: lighten($dark-paragraphe, 20%);
                }
            }

            ol {
                color: lighten($dark-paragraphe, 20%);

                li {
                    color: lighten($dark-paragraphe, 20%);
                }
            }

            blockquote {
                border-left: 2px solid lighten($dark-bg, 95%);
                color: lighten($dark-paragraphe, 20%);
            }

            a {
                color: #007bff;
            }

            pre {

                .codeContainer {
                    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
                    background-color: lighten($dark-bg, 8%) !important;

                    .codeFormatted {
                        background-color: none !important;
                        background: none !important;
                        color: rgb(171, 178, 191);

                        code {
                            background: none !important;
                            background-color: none !important;
                        }
                    }
                }

                span {
                    &.span-code {
                        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
                        background-color: lighten($dark-bg, 8%) !important;
                        color: lighten($dark-paragraphe, 20%);
                    }
                }
            }

            .tableContainer {
                border: 1px solid lighten($dark-bg, 20%);

                table {
                    border: 1px solid lighten($dark-bg, 20%);
                    color: lighten($dark-paragraphe, 20%);

                    tbody {
                        tr:nth-child(odd) {
                            background-color: lighten($dark-bg, 8%);
                        }

                        tr:hover {
                            background-color: lighten($primary-color, 40%);
                            border: 1px solid lighten($primary-color, 40%);
                            color: darken($primary-color, 10%);
                        }
                    }

                    thead {
                        tr {
                            border-bottom: 1px solid lighten($dark-bg, 20%);
                        }
                    }
                }
            }
        }

        .function-container {
            .button-content {
                color: $dark-titre;
            }

            .plurial-text-inputs-component {
                background-color: lighten($dark-bg, 30%);
            }

            .param-button {

                svg {
                    path {
                        fill: $dark-titre;
                    }
                }

                &.active {
                    &:hover {
                        background-color: lighten($primary-color, 40%);
                        border: 1px solid lighten($primary-color, 40%);
                        color: darken($primary-color, 10%);

                        svg {
                            path {
                                fill: darken($primary-color, 10%);
                            }
                        }
                    }
                }
            }
        }
    }

    .settings {

        .settings-container,
        .loader-container {
            background-color: lighten($dark-bg, 5%);
            color: lighten($dark-paragraphe, 20%);

            .paragraphe,
            p {
                color: lighten($dark-bg, 50%);
            }

            .textarea-container {
                background-color: lighten($dark-bg, 20%);
                box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
            }

            textarea {
                color: lighten($dark-paragraphe, 50%);
                background-color: lighten($dark-bg, 20%);

                &::-webkit-scrollbar-thumb {
                    background-color: #ccc;
                }
            }
        }

        .loader:before {
            animation: ball1 1s infinite;
            background-color: lighten($dark-bg, 20%);
            box-shadow: 30px 0 0 lighten($dark-bg, 10%);
            margin-bottom: 10px;
        }

        .loader:after {
            animation: ball2 1s infinite;
            background-color: lighten($dark-bg, 30%);
            box-shadow: 30px 0 0 lighten($dark-bg, 40%);
        }

        .user {
            input {
                &:disabled {
                    color: #595959;
                    background-color: #3e3e3e;
                }
            }
        }

        .choose-avatar {
            background-color: lighten($dark-bg, 10%);

            .avatar-container {
                &::-webkit-scrollbar-thumb {
                    background-color: lighten($dark-bg, 40%);
                }
            }

            li {

                &:hover,
                &.active {
                    background-color: lighten($dark-bg, 40%);
                }
            }
        }

        .credits {
            .progress-bar {
                background-color: lighten($dark-bg, 10%);
            }
        }

        .charts {

            .grid {
                stroke: lighten($dark-bg, 50%);
            }

            .chart-placeholder {
                background-color: lighten($dark-bg, 10%);
            }

            .global-line {
                path {
                    stroke-width: 4;
                }
            }
        }

        .users {

            border: 1px solid lighten($dark-bg, 20%);

            tbody {
                tr:nth-child(even) {
                    background-color: lighten($dark-bg, 8%);
                }

                tr:hover {
                    background-color: lighten($primary-color, 40%);
                    color: darken($primary-color, 10%);
                }

                .disabledClass:hover {
                    background-color: transparent;
                    color: lighten($dark-paragraphe, 20%);
                }
            }

            thead {
                tr {
                    border-bottom: 1px solid lighten($dark-bg, 20%);
                }
            }
        }
    }

    .dce {
        .tableContainer {
            border: 1px solid lighten($dark-bg, 20%);

            table {
                border: 1px solid lighten($dark-bg, 20%);
                color: lighten($dark-paragraphe, 20%);

                .checked {
                    background: linear-gradient(45deg, rgb(52, 103, 66), transparent);
                }

                .dropdown {
                    border: 1px solid lighten($dark-bg, 20%);

                    &:hover {
                        background-color: lighten($primary-color, 40%);
                        color: darken($primary-color, 30%);
                    }

                    svg {
                        fill: lighten($dark-bg, 20%);
                        stroke: lighten($dark-bg, 20%);
                    }
                }

                tbody {
                    tr:nth-child(odd) {
                        background-color: lighten($dark-bg, 8%);
                    }

                    .hoverable:hover {
                        background-color: lighten($primary-color, 40%);
                        color: darken($primary-color, 10%);
                    }

                    svg {
                        color: darken($primary-color, 10%);
                    }
                }

                thead {
                    tr {
                        border-bottom: 1px solid lighten($dark-bg, 20%);
                    }
                }
            }
        }
    }

    .toggle-darkmode {
        border: 1px solid $dark-titre;
        background-color: lighten($dark-bg, 10%);

        svg {
            fill: $dark-titre;
        }
    }

    .folder {
        color: $dark-titre;
    }
}