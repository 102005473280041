@mixin light-theme {

  h1,
  h2,
  h3,
  h4,
  svg,
  textarea {
    color: $light-titre;
  }

  p {
    color: $light-paragraphe;

    &.error {
      color: $light-error-text-color;
    }
  }

  input, .action, .switch, .addTextInput {
    background-color: darken($light-bg, 15%);
    color: $light-titre;
  }

  button {
    background-color: lighten($primary-color, 30%);
    color: darken($primary-color, 30%);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    &:hover {
      background-color: lighten($primary-color, 10%);
    }

    &.success {
      background-color: $light-success-bg-color !important;
      color: $light-success-text-color;
    }

    &.error {
      background-color: $light-error-bg-color !important;
      color: $light-error-text-color;
    }

    &:disabled {
      background-color: #ccc;
      /* Set the background color for disabled buttons */
      color: #999;
      /* Set the text color for disabled buttons */
      cursor: not-allowed;

      /* Set the cursor for disabled buttons */
      svg {
        color: #999;
      }
    }
  }

  .sso {
    background-color: lighten($dark-bg, 5%) !important;
    color: $light-bg !important;
    box-shadow: none;

    &:hover {
      background-color: lighten($dark-bg, 15%) !important;
    }

    color: $dark-paragraphe;
  }

  .btn,
  .specialized-chatbot>select {
    box-shadow: none;
    border: 1px solid #ccc;

    &:hover,
    &.active {
      background-color: lighten($primary-color, 40%);
      border: 1px solid white;
    }

    &:disabled {
      background-color: initial;
      border: 1px solid #ccc;
    }
  }

  .specialized-chatbot>select {
    option {
      background-color: darken($light-bg, 3%);
    }
  }

  .login {
    background-color: $light-bg;

    .lines {
      .line {
        background: darken($light-bg, 10%);

        &::after {
          background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000000 75%, #000000 100%)
        }
      }
    }

    .line-separator>.line {
      background: darken($light-bg, 10%);
    }

    .cardLogin {
      background: rgba(darken($light-bg, 2%), 2%);
    }

    form {
      .form-group {
        .form-head {
          color: $light-titre;

          p,
          svg {
            color: $light-titre;
            fill: $light-titre;
          }
        }

        .pass {
          color: $light-titre;
        }

        .icon {
          fill: $light-titre;
        }

        input {
          background-color: darken($light-bg, 5%) !important;
          color: $light-titre;
        }
      }

      a {
        color: $light-titre;
      }
    }

    .footer {
      background: darken($light-bg, 10%);

      p,
      a {
        color: $light-titre;
      }
    }
  }

  .loading {
    background-color: $light-bg;
  }

  .section {
    background-color: $light-bg;
  }

  .navbar {
    background-color: $light-bg;
  }

  .icon {
    color: $light-titre;
  }

  .navbar::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 60px;
    /* hauteur du dégradé */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    z-index: -1;
  }

  a {
    color: $light-titre;
  }

  .login-link {
    color: $light-titre;
  }

  .window {
    .topbar {
      background-color: darken($light-bg, 10%);
    }

    .content {
      background-color: darken($light-bg, 20%);
    }

    .bottombar {
      background-color: darken($light-bg, 10%);
    }
  }

  .toggle-container {
    span {
      color: $light-titre;
    }
  }

  select {
    background-color: darken($light-bg, 2%);
    color: $light-titre;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);

    &:hover {
      background-color: darken($light-bg, 5%);
    }
  }

  form {
    input {
      background-color: darken($light-bg, 2%);
      color: $light-titre;
    }
  }


  .contactForm {
    background-color: darken($light-bg, 1%);

    input,
    textarea,
    select {
      background-color: darken($light-bg, 4%);
      color: $light-paragraphe;
    }
  }

  .card {
    background-color: $light-bg;

    &:hover {
      background-color: darken($light-bg, 5%);
    }

    .titre {
      .icon {
        .audit {
          color: $light-second;
        }

        .formation {
          color: $light-third;
        }

        .implementation {
          color: $light-fourth;
        }
      }

      .underline {
        &.audit {
          svg {
            stroke: $light-second;
          }
        }

        &.formation {
          svg {
            stroke: $light-third;
          }
        }

        &.implementation {
          svg {
            stroke: $light-fourth;
          }
        }
      }
    }

    .texte {
      .line {
        &.audit {
          background-color: $light-second;
        }

        &.formation {
          background-color: $light-third;
        }

        &.implementation {
          background-color: $light-fourth;
        }
      }
    }

    .lien {
      &.audit {
        p {
          background-color: lighten($light-second, 30%);
          color: darken($light-second, 20%);

          &:hover {
            background-color: $light-second;
          }
        }

        .icon {
          color: darken($light-second, 20%);
        }
      }

      &.formation {
        p {
          background-color: lighten($light-third, 30%);
          color: darken($light-third, 20%);

          &:hover {
            background-color: $light-third;
          }
        }

        .icon {
          color: darken($light-third, 20%);
        }
      }

      &.implementation {
        p {
          background-color: lighten($light-fourth, 30%);
          color: darken($light-fourth, 20%);

          &:hover {
            background-color: $light-fourth;
          }
        }

        .icon {
          color: darken($light-fourth, 20%);
        }
      }
    }

  }

  .question {
    .form {
      box-shadow: 35px 35px 71px #e6e6e6,
        -35px -35px 71px #ffffff;

      p {
        color: $light-paragraphe;
      }

      button {
        &:hover {
          p {
            color: white !important;
          }
        }
      }

      .renderer-core-form-flow {
        background-image: url("https://ik.imagekit.io/z0tzxea0wgx/Cogitum/bg2_wbEVWNt5N.png?updatedAt=1680315152110");
      }

      input,
      textarea {
        color: darken($primary-color, 25%) !important;
        border-bottom: 1px darken($primary-color, 25%) solid !important;

        &::placeholder {
          color: darken($primary-color, 25%);
        }
      }

      .renderer-components-error-message-wrapper {
        .renderer-core-html-parser {
          p {
            color: white !important;
          }
        }
      }
    }
  }

  .cogichat {

    .sidebar {
      background-color: #ffffff;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);

      .logo {
        background-color: darken($light-bg, 6%);
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);

        &.active {
          &:hover {
            background-color: darken($light-bg, 10%);
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
          }
        }
      }

      .hidden {
        color: darken($light-bg, 5%);
      }

      .arrow {
        background-color: darken($light-bg, 5%);
      }

      .logout {
        .MuiSwitch-track {
          background-color: $primary-color;
        }
      }

      .sb {
        &.favorite {
          .two {
            svg.star {
              fill: $light-third;
              stroke: $light-third;
            }
          }
        }

        &.history {
          .two {
            svg.star {
              &:hover {
                fill: $light-third;
                stroke: $light-third;
              }
            }

            svg.trash {
              &:hover {
                fill: $light-second;
              }
            }
          }
        }

        h2 {
          border-bottom: 1px solid #ccc;
        }

        ul {
          li {
            .one {
              &:hover {
                background-color: darken($light-bg, 10%);
              }
            }

            &.active {
              background-color: lighten($primary-color, 40%);
              color: darken($primary-color, 40%);

              p {
                color: darken($primary-color, 40%);
              }

              .one {
                &:hover {
                  background-color: transparent;
                }
              }
            }
          }

          &::-webkit-scrollbar-thumb {
            background-color: #ccc;
          }
        }

      }
    }

    .chat {
      background-color: darken($light-bg, 1%);

      .chat-header {
        &::after {
          background-color: #ccc;
        }
      }

      .chat-messages {
        .message {

          &.unpair {
            background-color: darken($light-bg, 1%);
          }

          &.pair {
            background-color: darken($light-bg, 10%);
          }
        }

        &::-webkit-scrollbar-thumb {
          background-color: #ccc;
          /* Set the color of the scrollbar line */
        }
      }

      .new-chat {
        p {
          color: #7a7a7a;

          span {
            color: $primary-color;
          }
        }

        .projects-container {
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
          background-color: darken($light-bg, 2%);

          &::-webkit-scrollbar-thumb {
            background-color: #ccc;
          }
        }

        .templates {
          &::before {
            background: linear-gradient(to right, darken($light-bg, 1%), transparent);
          }

          &::after {
            background: linear-gradient(to left, darken($light-bg, 1%), transparent);
          }
        }
      }

      .chat-input {
        background-image: linear-gradient(to top, rgba($light-bg, 1) 50%, rgba($light-bg, 0) 100%);

        .input-container {

          .new {
            .test {
              background-color: darken($light-bg, 10%);
            }

            .mobile-btn {
              background-color: darken($light-bg, 2%);
              box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);

              &:hover {
                background-color: darken($light-bg, 5%);
              }

              svg {
                fill: darken($light-bg, 80%);
              }
            }
          }

          .options {
            select {
              option {
                background-color: darken($light-bg, 2%);

                &:checked {
                  background-color: $primary-color;
                  color: #fff;
                }
              }
            }

            .temperature {
              input {
                accent-color: $primary-color;
              }

              .description {
                background-color: darken($light-bg, 10%);

                &::after {
                  border-color: transparent transparent darken($light-bg, 10%) transparent; // Couleur du triangle
                }
              }
            }
          }

          .files {

            .addFile,
            .file-container {
              background-color: darken($light-bg, 2%);
              box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);

              .cross {
                background-color: $dark-bg;
                svg {
                  fill: white;
                  color: white;
                }
              }
            }

          }

          .input {
            .textarea-container {
              background-color: darken($light-bg, 2%);
              box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
            }

            textarea {
              background-color: darken($light-bg, 2%);

              &::-webkit-scrollbar-thumb {
                background-color: #ccc;
              }
            }

            svg {
              stroke: #888;
            }
          }
        }
      }
    }
  }

  .chat-formatted {

    .role {

      &.assistant,
      &.system {
        background-color: darken($light-bg, 20%);
      }
    }

    .loader {
      span {
        background: $light-titre;
      }
    }

    .pdf-container {
      .pdf {
        background-color: #fff;
      }
    }

    .content {

      &.user {
        color: darken($light-paragraphe, 20%) !important;
      }

      h1 {
        color: $light-titre;
      }

      h2 {
        color: $light-titre;
      }

      h3 {
        color: $light-titre;
      }

      h4 {
        color: $light-titre;
      }

      h5 {
        color: darken($light-paragraphe, 20%) !important;
      }

      h6 {
        color: darken($light-paragraphe, 20%) !important;
      }

      p {
        color: darken($light-paragraphe, 20%) !important;
      }

      ul {
        color: darken($light-paragraphe, 20%) !important;

        li {
          color: darken($light-paragraphe, 20%) !important;
        }
      }

      ol {
        color: darken($light-paragraphe, 20%) !important;

        li {
          color: darken($light-paragraphe, 20%) !important;
        }
      }

      blockquote {
        border-left: 2px solid darken($light-paragraphe, 20%);
        color: darken($light-paragraphe, 20%) !important;
      }

      a {
        color: #007bff;
      }

      pre {

        .codeContainer {
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
          background-color: darken($light-bg, 3%) !important;


          .codeFormatted {
            background-color: none !important;
            background: none !important;

            code {
              background: none !important;
              background-color: none !important;
            }
          }
        }

        span {
          &.span-code {
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
            background-color: darken($light-bg, 3%) !important;
          }
        }
      }

      .tableContainer {
        border: 1px solid darken($light-bg, 20%);

        table {
          tbody {
            tr:nth-child(odd) {
              background-color: darken($light-bg, 8%);
            }

            tr:hover {
              background-color: lighten($primary-color, 40%);
              border: 1px solid lighten($primary-color, 40%);
              color: darken($primary-color, 10%);
            }
          }

          thead {
            tr {
              border-bottom: 1px solid darken($light-bg, 20%);
            }
          }
        }
      }
    }

    .function-container {
      .button-content {
        color: darken($primary-color, 30%);
      }

      .param-button {

        svg {
          path {
            fill: lighten($light-paragraphe, 30%);
          }
        }

        &.active {
          &:hover {
            background-color: lighten($primary-color, 40%);
            border: 1px solid white;

            svg {
              path {
                fill: darken($primary-color, 30%);
              }
            }
          }
        }
      }
      
      .plurial-text-inputs-component {
        background-color: #fff;
      }
    }
  }

  .settings {

    .settings-container,
    .loader-container {

      .paragraphe,
      p {
        color: darken($light-bg, 50%);
      }

      .textarea-container {
        background-color: darken($light-bg, 1%);
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
      }

      textarea {
        background-color: darken($light-bg, 1%);

        &::-webkit-scrollbar-thumb {
          background-color: #ccc;
        }
      }
    }

    .user {
      input {
        &:disabled {
          color: #bbbbbb;
          background-color: #dbdbdb;
        }
      }
    }

    .choose-avatar {
      background-color: darken($light-bg, 10%);

      .avatar-container {
        &::-webkit-scrollbar-thumb {
          background-color: darken($light-bg, 40%);
        }
      }

      li {

        &:hover,
        &.active {
          background-color: darken($light-bg, 40%);
        }
      }
    }

    .credits {
      .progress-bar {
        background-color: darken($light-bg, 10%);
      }
    }

    .charts {

      .grid {
        stroke: darken($light-bg, 80%);
      }

      .chart-placeholder {
        background-color: darken($light-bg, 10%);
      }


      .global-line {
        path {
          stroke-width: 4;
        }
      }
    }

    .users {
      border: 1px solid darken($light-bg, 20%);

      tbody {
        tr:nth-child(even) {
          background-color: darken($light-bg, 8%);
        }

        tr:hover {
          background-color: lighten($primary-color, 40%);
          color: darken($primary-color, 10%);
        }

        .disabledClass:hover {
          background-color: transparent;
          color: black;
        }
      }

      thead {
        tr {
          border-bottom: 1px solid darken($light-bg, 20%);
        }
      }
    }
  }

  .dce {
    .tableContainer {
      border: 1px solid darken($light-bg, 20%);

      table {
        border: 1px solid darken($light-bg, 20%);

        .checked {
          background: linear-gradient(45deg, rgb(142, 215, 160), transparent);
        }

        .dropdown {
          border: 1px solid darken($light-bg, 20%);

          &:hover {
            background-color: lighten($primary-color, 40%);
            color: darken($primary-color, 10%);
          }

          svg {
            fill: darken($light-bg, 20%);
            stroke: darken($light-bg, 20%);
          }
        }

        tbody {
          tr:nth-child(odd) {
            background-color: darken($light-bg, 8%);
          }

          .hoverable:hover {
            background-color: lighten($primary-color, 40%);
            color: darken($primary-color, 30%);
          }
        }

        thead {
          tr {
            border-bottom: 1px solid darken($light-bg, 20%);
          }
        }
      }
    }
  }

  .toggle-darkmode {
    background-color: darken($light-bg, 20%);

    svg {
      fill: $light-titre;
    }
  }

  .folder {
    color: $light-titre;
  }
}