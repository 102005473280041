.pdf-viewer-container {
    position: relative;
    flex: 2;

    .pdf-viewer-wrapper {
        position: relative;
        all: initial;
        font-family: Arial, sans-serif;
        /* Vous pouvez changer la police ici */

        .toolbar-light-mode {
            .rpv-toolbar {
                background-color: #ffffff;
            }
        }

        .toolbar-dark-mode {
            background-color: #333;
            color: #fff;

            input {
                background-color: #2b2a2a;
                color: #fff;
            }

            span {
                color: #d1d1d1;
            }

            .rpv-toolbar {
                background-color: #333;
            }

            .rpv-toolbar-button {
                color: #fff;

                &:hover {
                    background-color: #555;
                }
            }

            .rpv-toolbar-dropdown {
                background-color: #333;
                color: #fff;

                .rpv-toolbar-dropdown-item {
                    &:hover {
                        background-color: #555;
                    }
                }
            }

            .rpv-toolbar-separator {
                border-color: #555;
            }

            .rpv-toolbar-item {
                .rpv-toolbar-field {
                    background-color: #333;
                    color: #fff;
                }
            }
        }


        button {
            background-color: transparent;
            border: none;
            border-radius: .25rem;
            color: #11181c;
            cursor: pointer;
            height: 2rem;
            padding: 0 .5rem
        }

        input {
            background-color: var(--rpv-core__textbox-background-color);
            border: 1px solid var(--rpv-core__textbox-border-color);
            box-sizing: border-box;
            border-radius: .25rem;
            color: var(--rpv-core__textbox-color);
            padding: 0 .5rem;
            height: 2rem;
            width: 100%;
            font-size: 1rem;

        }
    }

}