.choose-avatar {
    z-index: 60;
    position: absolute;
    width: 80%;
    height: 50vh;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 20px;
    padding: 60px 20px 20px;
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .cross {
        position: absolute;
    }

    .avatar-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 2px; /* Set the border radius for a rounded scrollbar line */
        }

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;

            li {
                cursor: pointer;
                flex-basis: calc(20% - 10px);
                margin: 5px;
                display: inline-flex;
                justify-content: space-around;
                align-items: space-around;
                border-radius: 10px;

                .avatar-image {
                    max-width: 80%;
                    max-height: 80%;
                    height: auto;
                }
            }
        }
    }

    .validate {
        padding: 2vh 2vw;

        button {
            text-align: center;
            padding: 2vh 2vw;
            font-size: 1em !important;
    
            &:disabled {
                background-color: #ccc; /* Set the background color for disabled buttons */
                color: #999; /* Set the text color for disabled buttons */
                cursor: not-allowed; /* Set the cursor for disabled buttons */
            }
        }
    }
}