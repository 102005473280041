.content {
    display: flex;
    flex-direction: column;
    gap: 5vh;
    box-sizing: border-box;
    position: relative;


    .credits {
        position: absolute;
        top: -50px;
        right: 0;
        width: 30%;
        .titre {
            display: flex;
            align-items: baseline;
            gap: 10px;
            justify-content: space-between;
            p {
                font-size: 0.8em;
            }
        }

        .progress-bar {
            width: 100%;
            height: 10px;
            border-radius: 10px;
            overflow: hidden;

            .active {
                background-color: lighten($primary-color, 30%);
                height: 100%;
                border-radius: 10px;

                &.warning {
                    background-color: orange;
                }

                &.danger {
                    background-color: red;
                }
            }
          }
    }

    @import "../../../ChartUsage/chartUsage.scss";

    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .titre {
            display: flex;
            align-items: baseline;
            gap: 10px;
            p {
                font-size: 0.8em;
            }
        }
    }

    .teams {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 20px;
        flex-wrap: wrap;

        .btn {
            font-family: $font-title;
            border-radius: 20px;
            padding: 20px;
        }
    }

    @import "./userTable/userTable.scss";
}

@import "./userHandler/newUser.scss";
@import "./userHandler/userSelected.scss";
@import "./teamHandler/newTeam.scss";
@import "./teamHandler/teamSelected.scss";
@import "../provider/newProvider.scss";