.dashboard-container{
	position: relative;
	overflow: hidden;
    display: flex;
    flex-direction: row;
    width: 100%;

	.cogichat {
		display: flex;
		flex-direction: row;
		width: 100%;

		.btn {
			cursor: pointer;
			padding: 10px 10px;
			text-align: center;
			border-radius: 5px;
			font-family: $font-paragraphe;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			gap: 10px;
			font-size: 0.9rem;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.sidebar {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 20px;
			box-sizing: border-box;
			gap: 20px;
			z-index: 300;
			transition: width 0.1s, min-width 0.1s;

			@include xs {
				position: absolute;
				width: 85%;
				&.open {
					min-width: 300px;
				}
			}

			@include sm {
				position: absolute;
				width: 85%;
				&.open {
					min-width: 300px;
				}
			}

			@include md {
				position: absolute;
				width: 50%;
				&.open {
					min-width: 300px;
				}
			}

			@include lg {
				position: relative;
				width: 18%;

				&.open {
					min-width: 450px;
				}
			}

			@include xl {
				position: relative;
				width: 18%;

				&.open {
					min-width: 450px;
				}
			}

			&.absolute {
				position: absolute;
			}

			&.close {
				width: 0%;
				padding: 0;
			}

			.arrow {
				cursor: pointer;
				z-index: 40;
				width: 30px;
				height: 30px;
				border-radius: 5px;
				position: absolute;
				top: 10px;
				right: -35px;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.sidebar-content{
				overflow-x: hidden;
				width: 100%;
				height: 100%;
				flex-grow: 1;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap: 20px;

				.logo {
					margin: 3px;
					padding: 5px;
					display: flex;
					border-radius: 10px;
					width: 90%;
					justify-content: center;
					align-items: center;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;

					&.active {
						&:hover {
							cursor: pointer;
						}
					}

					.box {
						display: flex;
						justify-content: center;
						flex: 5;
						position: relative;
						img {
							width: 40%;
							height: auto;
						}
					}
					.back {
						display: flex;
						justify-content: center;
						align-items: center;
						flex: 1;
						cursor: pointer;
					}
				}

				.menu {
					flex-grow: 1;
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					width: 100%;
					gap: 10px;
					svg {
						fill: none;
					}
				}

				.logout {
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					gap: 10px;
					width: 100%;

					.btn:first-child {
						display: flex;
						align-items: center;
						gap: 8px;
						.icon { 
							display: flex;
							align-items: center;
							justify-content: center;
							flex-shrink: 0;
							min-width: 1em;
						}

						.text {
							white-space: nowrap; /* Empêche le texte de passer à la ligne */
							overflow: hidden; /* Cache le débordement de texte */
							text-overflow: ellipsis;
						}
						flex-grow: 1;
					}

					.toggle-container {
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}

				@import "../components/sidebar/chat/chat.scss";
			}

			.email {
				display: flex;
				width: 100%;
				justify-content: flex-start;
				align-items: center;
				gap: 10px;
				p {
					font-size: 1em;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}

		.big-container {
			display: flex;
			flex-grow: 1;
			position: relative;
			overflow: hidden;
			min-width: 70%;
			
			@import "../components/chat/chat.scss";
			@import "../components/settings/settings.scss";
			@import "./PDFViewer.scss";
		}
	}

	
}