.parameters {
    display: flex;
    flex-wrap: wrap;
    gap: 5vh;
    width: 100%;
    flex-direction: column;

    input, textarea {
        &:hover {
            &:disabled {
                cursor: not-allowed;
            }
        }
    }

    .param-container {
        display: flex;
        flex-direction: column;
        gap: 5px;
        border-radius: 10px;

        &.single {
            flex-grow: 1;
        }

        &.double {
            flex-grow: 1;
        }

        &.click {
            .param-button {
                &.active {
                    &:hover {
                        cursor: pointer;
                    }
                }

                .spinner {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    width: 50px;
                    height: 50px;
                }

                .spinner-circle {
                    border: 3px solid rgba(255, 255, 255, 0.3);
                    border-radius: 50%;
                    border-top-color: #fff;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    animation: spin 1s ease-in-out infinite;
                }

                .spinner-text {
                    z-index: 1;
                    font-size: 12px;
                    font-family: $font-paragraphe;
                }

                svg {
                    height: 80%;
                    width: auto;
                }

                @keyframes spin {
                    to {
                        transform: rotate(360deg);
                    }
                }


            }
        }

        h3 {
            margin-left: 5px;
        }

        h4 {
            margin: 10px;
        }

        .header {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            @include xs {
                gap: 0;
            }

            @include sm {
                gap: 0;
            }

            @include md {
                gap: 0;
            }



            .toggle-all-button {
                flex: 1;
                cursor: pointer;
                white-space: nowrap;
                text-align: right;


                @include xs {
                    font-size: 10px;
                }

                @include sm {
                    font-size: 10px;
                }

                @include md {
                    font-size: 10px;
                }

                &:hover {
                    text-decoration: underline;
                    transform: scale(1.01);
                }
            }
        }

        .param-button {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            padding: 10px;
            border: 1px solid white;
            position: relative;

            &.valid {
                cursor: auto;
                &:hover {
                    background-color: initial;
                }
            }

            img {
                height: 5vh;
                width: auto;
            }

            .files {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;
                width: 100%;
                gap: 20px;

                .pdf-function {
                    display: flex;
                    height: 100%; /* Assure que l'élément prend bien la hauteur du parent */
                    overflow: hidden;
                    position: relative;
                    gap: 5px;
                    box-sizing: border-box;
                    align-items: stretch; /* Étire les enfants sur la même hauteur */

                    .logo {
                      display: flex;
                      align-items: center;
                      height: 100%; /* Assure que le conteneur logo prend toute la hauteur */

                      img {
                        height: 46px; /* Assure que l'image occupe 100% de la hauteur du conteneur parent */
                        width: auto; /* Conserve les proportions de l'image */
                      }
                    }

                    .content {
                      display: flex;
                      flex-direction: column;
                      align-items: flex-start;
                      justify-content: center;
                      font-family: $font-paragraphe;
                      width: 50%;

                      .title,
                      .pages {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        width: 100%;
                      }
                    }
                  }

            }

            .checklist-container {
                overflow: auto;
                max-height: 100%;
                max-width: 100%;
                flex: 1;

                .buttons {
                    gap: 5px;
                    display: flex;
                    flex-wrap: wrap;

                    .btn {
                        border-radius: 20px;
                    }
                }

                &::-webkit-scrollbar {
                    width: 4px;
                    border-radius: 8px;
                    // background-color: lighten($primary-color, 20%);
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 2px;
                    background-color: lighten($primary-color, 20%);
                }
            }

            .checked {
                z-index: 1000;
                position: absolute;
                bottom: -21%;
                right: -1rem;
                width: 2rem;

                &::before {
                    content: "";
                    display: block;
                    padding-bottom: 100%;
                }

                img {
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                }
            }

            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(128, 128, 128, 0.5);
                border-radius: 10px;
            }
        }

        .textbox {
            flex-grow: 1;
            input {
                padding: 10px;
                width: calc(100% - 20px);
                overflow: auto;
                background: none;
                border: 1px solid rgb(255, 255, 255);
                border-radius:5px;
                vertical-align: middle;
            }
        }

        .plurial-text-inputs {
            display: flex;
            flex-direction: column;
            gap: 40px;

            .plurial-text-inputs-component {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 10px;
              padding: 10px;
              border-radius: 5px;
              max-width: 100%; // Limite la largeur pour éviter le dépassement

              .inputs {
                display: flex;
                gap: 5px;
                flex-wrap: wrap;
                width: 100%;
                flex-grow: 1;

                .input-actions {
                  width: 100%;
                  max-width: 100%; // S'assure que l'input-actions ne dépasse pas
                  display: flex;
                  gap: 5px;

                  input {
                    flex-grow: 1; // Permet à l'input de prendre tout l'espace disponible sans dépasser
                    font-size: 14px;
                    text-overflow: ellipsis; /* Pour gérer les textes trop longs */
                  }

                  .action-buttons {
                    display: flex;
                    gap: 5px;
                    flex-shrink: 0; // Empêche l'ajustement automatique de la largeur
                    width: auto; // Laissez-le s'ajuster automatiquement à la taille du contenu
                    max-width: calc((38px * 4) + 58px + 120px + (6px * 5)); // 5 boutons de 38px, 1 bouton de 58px, avec 5 espaces de 5px

                    .action {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      width: 38px; // Largeur fixe pour chaque bouton
                      height: 38px;
                      aspect-ratio: 1 / 1;
                      border-radius: 5px;

                      &:hover {
                        cursor: pointer;
                      }
                    }

                    .switch {
                      border-radius: 5px;
                    }

                    .dropdown-select {
                        width: 120px; /* Définissez une largeur fixe ou ajustez selon vos besoins */
                        height: 38px; /* Correspond à la hauteur des boutons */
                        border-radius: 5px; /* Correspond au style des boutons */
                        background-color: #d5d5d5; /* Couleur de fond, ajustez si nécessaire */
                        font-size: 14px; /* Correspond au texte des boutons */
                        padding: 0 10px; /* Ajout d'un peu de rembourrage pour le texte */
                        overflow: hidden; /* Assurez-vous que le contenu ne déborde pas */
                        text-overflow: ellipsis; /* Pour gérer les textes trop longs */
                        white-space: nowrap; /* Empêche le texte de passer à la ligne */
                    }

                      .dropdown-select:hover {
                        border-color: #999; /* Changer la couleur de la bordure au survol */
                        cursor: pointer; /* Changer le curseur au survol */
                      }

                      .dropdown-select:focus {
                        outline: none; /* Supprime l'outline par défaut */
                        border-color: #007bff; /* Changer la bordure en bleu au focus */
                      }

                      .dropdown-select:disabled {
                        cursor: not-allowed; /* Curseur désactivé */
                        background-color: #e0e0e0; /* Couleur de fond pour indiquer qu'il est désactivé */
                        border-color: #ccc; /* Bordure pour indiquer qu'il est désactivé */
                    }

                  }
                }

                textarea {
                  width: 100%;
                  resize: vertical;
                  font-size: 14px;
                  border: 1px solid black;
                }

                textarea {
                  height: 60px;
                }
              }

              .file-keywords {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 10px;

                h3 {
                  margin-right: 5px;
                }

                .keyword-list {
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  align-items: center;
                  gap: 5px;

                  .keyword-display {
                    border: 1px solid black;
                    border-radius: 50px;
                    padding: 5px;
                    font-family: "Inter";
                  }

                  input {
                    border: none;
                    border-radius: 50px;
                    padding: 5px;
                    font-size: 0.1rem !important;
                  }
                }
              }
            }

            .addTextInput {
              width: 100% !important;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 5px;
              padding: 5px;

              &:hover {
                cursor: pointer;
              }
            }
          }



        .checklist-container {
            display: flex;
            flex-direction: column;
            gap: 50px;

            .buttons {
                display: flex;
                flex-wrap: wrap;
                gap: 5px;
                width: calc(100% - 40px);
                padding: 20px;
                border-radius: 5px;
                border: 1px solid #fff;
            }
        }
    }
}