.agent {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;

    input {
        width: calc(100% - 20px);
        padding: 10px;
    }

    button {
        padding: 10px;
    }

    button, ul{
        width: 100%;
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    li {
        max-width: 100%;
        position: relative;
        border-radius: 10px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
        background-color: white;
        display: flex;
        padding: 10px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        
        .file {
            max-width: 100%;
            display: flex;
            flex-direction: row;
            gap: 10px;
            padding-left: 50px;

        .icon {
            position: absolute;
            top: 10px; /* Adjusting for li's padding */
            left: 10px; /* Adjusting for li's padding */
            height: calc(100% - 20px); /* Adjusting for li's padding */
            display: flex; 
            align-items: center; /* This will center the image vertically */
                
            img {
                height: 100%;
                width: auto;
            }
        }
        }
    }
}