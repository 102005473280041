.user {
    display: flex;
    position: relative;


    @include xs {
        flex-direction: column;
        gap: 60px;
        align-items: flex-start;
    }

    @include sm {
        flex-direction: column;
        gap: 60px;
        align-items: flex-start;
    }

    @include md {
        gap: 50px;
    }

    @include lg {
        gap: 50px;
    }

    @include xl {
        gap: 50px;
    }

    @import "./avatar.scss";

    .btn {
        text-align: center;
        cursor: pointer;
        padding: 10px 10px;
        text-align: center;
        border-radius: 5px;
        font-family: $font-paragraphe;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-size: 0.9rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .avatar {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;

        @include xs {
            align-items: flex-start;
            flex-direction: column;
            width: 50%;
            gap: 5px;
        }

        @include sm {
            align-items: flex-start;
            flex-direction: column;
            width: 50%;
            gap: 5px;
        }

        @include md {
            width: 15%;
            flex-direction: column;
        }

        @include lg {
            width: 15%;
            flex-direction: column;
        }

        @include xl {
            width: 15%;
            flex-direction: column;
        }


        .img {
            height: auto;
            position: relative;
            overflow: hidden;
            width: 100%;

            img {
                width: 100%;
                height: auto;
            }
        }

        .btn {
            width: 90%;
        }
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 20px;

        label {
            font-family: $font-paragraphe;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }

        input {
            padding: 15px;
        }

        .btn {
            width: 40%;
        }

        button {
            text-align: center;
            padding: 1vh 0;
            font-size: 1em !important;

            &:disabled {
                background-color: #ccc;
                /* Set the background color for disabled buttons */
                color: #999;
                /* Set the text color for disabled buttons */
                cursor: not-allowed;
                /* Set the cursor for disabled buttons */
            }

            @include xs {
                width: 100%;
            }

            @include sm {
                width: 100%;
            }

            @include md {
                width: 30%;
            }

            @include lg {
                width: 30%;
            }

            @include xl {
                width: 30%;
            }
        }
    }

    .infos {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 50px;
        width: 100%;

        .informations {
            display: flex;
            flex-direction: column;

            @include xs {
                width: 100%;
                gap: 5px;
            }

            @include sm {
                width: 100%;
                gap: 5px;
            }

            @include md {
                width: 80%;
                gap: 50px;
            }

            @include lg {
                width: 80%;
                gap: 50px;
            }

            @include xl {
                width: 80%;
                gap: 50px;
            }
        }

        .password {
            display: flex;
            flex-direction: column;

            @include xs {
                width: 100%;
                gap: 5px;
            }

            @include sm {
                width: 100%;
                gap: 5px;
            }

            @include md {
                width: 80%;
                gap: 50px;
            }

            @include lg {
                width: 80%;
                gap: 50px;
            }

            @include xl {
                width: 80%;
                gap: 50px;
            }
        }

        .tone {
            display: flex;
            flex-direction: column;

            @include xs {
                width: 100%;
                gap: 20px;
            }

            @include sm {
                width: 100%;
                gap: 20px;
            }

            @include md {
                width: 80%;
                gap: 10px;
            }

            @include lg {
                width: 80%;
                gap: 15px;
            }

            @include xl {
                width: 80%;
                gap: 15px;
            }

            h2 {
                margin-bottom: 30px;
            }

            .button {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;
                gap: 10px;

                .btn {
                    border-radius: 25px;

                    &.disabled {
                        cursor: not-allowed;
                    }
                }
            }
        }
    }
}