.dce {
    width: 100%;
    overflow-x: auto;
    margin-top: 2vh;

    .header {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
    }

    .head {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 5vh;

        input {
            padding: 5px;
        }
    }

    /* Styles pour les lignes développées */
    .open {
        cursor: pointer;
    }

    .open td {
        border-bottom: 2px solid #ddd;
        /* Exemple de bordure pour mieux voir la ligne */
    }

    .open+tr {
        display: table-row;
    }


    .tableContainer {
        width: calc(100% - 2px);
        overflow-x: auto;
        margin-top: 2vh;
        border-radius: 10px;

        .category-dropdown {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: $font-title;
        }

        table {
            border-radius: 10px;
            min-width: 100%;
            table-layout: fixed;
            border-collapse: collapse;
            font-family: $font-paragraphe;
            box-sizing: border-box;

            th,
            td {
                padding: 24px;
                text-align: left;
                box-sizing: border-box;
                font-size: 0.9rem;
                height: 100%;


                    .nocompare th,
                    .nocompare td {
                    padding: 8px;
                    border: 1px solid #ddd;
                    }

                    .nocompare th.conforme,
                    .nocompare td.conforme {
                    width: 5%; /* Extrait prend 2/10 ou 20% */
                    }
    
                    .nocompare th.extrait,
                    .nocompare td.extrait {
                    width: 25%; /* Extrait prend 2/10 ou 20% */
                    }
    
                    .nocompare th.explication,
                    .nocompare td.explication {
                    width: 60%; /* Explication prend 3/10 ou 60% */
                    }
    
                    .nocompare th.page,
                    .nocompare td.page {
                    width: 5%;
                    }

                    .compare th,
                    .compare td {
                    padding: 8px;
                    border: 1px solid #ddd;
                    }
    
                    .compare th.extrait,
                    .compare td.extrait {
                    width: 25%; /* Extrait prend 2/10 ou 20% */
                        span {
                            display: block; /* Pour mettre le contenu du span sur une nouvelle ligne */
                            font-size: smaller; /* Pour réduire la taille de la police */
                            font-style: italic; /* Pour appliquer l'italique */
                            margin-top: 5px; /* Pour espacer un peu du texte précédent, optionnel */
                        }
                    }
    
                    .compare th.explication,
                    .compare td.explication {
                    width: 30%; /* Explication prend 3/10 ou 60% */
                    }

                    .compare th.conforme,
                    .compare td.conforme {
                    width: 5%; /* Explication prend 3/10 ou 60% */
                    }
    
                    .compare th.page,
                    .compare td.page {
                    width: 10%;
                    }

                    td.page {
                        span {
                            text-decoration: underline;
                            color: blue;
                        }
                    }



                .text {
                    position: relative;
                    display: flex;
                    max-width: 300px;
                    flex-wrap: wrap;
                }

                .roro {
                    position: relative;
                    margin-top: 60px;

                    button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 5px 10px;
                        background-color: rgb(146, 194, 146);

                        &:hover {
                            background-color: rgb(87, 122, 87);

                            svg {
                                color: rgb(173, 221, 181);

                            }
                        }
                    }

                    svg {
                        color: rgb(40, 45, 40);
                    }
                }

                .test {
                    position: relative;
                }

                textarea {
                    width: 90%;
                    font-size: 0.9rem;
                }
            }

            .CheckBox {
                cursor: pointer;
            }

            .editable {
                position: relative;
                padding-right: 24px;
                /* Ajustez en fonction de la taille de votre icône */
            }

            .edit-icon {
                visibility: hidden;
                position: absolute;
                right: 0;
                bottom: -20px;
                cursor: pointer;
                height: 20px;
            }

            .editable:hover .edit-icon {
                visibility: visible;
            }

            .files {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 10px;
            }

            tbody {
                box-sizing: border-box;
                position: relative;

                tr {
                    border-radius: 10px;
                }

                tr:hover {
                    cursor: pointer;
                }

                table {
                    width: 100%;
                }
            }

            .dropdown-container {
                display: flex;
                flex-direction: column;
                gap: 10px;
                border-radius: 30px;
            }

            .dropdown {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-radius: 30px;
                padding: 15px;
            }

            .nested-table {
                box-sizing: border-box;
                border-radius: 10px;
                width: 100% !important;

                &.editable {
                    th:nth-child(1) {
                        width: 3%;
                    }

                    th:nth-child(2) {
                        width: 40%;
                    }

                    th:nth-child(3) {
                        width: 30%;
                    }
                }

                &.not-editable {
                    th:nth-child(1) {
                        width: 40%;
                    }

                    th:nth-child(2) {
                        width: 30%;
                    }
                }



                tr {
                    background-color: inherit;

                    .link {
                        text-decoration: underline;
                    }
                }

                tr:hover {
                    background-color: inherit;
                    /* ou la couleur d'arrière-plan initiale que vous souhaitez */
                    border: inherit;
                    /* ou la bordure initiale que vous souhaitez */
                    color: inherit;
                    /* ou la couleur initiale que vous souhaitez */
                    cursor: auto;
                }
            }
        }
    }

    .progress-bar {
        width: 100%;
        height: 30px;
        background-color: #e0e0e0;
        border-radius: 50px;
        margin-top: 10px;
        margin-bottom: 10px;
        position: relative;
        overflow: hidden;

        .progress-bar-fill {
            height: 100%;
            background-color: #4caf50;
            border-radius: 50px;
            transition: width 0.5s ease;

            .status-info {
                position: absolute;
                left: 50%;
                top: 25%;
                transform: translateX(-50%);
            }
        }
    }
}