@import '_shared-themes';

html, body {
    overflow-x: hidden;
    width: 100vw;
	box-sizing: border-box;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
}

*::selection {
    background-color: rgb(236, 179, 229);
}

.App {
    &.light-mode {
        @include shared-themes(false);
    }

    &.dark-mode {
        @include shared-themes(true);
    }

    .section {
        @include xs {
            padding-left: 5vw;
            padding-right: 5vw;
        }
    
        @include sm {
            padding-left: 5vw;
            padding-right: 5vw;
        }
    
        @include md {
            padding-left: 10vw;
            padding-right: 10vw;
        }
    
        @include xl {
            padding-left: 15vw;
            padding-right: 15vw;
        }
    
        @include xxl {
            padding-left: 20vw;
            padding-right: 20vw;
        }    
    }
    
    h1, h2, h3, p, ul, li {
        margin: 0;
        padding: 0;
    }
    
    h1 {
        @include xs {
            font-size : 2.5rem;
        }
    
        @include sm {
            font-size : 2.5rem;
        }
    
        @include md {
            font-size : 3.5rem;
        }
    
        @include xl {
            font-size : 3.5rem;
        }
    
        @include xxl {
            font-size : 4rem;
        }
    
        span {
            color: $primary-color !important;
        }
    }
    
    h1, h2, h3, h4 {
        font-family: $font-title;
        span {
            color: $primary-color;
        }
    }
    
    p {
        font-family: $font-paragraphe;
        @include xs {
            font-size : 0.9rem;
        }
    
        @include sm {
            font-size : 0.9rem;
        }
    
        @include md {
            font-size : 1.2rem;
        }
    
        @include xl {
            font-size : 1.2rem;
        }
    
        @include xxl {
            font-size : 1.5rem;
        }
    }
    
    .validate {
        span {
            @include xs {
                font-size : 0.9rem;
            }
        
            @include sm {
                font-size : 0.9rem;
            }
        
            @include md {
                font-size : 1.2rem;
            }
        
            @include xl {
                font-size : 1.2rem;
            }
        
            @include xxl {
                font-size : 1.5rem;
            }
        }
    }
    
    input, textarea, select {
        border: none;
        border-radius: 4px;
        font-family: $font-paragraphe;
        
        &:focus {
            outline: none;
        }
        &:focus::placeholder{
            color: transparent;
        }
    
        @include xs {
            font-size : 0.9rem;
        }
    
        @include sm {
            font-size : 0.9rem;
        }
    
        @include md {
            font-size : 1.2rem;
        }
    
        @include xl {
            font-size : 1.2rem;
        }
    
        @include xxl {
            font-size : 1.5rem;
        }
    }
    
    select {
        cursor: pointer;
    }
    
    button {
        border: none;
        border-radius: 4px;
        padding: 0.5rem 0.5rem;
        cursor: pointer;
        transition: background-color 0.1s ease, opacity 0.1s ease;
    
        &.success {
            cursor: not-allowed;
        }
    }
    
    .icon {
        &.clickable{
            &:hover{
                cursor: pointer;
            }
        }
    }

    .alert {
        font-size: 0.7em;
        padding: 10px;
        border-radius: 20px;
    
        &.success {
            background-color: rgb(162, 225, 162);
            color: green !important;
            border: 1px solid green;
        }

        &.error {
            background-color: rgb(225, 162, 162);
            color: rgb(164, 0, 0) !important;
            border: 1px solid rgb(164, 0, 0);
        }

    }
}