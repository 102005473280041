.file-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    border-radius: 5px;
    padding: 10px;
    width: 15%;
    // background-color: lighten($dark-bg, 10%);
    position: relative;


    .left {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            height: 30px;
            width: auto;
        }
    }

    .right {
        display: flex;
        flex-direction: column;
        gap: 5px;
        overflow: hidden;

        .file-name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        p {
            font-size: 0.6em;
        }
    }

    .cross {
        position: absolute;
        // background-color: black;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        top: -6px;
        right: -6px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
            cursor: pointer;
        }
    }
}