.specialized-chatbot {
    font-size: 0.9rem;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    select {
        font-size: 0.9rem;
        padding: 10px;
        border-radius: 5px;
        width: 100%;
        option {
            border: none;
            padding: 5px 10px;
            border-radius: 50px;
            outline: none;
            transition: background-color 0.2s ease-in-out;

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.userselector {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;

    select {
        font-size: 0.9rem;
    }
}

.new {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
    position: relative;
    .new-chat {
        flex: 6;
    }

    .folder {
        flex: 1;
    }
}

.sb {
    &.history{
        flex: 3;
    }
    &.favorite {
        flex: 5;
    }
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    h2 {
        margin: 0 0 10px 0;
        font-size: 18px;
        padding: 10px;
        width: 100%;
        text-align: left;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding-right: 2%;
        height: 100%;
        width: 98%;
        overflow-y: auto;
        li {
            font-family: $font-paragraphe;
            margin-bottom: 10px;
            transition: background-color 0.2s ease-in-out;
            display: flex;
            width: 100%;
            align-items: center;
            border-radius: 5px;

            .one {
                cursor: pointer;
                border-radius: 5px;
                padding: 4px;
                flex: 3;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                position: relative;
                overflow-x: hidden;

                svg {
                    flex: 1;
                    fill: none;
                }

                p {
                    flex: 6;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-left: 10px;
                    font-size: 1rem;
                }
            }

            .two {
                border-radius: 5px;
                padding: 4px;
                display: flex;
                justify-content: space-around;
                align-items: center;
                flex: 1;

                svg {
                    cursor: pointer;
                    z-index: 10;
                    fill: none;
                    transition: transform 0.2s ease-in;
                }

                svg.star {
                    &:hover {
                      transform: scale(1.3);
                    }
                  }

                svg.trash {
                    &:hover {
                        transform: scale(1.3);
                    }
                }
            }
        }

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 2px; /* Set the border radius for a rounded scrollbar line */
        }
    }

}

.delete {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    .delete-chats {
        flex: 1;
    }

    .toggle-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}
