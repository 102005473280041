.shared {
    display: flex;

    .dashboard-container {
        flex: 3 !important;
    }

    .pdf-viewer-container {
        flex : 2 !important;
    }
    table {
        font-family: $font-paragraphe;
    }

    @import "../dashboard/components/DCERender/DCERender.scss";
    @import "../dashboard/components/FolderStructure/folderStructure.scss";
}

.toggle-darkmode {
    cursor: pointer;
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 50px;
    right: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease-in;

    svg {
        max-width: 80%;
        height: auto;
    }
}

.toggle-darkmode:hover {
    transform: scale(1.1);
}
