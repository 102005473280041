.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    pointer-events: none;
    transition: opacity 0.5s ease-in-out;

    .logo {
        animation: logo-animation 2s ease-in-out infinite;
        transform-origin: center;
        opacity: 0;

        img {
            height: auto;
        
            @include xs {
                width: 10vw;
            }
    
            @include sm {
                width: 10vw;
            }
    
            @include md {
                width: 7vw;
            }
    
            @include xl {
                width: 3vw;
            }
    
            @include xxl {
                width: 3vw;
            }
        }
    }

    &.is-loading{
        opacity: 0;
    }
    
    @keyframes logo-animation {
        0% {
            opacity: 0.8;
            transform: scale(1);
        }
        50% {
            opacity: 1;
            transform: scale(1.2);
        }
        100% {
            opacity: 0.8;
            transform: scale(1);
        }
    }
}
