.submodule {

    .back {
        cursor: pointer;
    }

    button {
        padding: 10px 15px;
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 5vh;
        width: 100%;

        .buttons {
            width: 100%;
            display: flex;
            flex-direction: row;
            gap: 20px;

            .btn {
                width: 100%;
            }
        }

        .informations,
        .password,
        .delete {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;

            h2 {
                display: flex;
                flex-direction: row;
                gap: 10px;
                align-items: center;
            }

            form {
                label {
                    width: 100%;
                }

                input,
                select,
                button {
                    box-sizing: border-box;
                    width: 100%;
                    padding: 15px;
                }
            }
        }

        .delete {
            button {
                width: 30%;
                font-size: 1em;

                @include xs {
                    width: 100%;
                    padding: 15px;
                }

                @include sm {
                    width: 100%;
                    padding: 15px;
                }

                @include md {
                    width: 100%;
                    padding: 15px;
                }
            }
        }

        .doubleButton {
            width: calc(100% - 20px);
            display: flex;
            background-color: #f0f0f0;
            border-radius: 10px;
            flex-direction: row;
            gap: 20px;
            padding: 10px;

            button {
                &.active {
                    background-color: #6ec6dc;
                }

                &.inactive {
                    background-color: #f0f0f0;
                }
            }
        }

        .suggestions {
            width: 100%;
            display: flex;
            overflow: hidden;
            white-space: nowrap; // Gardez les éléments en ligne

            .suggestions-container {
                display: inline-flex; // Utilisez inline-flex pour un alignement en ligne
                animation: scroll-left 120s linear infinite;

                .suggestion {
                    padding: 5px 10px;
                    margin-right: 10px; // Ajustez en fonction de l'espacement désiré
                    border: 1px solid #000;
                    border-radius: 50px;

                    &:hover {
                        cursor: pointer;
                        background-color: #f0f0f0;
                    }
                }
            }
        }

        @keyframes scroll-left {
            from {
                transform: translateX(0);
            }

            to {
                transform: translateX(-50%); // Déplacez seulement de 50% pour le contenu dupliqué
            }
        }
    }
}